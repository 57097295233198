import { ResolveFn } from "@angular/router";
import { CommonService } from "../services/common.service";
import { inject } from "@angular/core";
import { of } from "rxjs";
import { MasterDataService } from "../services/master-data/master-data.service";

export const LanguagesResolver: ResolveFn<boolean> = (route, state) =>  {
    let commonService = inject(CommonService);
    let masterService = inject(MasterDataService)
    if(commonService.languages && commonService.languages.length > 0) {
        return of(true);
    }

    masterService.getAllLanguages().subscribe((languages) => {
        commonService.languages = languages
        return of(true)
    })
    return of(true)
}