import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppService, getServiceUrl } from '../global/global';
import { map } from 'rxjs/Operators';
import { ComponentVersions, VersionChangeData } from '../models/component/component.data.model';

@Injectable({
  providedIn: 'root'
})
export class VersionchangesService {

  private fetchVersionsSubject = new Subject<ComponentVersions>()
  fetchchVersionChangesData$ = this.fetchVersionsSubject.asObservable();
  notifyToFetch(value: ComponentVersions) {
    this.fetchVersionsSubject.next(value);
  }

  constructor(private httpClient: HttpClient) { 

  }

  fetchVersionDifferences(sourceComponentId: number, targetComponentId: number) : Observable<VersionChangeData[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sourceComponentId': sourceComponentId.toString(),
        'targetComponentId': targetComponentId.toString()
      })
    };
     return this.httpClient.get<VersionChangeData[]>(getServiceUrl(AppService.CompareVersionBasedSrdoList), httpOptions);
  }
}
