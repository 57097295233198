import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { MsalGuard } from '@azure/msal-angular';
import { RolesResolver } from '../global/rolesresolver';

const routes: Routes = [
  {path: 'user-roles', canActivate: [MsalGuard],  resolve: { roles: RolesResolver }, component: UserRolesComponent},
  {path: 'add-user', canActivate: [MsalGuard], component: AddUserComponent},
  {path: 'edit-user', canActivate: [MsalGuard], component: EditUserComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class UserRoutingModule { }
