import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuService } from '../../services/system/menu.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeItemDropEvent } from '@progress/kendo-angular-treeview';
import { MasterDataService } from '../../services/master-data/master-data.service';
import { FeatureService } from 'src/app/services/system/feature.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { faTrash, faEdit, faPlus, faFileImport, faRetweet, faTimes, faLink, faUnlink,faMinus, faCopy, faSlash } from '@fortawesome/free-solid-svg-icons';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';
import { WidgetService } from '../../services/widget/widget.service';
import { UserAccess } from 'src/app/global/user-access';
import { SelectableSettings } from "@progress/kendo-angular-treeview";
import { SystemListService } from 'src/app/services/system/system-list.service';
import { SystemModel } from 'src/app/models/system/system.data.model';
import { CommonService } from 'src/app/services/common.service';
import { MenuWidgetModel } from 'src/app/models/widget/menu.widget.model';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { UserConfirmActions } from 'src/app/common/confirm-dailog/confirm-dailog.component';
import { DisplayItem, DisplayModel } from 'src/app/common/copy-modal/copy-modal.component';
import { CommonMenuModel, menuModel } from 'src/app/models/system/feature/feature.module';
import { CommonMenu, MenuDetailsById } from 'src/app/models/component/component.data.model';
import { exit } from 'process';
import { error } from 'console';
import { renameKey } from 'src/app/global/app-enums.enum';
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  menuImport: UntypedFormGroup;
  MenuForm: UntypedFormGroup;
  defaultMenuList: MenuWidgetModel[] = [];
  securityList: any = [{}];
  selectedFeatureData: any = {};
  widgetId: "";
  submitted = false;
  isNavigable = false;
  closeResult: string;
  addMenuForm: UntypedFormGroup;
  parentMenuName: string;
  parentMenuId = 0;
  menuId: number;
  menuDbid: number;
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faMinus = faMinus;
  faTimes = faTimes;
  faFileImport = faFileImport;
  faRetweet = faRetweet;
  faLink = faLink;
  faUnlink = faUnlink;
  faCopy = faCopy;
  faSlash = faSlash;
  editMenuForm: UntypedFormGroup;
  allFeatureListData: any = [{}];
  correntPosition = 0;
  destinationPosition = "";
  editFeatureId: number;
  editFeatureText: string;
  isDataRequested = false; //Add or Edit Menu form
  isDataRequestedMenu = false; //Menu View
  copyButtonState = false;
  componentList: any = [{}];
  versionList: any = [{}];
  pageList: any = [{}];
  srdoList: any = [{}];
  componentListData: any = [{}];
  versionListData: any = [{}];
  pageListData: any = [{}];
  srdoListData: any = [{}];
  isEdit = false;
  editMenuFormData = {}; //edit menu form data
  displayMenuData: DisplayMenuItem = {
    title: "",
    security: "",
    widget: "",
    feature: "",
    component: "",
  }; //display menu form data
  parentRelation = [
    {
      id: 1,
      value: "As a Child",
    },
    {
      id: 0,
      value: "Above",
    },
    {
      id: 2,
      value: "Below",
    },
  ];
  public data: any;
  destinationData: any;
  sourceData: any;
  addMenuAccess = false;
  deleteMenueAccess = false;
  importMenuAccess = false;
  updateMenuAccess = false;
  unlinkFeatureAccess = false;
  public expandedIcon = faPlus;
  public expandText = "Expand All";
  public expandedKeys: any[] = [0];
  public tempKeys: any[] = [];
  public selectedKeys: any[] = [0];
  public addtreeselectedKeys: any[] = [0];
  public selection: SelectableSettings = { mode: "single" };
  /*Copy menu variables*/
  openCopyMenuState = false;
  selectedId: number;
  destinationList: SystemModel[];
  sourceList: SystemModel[];
  selectedDestId: number;
  selectedSourceId: number;
  destSelectedKeys: any[] = [0];
  srcSelectedKeys: any[] = [];
  destExpandedKeys: any[] = [0];
  sourceMenuSelected: any;
  destMenuSelected: any;
  heirarchySelected = 1;
  copybtnState = true;
  errorMessage = "";
  //BUG 554563: System Data - Menu : Highlighted menu item and the selected menu are different
  menuObj: any; //This object used to set Add Menu Selection Object
  versionDisable: boolean = true;
  srdoDisable: boolean = true;
  pageNoDisable: boolean = true;
  componentId: number = -1;
  versionId: number = -1;
  srdoId: number = -1;
  pageNo: number = -1;
  selectedComponentId: number = 0;
  selectedVersionId: number;
  selectedPageId: number;
  selectedSRDOId: number;
  selectedComponent: string = "";
  selectedVersion: string = "";
  selectedPage: string = "";
  selectedSRDO: string = "";
  selectedValue: string = "";
  showConfirmation = false;

  //Copy common menu
  linkCommonMenuOpen = false;
  copyCommandError: string = "";
  menuDetails = {};
  linkButtonState: boolean = true;
  linkCommonMenuData: DisplayItem[] = [];
  deleteConfirmTitle = ''
  deleteConfirmNote = ''
  deleteConfirmMessage = ''
  commonMenuByIdData: CommonMenuModel;
  isNavigabletoComponent: boolean = false
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    private toastService: ToastService,
    private router: ActivatedRoute,
    private masterDataService: MasterDataService,
    private featureService: FeatureService,
    private modalService: NgbModal,
    private confirmationDialogService: DialogboxService,
    private widgetService: WidgetService,
    private useraccess: UserAccess,
    public route: Router,
    public systemListService: SystemListService,
    public commonService: CommonService,
    private componentService: ComponentListService,
    private srdoDataService: SrdoDataService
  ) {}

  ngOnInit() {
    this.featureService.attachedFetureName("", "");
    this.menuImport = this.formBuilder.group({
      menuFile: ["", Validators.required],
    });
    this.MenuForm = this.formBuilder.group({
      title: ["", Validators.required],
      securityId: [1, Validators.required],
      widgetId: [1, Validators.required],
      featureAttached: [0, Validators.required],
    });
    this.addMenuForm = this.formBuilder.group({
      addTitle: ["", Validators.required],
      addSecurity: [1, Validators.required],
      addMenuWidget: [1, Validators.required],
      addFeatureAttached: [0, Validators.required],
      addHierarchy: [0, Validators.required],
      componentId: "",
      versionId: "",
      srdoId: "",
      pageNo: "",
      value: "",
    });
    this.editMenuForm = this.formBuilder.group({
      editTitle: ["", Validators.required],
      editSecurity: ["", Validators.required],
      editMenuWidget: ["", Validators.required],
      editFeatureAttached: [0],
      componentId: "",
      versionId: "",
      srdoId: "",
      pageNo: "",
      value: "",
    });
    this.featureService
      .getFeatureListBySystem(this.router.snapshot.paramMap.get("id"))
      .subscribe((res) => {
        if (res != null) {
          this.allFeatureListData = res;
        }
      });
    this.spinner.show();
    this.componentService.getComponentList(false).subscribe(
      (res) => {
        this.componentService.componentData = res;
        this.componentList = JSON.parse(JSON.stringify(res));
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
    this.router.paramMap.subscribe((params) => {
      this.clearMenuForm();
      this.editFeatureText = "";
      const systemid = +this.router.snapshot.paramMap.get("id");
      this.checkAccess(MenuActions.AddMenu, systemid);
      this.checkAccess(MenuActions.DeleteMenu, systemid);
      this.checkAccess(MenuActions.ImportMenu, systemid);
      this.checkAccess(MenuActions.UpdateMenu, systemid);
      this.checkAccess(MenuActions.UnlinkFeature, systemid);
      this.data = [];
      if (this.router.snapshot.paramMap.get("id") != null) {
        this.spinner.show();
        this.menuService
          .getMenuHeirarchy(this.router.snapshot.paramMap.get("id"))
          .subscribe(
            (res) => {
              if (res != null && res[0] != null) {
                this.data = res;
                this.hasCommonMenu = false
                this.expandNodes(res)
                this.menuId = res[0].id;
                this.menuDbid = res[0].Dbid;
                // Go to Menu link from feature tab
                let goToFeatureMenuId = this.featureService.featureMenuId;
                this.onSelectedMenu(
                  goToFeatureMenuId != null && goToFeatureMenuId != 2147483647
                    ? goToFeatureMenuId
                    : res[0].id
                );
                this.expandAllParentOfSelectedNode(
                  this.data,
                  goToFeatureMenuId != null ? goToFeatureMenuId : res[0].id
                );
                this.reverseOrderOfExpandKeys()
                this.selectedItem = res[0];
              }
              this.spinner.hide();
              this.commonService.selectMenuAction();
            },
            () => {
              this.spinner.hide();
            }
          );
        this.unmappedFeatureList();
      }
    });
    this.widgetService.getMenuWidgetList().subscribe((menuRes) => {
      if (menuRes !== null) {
        this.defaultMenuList = menuRes;
      }
    });
    this.masterDataService.getSecurityLevel().subscribe((securityRes) => {
      if (securityRes !== null) {
        this.securityList = securityRes;
      }
    });
    //Bug-659808
    this.commonService.updateFeatureListObservable().subscribe((status) => {
      this.loadMenuList();
    });
  }

  reverseOrderOfExpandKeys() {
    if(this.tempKeys.length == 0) {
      if(this.data && this.data.length > 0) {
        this.expandedKeys = [this.data[0].id]
      }
    } else {
      this.expandedKeys =  this.tempKeys.reverse()
    }
    this.tempKeys = []
  }

  unmappedFeatureList() {
    this.featureService
      .getFeaturelist(this.router.snapshot.paramMap.get("id"))
      .subscribe((listRes) => {
        this.selectedFeatureData = listRes;
        this.spinner.hide()
      }, error =>{
        this.spinner.hide()
      });
  }
  featureOnClick(name, id) {
    document.getElementById("0").click();
    this.featureService.getFeatureServiceId = id;
    this.featureService.attachedFetureName(name, id);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.addMenuForm.controls;
  }
  get edit() {
    return this.editMenuForm.controls;
  }

  onSelectedFIle(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.menuImport.get("menuFile").setValue(file);
      this.onMenuFileImport();
    }
  }

  unlinkFeature() {
    this.confirmationDialogService
      .confirm(
        "Please confirm..",
        "Are you sure you want to unlink the feature?"
      )
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          const systemid = +this.router.snapshot.paramMap.get("id");

          this.menuService.unlinkFeature(this.menuDbid, systemid).subscribe(
            (datRes) => {
              this.loadMenuList();
              this.clearMenuForm();
              // this.onSelectedMenu(this.menuId);
              this.unmappedFeatureList();
            },
            (error) => {
              this.loadMenuList();
              this.spinner.hide();
              return false;
            }
          );
          this.spinner.hide();
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }

  onMenuFileImport() {
    const formData = new FormData();
    formData.append("formFile", this.menuImport.get("menuFile").value);
    this.spinner.show();
    this.menuService
      .importMenuExcelFile(formData, this.router.snapshot.paramMap.get("id"))
      .subscribe(
        (res) => {
          if (res === true) {
            this.menuService
              .getMenuHeirarchy(this.router.snapshot.paramMap.get("id"))
              .subscribe(
                (heirarchyRes) => {
                  this.data = heirarchyRes;
                  this.menuId = heirarchyRes[0].id;
                  this.menuDbid = heirarchyRes[0].Dbid;
                  this.ToastMessage("Menu imported successfully", "bg-success");
                  this.spinner.hide();
                  this.onSelectedMenu(this.menuId);
                },
                () => {
                  this.spinner.hide();
                }
              );
          } else {
            this.ToastMessage("Menu import failed", "bg-danger");
            this.spinner.hide();
          }
        },
        (error) => {
          this.ToastMessage(error, "bg-danger");
          this.spinner.hide();
        }
      );
  }
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: type === "bg-success" ? "Success" : "Error",
    });
  }
  public selectedItem;
  public log(event: string, args?: any): void {
    this.selectedKeys = [args.index];
    this.menuDbid = args.dataItem.Dbid;
    this.menuId = args.dataItem.id;
    this.selectedItem = args.dataItem;
    this.onSelectedMenu(args.dataItem.id);
  }

  hasCommonMenu = false;
  /**
   * Expand menu nodes
   * @param nodes 
   */
  expandNodes(nodes: CommonMenu[]) {
    nodes.some((item) => {
      if(item.IsCommonMenu) {
        this.hasCommonMenu = true;
        return;
      }
      if(item.children && item.children.length > 0) {
        this.expandNodes(item.children)
      }
    })
 }

 parent: CommonMenu
 /**
  * Iterate through parent and child nodes to find common menu link exists or not
  * @param nodes 
  */
 findParents(nodes: CommonMenu[]) {
  nodes.some((item) => {
    if(item.children && item.children.length > 0) {
      if(item.parent == 0) {
        this.findParents(item.children)
      }else {
        this.parent = item;
        this.findChildItems(item.children)
      }
    } else {
      item.disabled = item.IsCommonMenu == true ?  true : false
    }
  })
 }

 /**
  * Search for Iscommon menu in child nodes
  * @param nodes
  */
 findChildItems(nodes: CommonMenu[]) {
  nodes.some((item) => {
    if(item.IsCommonMenu) {
      if(this.parent && this.parent.parent != 0) {
        this.parent.disabled = true
      }
    }
    if(item.children && item.children.length > 0) {
      this.findChildItems(item.children)
    }
  })
 }


  public expandAllParentOfSelectedNode(jsonObj, menuid) {
    let nodeToFind = menuid;
    if (jsonObj !== null && typeof jsonObj == "object") {
      Object.entries(jsonObj).forEach(([key, value]) => {
        // key is either an array index or object key
        let childItem = [];
        childItem.push(value);
        if (childItem[0]["id"] === nodeToFind) {
          let foundperent = childItem[0]["parent"];
          this.tempKeys.push(foundperent);
          if (foundperent !== 0) {
            this.expandAllParentOfSelectedNode(this.data, foundperent);
            this.menuObj = value;
          }
        } else {
          this.expandAllParentOfSelectedNode(value, nodeToFind);
        }
        if (this.menuObj === undefined) {
          Object.values(jsonObj).forEach((value) => {
            if (value) {
              let val = value as any;
              if (+val.id == +menuid) {
                this.menuObj = val;
                this.menuId = menuid;
              }
            }
          });
        }
      });
    }
  }

  expandAll() {
    this.expandedKeys = [0];
    if (this.data !== null && typeof this.data == "object") {
      if (this.expandedIcon == faPlus) {
        this.expandedIcon = faMinus;
        this.expandText = "Contract All";
        Object.entries(this.data).forEach(([key, value]) => {
          // key is either an array index or object key
          this.expandNext(value);
        });
      } else {
        this.expandedIcon = faPlus;
        this.expandText = "Expand All";
        this.collapseAll();
      }
    }
  }

  expandNext(node) {
    let childItem = [];
    childItem.push(node);
    if (childItem[0]["children"] != null) {
      let Children = childItem[0]["children"];
      this.expandedKeys.push(node["id"]);
      Object.entries(Children).forEach(([key, value]) => {
        this.expandedKeys.push(value["id"]);
        if (value["children"].length > 0) {
          this.expandNext(value);
        }
      });
    }
  }

  collapseAll() {
    this.expandedKeys = [0];
  }

  onSelectedMenu(menuId: number) {
    this.spinner.show();
    this.selectedKeys = [menuId];
    if(this.selectedItem && this.selectedItem.IsCommonMenu) {
      let commonMenuDetails: MenuDetailsById = {
        componentId: this.selectedItem.ComponentId.toString(),
        titleId: this.selectedItem.TitleId.toString(),
        commonMenuId: menuId.toString(),
      };
      this.checkForNavigation(this.selectedItem.ComponentId)
      this.menuService.getCommonMenuById(commonMenuDetails).subscribe(
        (res) => {
          if (res) {
            this.commonMenuByIdData = res
            this.updateViewWithMenuByID(res)
          }
      }, error => this.spinner.hide())
    } else {
      this.menuService
      .getMenuBasesOnMenuId({
        selectedMenuID: menuId.toString(),
        systemId: this.router.snapshot.paramMap.get("id"),
      })
      .subscribe(
        (res) => {
          this.commonMenuByIdData = undefined
          this.updateViewWithMenuByID(res)
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
    
  }

  updateViewWithMenuByID(res: menuModel) {
    if (res !== null) {
      const MenuFormData = {
        title: res.title,
        securityId: res.securityId,
        widgetId: res.widgetId,
        featureAttached: res.referencedFeatureName,
        baseComponentId: res.baseComponentId,
        srdoId: res.srdoId,
        pageId: res.pageId,
        versionComponentId: res.versionComponentId,
      };
      this.displayMenuData.title = res.title;
      this.displayMenuData.feature = res.referencedFeatureName;
      let security = this.securityList.find(
        (item) => item.id === +res.securityId
      );
      if (security) {
        this.displayMenuData.security = security.value;
      }
      this.editFeatureText = res.referencedFeatureName;
      this.editFeatureId = res.referencedFeatureId;
      this.menuId = res.menuId;
      this.menuDbid = res.id;
      this.MenuForm.patchValue(MenuFormData);
      let menuFound = this.defaultMenuList.find((item) => {
        return item.id === +res.widgetId;
      });
      if (menuFound) {
        this.isDataRequested = menuFound.isDataRequested;
        this.isDataRequestedMenu = menuFound.isDataRequested;
        this.displayMenuData.widget = menuFound.name;
      }
      this.selectedComponentId = +res.baseComponentId;
      this.selectedPageId = +res.pageId;
      this.selectedVersionId = +res.versionComponentId;
      this.selectedSRDOId = +res.srdoId;
      this.selectedValue = res.value;
      let componentFound = this.componentList.find(
        (item) => item.id === +res.baseComponentId
      );

      if (componentFound) {
        this.selectedComponent = componentFound.name;
        this.displayMenuData.component = componentFound.name;
        setTimeout(() => {
          this.fetchAllData();
        })
      }else {
        this.spinner.hide()
      }
    }
  }

  checkForNavigation(componentId: number) {
    this.isNavigabletoComponent =  false;
    let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
    let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
    if(isAdmin || isGlobalRead) {
        this.isNavigabletoComponent =  true;
    } else {
      let component = this.useraccess.actions.components.find((component) => {
        return component.componentId === componentId
      })
      if(component) {
        this.isNavigabletoComponent = true
      }
    }
  }

  clearMenuForm() {
    this.MenuForm.reset();
    this.submitted = false;
    this.expandedKeys = [0];
    this.selectedKeys = [0];
    this.selectedItem = null;
    this.addtreeselectedKeys = null;
  }
  clearAddMenuForm() {
    this.addMenuForm.reset();
    this.submitted = false;
  }
  clearEditMenuForm() {
    this.editMenuForm.reset();
    this.submitted = false;
  }

  onReset(event) {
    event.preventDefault();
    if (this.isEdit) {
      this.editMenuForm.patchValue(this.editMenuFormData);
      if (this.selectedComponentId > 0) {
        this.editMenuForm.controls.versionId.setValue(this.selectedVersionId);
        this.editMenuForm.controls.pageNo.setValue(this.selectedPageId);
        this.editMenuForm.controls.srdoId.setValue(this.selectedSRDOId);
        this.editMenuForm.controls.value.setValue(this.selectedValue);
      } else {
        this.onFormRest(this.editMenuForm);
      }
    } else {
      this.addMenuForm.controls.addMenuWidget.setValue(
        this.defaultMenuList[0].id
      );
      this.addMenuForm.controls.addSecurity.setValue(this.securityList[3].id);
      this.addMenuForm.controls.addHierarchy.setValue(
        this.parentRelation[0].id
      );
      this.addMenuForm.controls.addFeatureAttached.setValue(0);
      this.addMenuForm.controls.addTitle.setValue("");
      this.parentMenuId = 0;
      this.parentMenuName = "";
      this.isDataRequested = false;
      this.getMenuId(this.selectedItem);
      this.onFormRest(this.addMenuForm);
      this.versionDisable = true;
      this.srdoDisable = true;
      this.pageNoDisable = true;
      this.submitted = false;
      this.setandResetValidators("");
    }
  }

  openMenuModel(content) {
    this.isEdit = false;
    this.parentMenuName = "";
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      ariaLabelledBy: "modal-basic-title",
    };
    this.submitted = false;
    const ref = this.modalService.open(content, ngbModalOptions);
    this.addMenuForm.controls.addMenuWidget.setValue(
      this.defaultMenuList[0].id
    );
    this.isDataRequested = false;
    this.addMenuForm.controls.addSecurity.setValue(this.securityList[3].id);
    this.addMenuForm.controls.addHierarchy.setValue(this.parentRelation[0].id);
    this.addMenuForm.controls.addFeatureAttached.setValue(0);
    this.onFormRest(this.addMenuForm);
    this.addtreeselectedKeys = this.selectedKeys;
    this.getMenuId(this.selectedItem);
    ref.result.then(
      (result) => {
        this.clearAddMenuForm();
        this.parentMenuId = 0;
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.clearAddMenuForm();
        this.parentMenuId = 0;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.parentMenuId = 0;
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.parentMenuId = 0;
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addMenuForm.invalid) {
      return;
    }
    this.modalService.dismissAll();
    // display form values on success
    let srdoId = 0;
    let srdo = this.srdoList.find(
      (item) => item.id === +this.addMenuForm.get("srdoId").value
    );
    if (srdo) {
      srdoId = srdo.srdoId;
    }
    const addMenuData = {
      name: this.addMenuForm.get("addTitle").value,
      parentId: this.parentMenuId,
      securityId: this.addMenuForm.get("addSecurity").value,
      menuWidgetId: this.addMenuForm.get("addMenuWidget").value,
      featureId:
        this.addMenuForm.get("addFeatureAttached").value === null
          ? 0
          : this.addMenuForm.get("addFeatureAttached").value,
      menuHeirarchy: this.addMenuForm.get("addHierarchy").value,
      baseComponentId: +this.addMenuForm.get("componentId").value,
      versionComponentId: +this.addMenuForm.get("versionId").value,
      pageId: +this.addMenuForm.get("pageNo").value,
      srdoId: srdoId,
      value: this.addMenuForm.get("value").value,
    };
    this.spinner.show();
    this.menuService
      .addMenuData(addMenuData, this.router.snapshot.paramMap.get("id"))
      .subscribe(
        (res) => {
          if (res !== null) {
            this.ToastMessage("Menu created Successfully.", "bg-success");
            this.clearAddMenuForm();
            this.loadMenuList(Number(res));
            // this.onSelectedMenu(Number(res));
            this.unmappedFeatureList();
          }
        },
        error => {
          this.spinner.hide();
        }
      );
  }
  getMenuId(data) {
    if (data == null) {
      this.parentMenuId = 0;
      this.parentMenuName = "";
    } else {
      this.parentMenuId = data.id;
      this.parentMenuName = data.name;
    }
  }
  
  loadMenuList(id?: Number, isDelete?: String) {
    this.data = [];
    this.menuService
      .getMenuHeirarchy(this.router.snapshot.paramMap.get("id"))
      .subscribe((datRes) => {
        this.spinner.hide()
        if (datRes !== null) {
          this.data = datRes;
          this.hasCommonMenu = false
          this.expandNodes(datRes)
          if (isDelete) {
            this.menuId = datRes[0].id;
            this.selectedItem = datRes[0];
            this.onSelectedMenu(this.menuId);
          }
          if (id && !isDelete) {
            this.expandAllParentOfSelectedNode(datRes, id);
            this.reverseOrderOfExpandKeys()
            this.selectedItem = this.menuObj;
            this.menuId = +id
            this.onSelectedMenu(+id)
          }
        }
      }, error => this.spinner.hide());
  }

  deleteMenu() {
    //Note: Import menu access is only for Admin and System Owner - Using this property to delete a root menu or not
    if (this.importMenuAccess) {
      this.deleteConfirmTitle = 'Are you sure you want to delete?'
      this.deleteConfirmNote = `Warning: All the nested menu will be deleted if you delete this`
      this.deleteConfirmMessage = 'Do you want to delete linked features & feature listings?'
      this.showConfirmation = true;
    } else {
      this.confirmationDialogService
        .confirm(
          "Please confirm..",
          "Are you sure you want to delete this item?"
        )
        .then((confirmed) => {
          if (confirmed === true) {
            let parameters = { Id: this.menuDbid };
            this.deleteMenuAPICall(parameters);
          }
        })
        .catch(() =>
          console.log(
            "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
          )
        );
    }
  }

  deleteMenuAPICall(params: any) {
    this.spinner.show();
    this.data = [];
    this.menuService.deleteMenu(params).subscribe(
      (datRes) => {
        this.spinner.hide();
        this.ToastMessage("Menu Deleted Successfully.", "bg-success");
        let parentExpandedKeys = [];
        parentExpandedKeys.push(this.expandedKeys);
        this.loadMenuList(0, "delete");
        this.clearMenuForm();
        this.onSelectedMenu(this.menuId);
        this.expandedKeys =
          parentExpandedKeys[0].length === 0
            ? this.menuId
            : parentExpandedKeys[0];
        this.unmappedFeatureList();
      },
      (error) => {
        this.loadMenuList();
        this.spinner.hide();
        return false;
      }
    );
  }

  /* Editing a menu - On Click of Edit button */
  editMenu(content) {
    this.isEdit = true;
    this.spinner.show();
    this.editMenuForm.controls.editMenuWidget.setValue(
      this.defaultMenuList[0].id
    );
    this.submitted = false;
    if(this.selectedItem && this.selectedItem.IsCommonMenu) {
      let commonMenuDetails: MenuDetailsById = {
        componentId: this.selectedItem.ComponentId.toString(),
        titleId: this.selectedItem.titleId.toString(),
        commonMenuId: this.menuId.toString(),
      };
      this.menuService.getCommonMenuById(commonMenuDetails).subscribe(
        (res) => {
          if (res) {
            this.onEditUpdateViewByMenuId(content, res)
          }
      }, error => this.spinner.hide())
    } else {
      this.menuService
      .getMenuBasesOnMenuId({
        selectedMenuID: this.menuId.toString(),
        systemId: this.router.snapshot.paramMap.get("id"),
      })
      .subscribe(
        (res) => {
          if (res !== null) {
            this.onEditUpdateViewByMenuId(content, res)
          } else {
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }


  onEditUpdateViewByMenuId(content: any, res: menuModel) {
    const MenuFormData = {
      editTitle: res.title,
      editSecurity:  res.securityId,
      editMenuWidget: res.widgetId,
      editFeatureAttached: res.referencedFeatureDBId,
      componentId: res.baseComponentId,
      versionId: res.versionComponentId,
      value: res.value === undefined ? "" : res.value,
    };
    this.editMenuFormData = MenuFormData;
    this.editFeatureText = res.referencedFeatureName;
    this.editFeatureId = res.referencedFeatureDBId;
    this.editMenuForm.patchValue(MenuFormData);
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    if (this.isDataRequested) {
      this.selectedComponentId = +res.baseComponentId;
      this.selectedPageId = +res.pageId;
      this.selectedVersionId = +res.versionComponentId;
      this.selectedSRDOId = +res.srdoId;
      this.selectedValue = res.value;
      this.versionDisable = false;
      this.srdoDisable = false;
      this.pageNoDisable = false;
      this.pageList = this.pageListData;
      this.versionList = this.versionListData;
      this.srdoList = this.srdoListData;
      this.setDropDownSelections();
    }
    this.modalService.open(content, ngbModalOptions);
    this.spinner.hide();
  }

  onEditSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editMenuForm.invalid) {
      return;
    }
    this.spinner.show();
    this.modalService.dismissAll();
    let srdoId = 0;
    let srdo = this.srdoList.find(
      (item) => item.id === +this.editMenuForm.get("srdoId").value
    );
    if (srdo) {
      srdoId = srdo.srdoId;
    }
    const editMenuData = {
      id: this.menuDbid,
      name: this.editMenuForm.get("editTitle").value,
      parentId: this.parentMenuId,
      securityId: this.editMenuForm.get("editSecurity").value,
      menuWidgetId: this.editMenuForm.get("editMenuWidget").value,
      featureId:
        this.editMenuForm.get("editFeatureAttached").value === null
          ? 0
          : this.editMenuForm.get("editFeatureAttached").value,
      baseComponentId: +this.editMenuForm.get("componentId").value,
      versionComponentId: +this.editMenuForm.get("versionId").value,
      pageId: +this.editMenuForm.get("pageNo").value,
      srdoId: srdoId,
      value: this.editMenuForm.get("value").value,
    };
    this.menuService
      .updateMenu(editMenuData, this.router.snapshot.paramMap.get("id"))
      .subscribe(
        (res) => {
          if (res !== null) {
            this.ToastMessage("Menu update Successfully.", "bg-success");
            this.clearEditMenuForm();
            this.loadMenuList();
            this.onSelectedMenu(this.menuId);
            this.unmappedFeatureList();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
    this.spinner.hide();
  }

  reOrderMenu(modal) {
    this.findParents(this.data)
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      ariaLabelledBy: "modal-basic-title",
    };
    this.modalService.open(modal, ngbModalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.spinner.show();
        this.loadMenuList();
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        setTimeout(() => {
          this.spinner.hide();
        }, 1800);
      }
    );
  }

  menuDragDrop(event: string, args?: any): void {
    if (event === "addItem") {
      if (args.dropPosition === undefined || args.dropPosition >= 0) {
        this.destinationPosition =
          args.destinationItem.item.dataItem.Dbid.toString();
        this.correntPosition = 0;
        if (args.dropPosition === 1 || args.dropPosition === 2) {
          this.correntPosition =
            args.destinationItem.item.dataItem.Dbid.toString();
          this.destinationPosition =
            args.destinationItem.parent.item.dataItem.Dbid.toString();
        }
      } else {
        this.correntPosition =
          args.destinationItem.item.dataItem.Dbid.toString();
        this.destinationPosition =
          args.destinationItem.parent.item.dataItem.Dbid.toString();
      }
      if (
        this.destinationPosition !==
        args.sourceItem.item.dataItem.Dbid.toString()
      ) {
        this.menuService
          .getMenuReOrdering({
            sourceMenuId: args.sourceItem.item.dataItem.Dbid.toString(),
            destinationParentMenuId: this.destinationPosition,
            currentPostionMenuId: this.correntPosition,
          })
          .subscribe(
            (res) => {
              if (res !== null) {
                this.ToastMessage("Menu update Successfully.", "bg-success");
              }
            },
            () => {
              this.spinner.hide();
            }
          );
      }
    }
  }
  public handleMenuDrop(event: TreeItemDropEvent): void {
    this.menuDragDrop("nodeDrop", event);
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case MenuActions.AddMenu:
        this.addMenuAccess = this.useraccess.getUserAccess(
          "systems",
          action,
          dataItem !== -1 ? +dataItem : -1
        );
        break;
      case MenuActions.DeleteMenu:
        this.deleteMenueAccess = this.useraccess.getUserAccess(
          "systems",
          action,
          dataItem !== -1 ? +dataItem : -1
        );
        break;
      case MenuActions.ImportMenu:
        this.importMenuAccess = this.useraccess.getUserAccess(
          "systems",
          action,
          dataItem !== -1 ? +dataItem : -1
        );
        break;
      case MenuActions.UpdateMenu:
        this.updateMenuAccess = this.useraccess.getUserAccess(
          "systems",
          action,
          dataItem !== -1 ? +dataItem : -1
        );
        break;
      case MenuActions.UnlinkFeature:
        this.unlinkFeatureAccess = this.useraccess.getUserAccess(
          "systems",
          action,
          dataItem !== -1 ? +dataItem : -1
        );
        break;
      default:
        break;
    }
  }

  // A function that disables every item with a text field which equals to 'Decor'.
  public isDisabled = (dataItem: CommonMenu): boolean => {
    return  !!dataItem.disabled;
  };

  /**
   * Copy System Menu
   * Button Click Event
   **/
  copyMenuFromSystem($event: Event) {
    this.errorMessage = "";
    let selectedId = this.systemListService.selectedSystemId;
    this.destinationData = [...this.data];
    this.destinationList = this.systemListService.systemList.filter(
      (system) => system.id === selectedId
    );
    this.sourceList = this.systemListService.systemList;
    this.selectedDestId =
      this.destinationList.length > 0 ? this.destinationList[0].id : 0;
    this.destMenuSelected = { dataItem: { id: this.selectedKeys[0] } };
    this.openCopyMenuState = true;
    this.destSelectedKeys = [...this.selectedKeys];
    this.destExpandedKeys = [...this.expandedKeys];
    this.srcSelectedKeys = [];
    this.sourceData = [];
    this.selectedSourceId = 0;
  }

  /*
   * Copy button action - Callback function
   */
  copyMenuSystem(isCopyLinkedDataRequired: boolean) {
    let sourceSystemId = this.selectedSourceId;
    let sourceMenuId = this.sourceMenuSelected.dataItem.id;
    let targetSystemId = this.selectedDestId;
    let targetMenuId = this.destMenuSelected.dataItem.id;
    let menuPosition = this.parentRelation.filter(
      (item) => item.id === this.heirarchySelected
    )[0].id;

    this.spinner.show();
    this.menuService
      .copyMenuSystem(
        sourceSystemId,
        sourceMenuId,
        targetSystemId,
        targetMenuId,
        menuPosition.toString(),
        isCopyLinkedDataRequired
      )
      .subscribe(
        (res) => {
          let id = res;
          this.ToastMessage("Menu copied successfully.", "bg-success");
          this.menuService
            .getMenuHeirarchy(targetSystemId.toString())
            .subscribe(
              (res) => {
                if (res != null && res[0] != null) {
                  this.data = res;
                  this.hasCommonMenu = false
                  this.expandNodes(res)
                  this.menuId = res[0].id;
                  this.menuDbid = res[0].Dbid;
                  this.copybtnState = false;
                  this.openCopyMenuState = false;
                  this.selectedItem = res[0]
                  this.onSelectedMenu(id);
                  this.unmappedFeatureList();
                  this.expandAllParentOfSelectedNode(this.data, id);
                  this.reverseOrderOfExpandKeys()
                }
                this.spinner.hide();
              },
              (error) => {
                this.spinner.hide();
                this.errorMessage = error;
              }
            );
        },
        (error) => {
          this.spinner.hide();
          this.errorMessage = error;
        }
      );
  }

  /**
   * Load Menu Heirarchy
   * Kendo DropDown Change
   **/
  refreshMenuHeirarchy($event) {
    this.sourceMenuSelected = undefined;
    this.copybtnState = true;
    this.srcSelectedKeys = [];
    this.sourceData = [];
    if ($event) {
      this.selectedSourceId = $event;
    } else {
      return;
    }
    this.spinner.show();
    this.menuService.getMenuHeirarchy($event.toString()).subscribe(
      (res) => {
        if (res != null && res[0] != null) {
          this.hasCommonMenu = false
          this.expandNodes(res)
          this.sourceData = res;
          this.menuId = res[0].id;
          this.menuDbid = res[0].Dbid;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /*
    Source Menu Selection Callback
  */
  heirarchySelectionChanged($event) {
    this.heirarchySelected = $event;
    this.checkCopyMenuDataValid();
  }

  /*
  Validations to enable Copy button
  */
  checkCopyMenuDataValid() {
    if (
      this.sourceMenuSelected &&
      this.destMenuSelected &&
      this.heirarchySelected
    ) {
      this.copybtnState = false;
    }
  }

  /*
  Source menu selection callback
  */
  sourceMenuSelectionEvent($event) {
    this.sourceMenuSelected = $event;
    this.checkCopyMenuDataValid();
  }

  /*
  Destination menu selection callback
  */
  destMenuSelectionEvent($event) {
    this.destMenuSelected = $event;
    this.checkCopyMenuDataValid();
  }

  /*
  Close action Callback from modal
  */
  closeCopyMenuModal($event) {
    this.srcSelectedKeys = [];
    this.sourceData = [];
    this.selectedSourceId = 0;
    this.copybtnState = true;
    this.openCopyMenuState = false;
    this.sourceMenuSelected = undefined;
    this.destMenuSelected = undefined;
  }

  onChange(event) {
    let menu = this.defaultMenuList.find(
      (item) => item.id === +event.target.value
    );
    if (menu) {
      this.isDataRequested = menu.isDataRequested;
    }
  }

  onComponentChange($event) {
    this.versionDisable = true;
    let form = this.isEdit ? this.editMenuForm : this.addMenuForm;
    form.get("versionId").reset();
    form.get("srdoId").reset();
    form.get("pageNo").reset();
    this.setandResetValidators($event.target.value);
    this.srdoDisable = true;
    this.pageNoDisable = true;
    if (+$event.target.value !== this.componentId && +$event.target.value > 0) {
      this.versionDisable = false;
    }
    this.spinner.show();
    this.fetchVersions($event.target.value);
  }

  fetchVersions(componentId: string) {
    this.componentService
      .getversionlistbycomponent(componentId)
      .subscribe((res) => {
        this.spinner.hide();
        this.versionList = res;
      });
  }

  setandResetValidators(value: string) {
    let form = this.isEdit ? this.editMenuForm : this.addMenuForm;
    if (value === "") {
      form.get("versionId").setErrors(null);
      form.get("versionId").setValidators(null);
      form.get("srdoId").setErrors(null);
      form.get("srdoId").setValidators(null);
      form.get("pageNo").setErrors(null);
      form.get("pageNo").setValidators(null);
    } else {
      form.get("versionId").setValidators([Validators.required]);
      form.get("srdoId").setValidators([Validators.required]);
      form.get("pageNo").setValidators([Validators.required]);
    }
    form.get("versionId").updateValueAndValidity();
    form.get("srdoId").updateValueAndValidity();
    form.get("pageNo").updateValueAndValidity();
  }

  onVersionChange($event) {
    let form = this.isEdit ? this.editMenuForm : this.addMenuForm;
    form.get("srdoId").reset();
    form.get("pageNo").reset();
    this.srdoDisable = true;
    if (+$event.target.value !== 0) {
      this.pageNoDisable = false;
    }
    this.spinner.show();
    this.fetchPageNos($event.target.value);
  }

  fetchPageNos(compversionId: string) {
    this.srdoDataService
      .getPageByComponentIdVersionNumber(compversionId, "")
      .subscribe((data) => {
        this.spinner.hide();
        this.pageList = data;
      });
  }

  onPageNoChange($event) {
    this.srdoDisable = true;
    let form = this.isEdit ? this.editMenuForm : this.addMenuForm;
    form.get("srdoId").reset();
    if ($event.target.value !== "") {
      this.srdoDisable = false;
    }
    this.spinner.show();
    this.fetchSRDOs(form.get("versionId").value, form.get("pageNo").value);
  }

  fetchSRDOs(versionId: string, pageNo: string) {
    this.srdoDataService
      .getSrdoByComponentIdVersionNumber(versionId, "", pageNo)
      .subscribe((data) => {
        this.srdoList = data;
        this.spinner.hide();
      });
  }

  /* --- Feature: 669419 ---
    Edit menu form fields selection 
  */
  setDropDownSelections() {
    if(this.selectedItem && this.selectedItem.IsCommonMenu) {
      this.editMenuForm.controls.editSecurity.disable();
      this.editMenuForm.controls.editMenuWidget.disable();
    }
    if (this.selectedComponentId > 0) {
      this.editMenuForm.controls.versionId.setValue(this.selectedVersionId);
      this.editMenuForm.controls.pageNo.setValue(this.selectedPageId);
      this.editMenuForm.controls.srdoId.setValue(this.selectedSRDOId);
      this.editMenuForm.controls.value.setValue(this.selectedValue);
    } else {
      this.onFormRest(this.editMenuForm);
      if (this.selectedValue && this.selectedValue.length > 0) {
        this.editMenuForm.controls.value.setValue(this.selectedValue);
      }
    }
  }

  /* --- Feature: 669419 ---
    Reset add or edit form fields 
  */
  onFormRest(formgroup: UntypedFormGroup) {
    formgroup.get("componentId").setValue("");
    formgroup.get("versionId").setValue("");
    formgroup.get("srdoId").setValue("");
    formgroup.get("pageNo").setValue("");
    formgroup.get("value").reset();
    this.versionDisable = true;
    this.srdoDisable = true;
    this.pageNoDisable = true;
  }

  /* --- Feature: 669419 ---
    Get Versions, Pages and SRDOs for edit form data filling 
  */
  fetchAllData() {
    this.componentService
      .getversionlistbycomponent(this.selectedComponentId.toString())
      .subscribe(
        (res) => {
          this.versionListData = res;
          let versionFound = this.versionListData.find(
            (item) => +item.versionNumberId === this.selectedVersionId
          );
          if (versionFound) {
            this.selectedVersion = versionFound.versionNumber;
            this.displayMenuData.version = versionFound.versionNumber;
          }
          this.srdoDataService
            .getPageByComponentIdVersionNumber(
              this.selectedVersionId.toString(),
              ""
            )
            .subscribe(
              (data) => {
                this.pageListData = data;
                let pageFound = this.pageListData.find(
                  (item) => +item.pageNumber === this.selectedPageId
                );
                if (pageFound) {
                  this.selectedPage = pageFound.pageText;
                  this.displayMenuData.page = pageFound.pageText;
                }
                this.srdoDataService
                  .getSrdoByComponentIdVersionNumber(
                    this.selectedVersionId.toString(),
                    "",
                    this.selectedPageId.toString()
                  )
                  .subscribe(
                    (data) => {
                      this.srdoListData = data;
                      let srdoFound = this.srdoListData.find(
                        (item) => +item.id === this.selectedSRDOId
                      );
                      if (srdoFound) {
                        this.selectedSRDO = srdoFound.srdoName;
                        this.displayMenuData.srdo = srdoFound.srdoName;
                        let isAdmin = this.useraccess.actions.roles.find(
                          (x) => x.id == 1
                        );
                        let isGlobalRead = this.useraccess.actions.roles.find(
                          (x) => x.id == 9
                        );
                        if (
                          isAdmin !== undefined ||
                          isGlobalRead !== undefined
                        ) {
                          this.isNavigable = true;
                        } else {
                          let component =
                            this.useraccess.actions.components.find(
                              (component) => {
                                return (
                                  component.componentName ===
                                  this.selectedComponent
                                );
                              }
                            );
                          if (component) {
                            this.isNavigable = true;
                          } else {
                            this.isNavigable = false;
                          }
                        }
                      }
                      this.spinner.hide();
                    },
                    (error) => {
                      this.spinner.hide();
                    }
                  );
              },
              (error) => {
                this.spinner.hide();
              }
            );
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  confirmationEvent(event: UserConfirmActions) {
    this.showConfirmation = false;
    if (event.confirmAction) {
        let parameters = {
          Id: this.menuDbid,
          deleteMenuChildren: true,
          deleteLinkedFeatures: event.userConfirmAction,
        };
        this.deleteMenuAPICall(parameters);
    }
  }

  confirmationEventUnlinking(status: boolean) {
    if(status) {
        this.unLinkCommonMenu()
    }
    this.showConfirmation = false
  }

  navigateToComponentData() {
    this.spinner.show();
    this.menuService
      .componentDataForNavigationFromMenu(this.menuDbid, "false")
      .subscribe(
        (versionId: Number) => {
          this.spinner.hide();
          let data = {
            controlType: "SRDO",
            componentName: this.selectedComponent,
            versionId: +versionId,
            srdoId: this.selectedSRDOId,
            commandResponseId: 0,
            linkName: this.selectedSRDO,
          };
          this.commonService.selectedSRDOCommand = data;
          this.route.navigateByUrl("/component-data");
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  /**
   * Link common menu to system - Show Modal
   */
  linkCommonMenuToSystem() {
    this.linkCommonMenuOpen = true;
    this.linkCommonMenuData = [];
    this.menuDetails = {"Destination Menu Node": this.selectedItem.name }
    this.linkCommonMenuData.push({
      title: "Component",
      values: this.componentService.componentData,
      disabled: false,
      selectedValue: { id: "", name: "Please select source component" },
    });
    this.linkCommonMenuData.push({
      title: "System template",
      values: [],
      disabled: true,
      selectedValue: { id: "", name: "" },
    });
    // this.linkCommonMenuData.push({ title: "Command", values: [], disabled: true, selectedValue: { id: '', name: '' } })
  }

  /**
   * Link common menu to system - Hide Modal
   * @param state
   */
  public closeCommonMenuModal(state: boolean) {
    // this.linkCommonMenuOpen = false;
    if(state) {
      this.spinner.show();
      let componentId = this.linkCommonMenuData[0]?.selectedValue.id
      let titleId = this.linkCommonMenuData[1]?.selectedValue.id 
      let targetMenuId = this.menuId
      let systemId = +this.router.snapshot.paramMap.get("id");
        this.menuService.linkCommonMenuData(+componentId, +titleId, targetMenuId, systemId).subscribe(
          (res) => {
            if(res) {
              this.ToastMessage('Menu linked successfully', 'bg-success')
              this.linkCommonMenuOpen = false;
              this.loadMenuList(Number(res));
            } else {
              this.ToastMessage("Menu linking failed", "bg-danger");
              this.spinner.hide()
            }
          }, error => this.spinner.hide()
        )
    } else {
      this.linkCommonMenuOpen = false;
      this.linkCommonMenuData[0].selectedValue = { id: '', name: '' }
      this.linkCommonMenuData[1].selectedValue = { id: '', name: '' }
      this.changeCopyButtonState()
    }
  }

  unLinkCommonMenuFromSystem() {
   this.deleteConfirmTitle = 'Unlink a common menu'
   this.deleteConfirmNote = 'Are you sure you want to proceed with unlinking?'
   this.deleteConfirmMessage = ''
   this.showConfirmation = true
  }


  unLinkCommonMenu() {
    this.spinner.show()
    let componentId = this.selectedItem.ComponentId
    let titleId = this.selectedItem.TitleId
    let systemId = +this.router.snapshot.paramMap.get("id");
    this.menuService.unlinkCommonMenuData(+componentId, +titleId, systemId).subscribe(
      (res) => {
        if(res) {
          this.showConfirmation = false
          this.ToastMessage('Menu unlinked successfully', 'bg-success')
          this.loadMenuList(0, "delete");
          // this.onSelectedMenu(this.menuId);
        } else {
          this.ToastMessage("Menu unlinking failed", "bg-danger");
          this.spinner.hide()
        }
       
      }, error => this.spinner.hide()
    )
  }

  /**
   * Selection change from the modal - Call back function
   * @param item
   */
  public selectionChange(item: any) {
    if (item.extra == "Component") {
      this.spinner.show();
      this.linkCommonMenuData[0].selectedValue = item
      this.linkCommonMenuData[1].selectedValue = { id: '', name: '' }
      this.menuService.getMenuTitles(item.id.toString()).subscribe(
        (res) => {
          this.spinner.hide();
          if (res != null) {
            Object(res).forEach((obj) => {
              renameKey(obj, "title", "name");
            });
            this.linkCommonMenuData[1].values = res;
            this.linkCommonMenuData[1].disabled = false;
            this.changeCopyButtonState();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    } else {
      this.linkCommonMenuData[1].selectedValue = item
      this.changeCopyButtonState()
    }
  }

  public changeCopyButtonState() {
    let item = this.linkCommonMenuData.find((item) => item.selectedValue.id.length === 0)
    this.linkButtonState = false;
    if (item) {
      this.linkButtonState = true;
    }
  }

  /**
   * Navigation to system template menu
   */
  navigateToSystemTemplate() {
     this.commonService.navigateToSystemTemplate = this.commonMenuByIdData;
     this.route.navigateByUrl('/component-data')
  }

}


export enum MenuActions {
 AddMenu = 'AddMenu',
 DeleteMenu = 'DeleteMenu',
 ImportMenu = 'ImportMenu',
 UpdateMenu ='UpdateMenu',
 UnlinkFeature ='RemoveFeatureLink'
}

interface DisplayMenuItem {
  title: string,
  security: string,
  widget: string,
  feature: string,
  component?: string,
  srdo?: string,
  page?: string,
  version?: string,
  value?: string
}

