import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { VersionchangesService } from './versionchanges.service';
import { ComponentVersions, VersionChangeData } from '../models/component/component.data.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { GridComponent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-versionchanges',
  templateUrl: './versionchanges.component.html',
  styleUrls: ['./versionchanges.component.scss'],
})
export class VersionchangesComponent implements OnInit{
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @Input() sourceComponentId: number
  @Input() tragetComponentId: number
  data: VersionChangeData[] = []
  gridData: VersionChangeData[] = []
  statusTitle: string = ""
  isLoading = false
  diffSubscription: Subscription;
  constructor(private versionService: VersionchangesService, private spinner: NgxSpinnerService) {
    this.diffSubscription = this.versionService.fetchchVersionChangesData$.subscribe((data) => {
      this.statusTitle = `${data.componentName} Component Version ${data.tragetComponentVersion} Changes`
      this.fetchDifferences(data)
     })
  }

  ngOnInit(): void {
    
  }

  fetchDifferences(data: ComponentVersions) {
    this.spinner.show()
      this.versionService.fetchVersionDifferences(data.sourceComponentId, data.targetComponentId).subscribe((data) => {
        this.data = data;
        this.updateVersionsWithStyleClasses()
        this.gridData = this.data
        this.spinner.hide()
      }, error => {
        this.spinner.hide()
      })
  }

  updateVersionsWithStyleClasses() {
     this.data.map((item) => {
      switch(item.status) {
        case 1:
            item.statusstyle = 'added'
            item.statusname = "Added"
          break;
        case 2:
            item.statusstyle = 'updated'
            item.statusname = "Updated"
          break;
        default:
           item.statusname = "Not Available"
            item.statusstyle = 'deleted'
          break;
      }
    })
  }

  public onFilter(inputValue: string): void {
    this.gridData = process(this.data, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'title',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data
    this.dataBinding.skip = 0;
  }

  sortChange($event) {

  }

  ngOnDestroy(): void {
    this.diffSubscription?.unsubscribe()
  }

  
}
