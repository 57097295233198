import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  faArrowDown,
  faArrowUp,
  faClone,
  faCopy,
  faEdit,
  faFileImport,
  faLink,
  faPlus,
  faRetweet,
  faSearch,
  faSort,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Access,
  DataType,
  SrdoCachedData,
  SrdoModel,
  SrdoPublished,
  SrdoSecurityLevels
} from 'src/app/models/component/srdo-data.model';
import { UserAccess } from '../../global/user-access';
import { SrdoDataService } from '../../services/component/srdo-data.service';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';
import { ToastService } from '../../services/toast/toast.service';
import { CustomFormValidationsService } from '../../shared/formValidations/custom-form-validations.service';

import {
  DataStateChangeEvent,
  GridComponent,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { DisplayItem, DisplayModel } from 'src/app/common/copy-modal/copy-modal.component';
import { LinkedFeatureSystem } from 'src/app/common/display-list-modal/display-list-modal.component';
import { CanDeactivateGuard } from 'src/app/services/can-deactivate-guard.service';
import { CommonService } from 'src/app/services/common.service';
import { CommandResponseService } from 'src/app/services/component/command-response.service';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { FilterType } from '../command-response-data/command-response-data.component';
import { renameKey, SRDODataActions } from 'src/app/global/app-enums.enum';

const EnumErrorMsg = 'Enumeration value should be between min and max range'
@Component({
  selector: 'app-srdo-data',
  templateUrl: './srdo-data.component.html',
  styleUrls: ['./srdo-data.component.scss']
})
export class SrdoDataComponent implements OnInit, CanDeactivateGuard {
  srdFrom: UntypedFormGroup;
  faClone = faClone;
  faSearch = faSearch;
  faLink = faLink;
  faFileImport = faFileImport;
  faArrowUp = faArrowUp;
  srdoDataTypes: DataType[] = [];
  srdoAccess: Access[] = [];
  srdoSecurityLevels: SrdoSecurityLevels[] = [];
  srdoCachedData: SrdoCachedData[] = [];
  srdoPublished: SrdoPublished[] = [];
  selectedDataTypeLabel = 'Data Type -Ounit 8';
  selectedDataTypeVal = 'OUint8';
  dataTypeClass = 'data-type-hide';
  submitted = false;
  submittedEnumData = false;
  selectedDataType: any = '';
  selectedreadSecurityLevel: any = '';
  selectedwriteSecurityLevel: any = '';
  selectedcachedData: any = 0;
  selectedpublished: any = 0;
  selectedaccess: any = '';
  // tslint:disable-next-line: variable-name
  srdo_id: number = null;
  componentId: number;
  componentVersionId: any;
  faPlus = faPlus;
  faCopy = faCopy;
  faTrash = faTrash;
  faEdit = faEdit;
  faRetweet = faRetweet;
  faSort = faSort;
  faArrowDown = faArrowDown;
  text: string;
  faTimes = faTimes;
  dataTypeName = 'Oint';
  customDataType: DataType[] = [];
  enumDataType = [15, 16, 17];
  bitmaskDataType = [11, 12, 13, 14];
  primitiveDataType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  showGrid = true;
  showForm = false;
  showConfirmation = false;
  fileevent: any;
  srdoListItemSelected: number;
  srdoPageSelected = '';
  oldSrdoPageSelected = ''
  showLinkModal = false;
  showMenu = true;
  linkedFeatures: LinkedFeatureSystem[];
  selectionFlag = -1;
  // tslint:disable-next-line:ban-types
  setInputMax: string;
  private customaData: any;
  srdoDataList: SrdoModel = {
    id: null,
    name: '',
    srdoid: null,
    page: null,
    dataType: '',
    quantity: null,
    arrayColumnName: '',
    units: '',
    searchKeywords: '',
    groupVal: false,
    description: '',
    defaultValue: '',
    inputMin: '',
    inputMax: '',
    extraValues: '',
    // dataDetails: '',
    dataTypeDetails: [{}],
    access: '',
    readSecurityLevel: '',
    writeSecurityLevel: '',
    address: '',
    spare1: '',
    dataCategory: '',
    cachedData: false,
    published: false,
    messageId: '',
    messageStructureItem: '',
  };

  copysrdoDataList: SrdoModel;
  //https://utcbis.visualstudio.com/Elevator%20Data%20Dictionary/_workitems/edit/918514
  groupByValue = false;
  srdoImportFile: UntypedFormGroup;
  bulkEnumFromData: UntypedFormGroup;
  selectedComponentSRDOData: any = {};
  srdoListRes = [];
  selectedPage: any = '';
  public gridView: any = [{}];
  list: {}[]; // Table data
  enableFieldFLag = false;
  extraValueSplit: string[] = [];
  private editedRowIndex: number;
  private isNew = false;
  public formGroup: UntypedFormGroup;
  public editId: 0;
  public bitmasks: UntypedFormArray;
  public customs: UntypedFormArray;
  bitmaskDataTypeArr = [];
  public bitmaskGridView: GridDataResult;
  public CAskip = 0;
  public CAsort: Array<SortDescriptor> = [];
  public CApageSize = 5;
  public skip = 0;
  public sort: Array<SortDescriptor> = [];
  public pageSize = 5;
  public customGridView: GridDataResult;
  public editBitmaskData: any = [{}];
  public checkboxFlag = false;
  public textValue = '';
  enumErrorMessage = null;
  copyEnumErrorMessage = null;
  bitmask_Enum_Errors = []
  submitError = null;
  dataTypeChangeEvent = 0;
  selDataTypeName = '';
  public gridViewObject: any = [{}];
  customDataField = 1;
  UnitDescriptorAccess = false;
  ArrayNameAccess = false;
  AddSRDOAccess = false;
  deleteSRDOAccess = false;
  editSRDOAccess = false;
  ImportSRDOAccess = false;
  errorMsg = '';
  customErrMsg = '';
  addStatus = 0;
  customError = [];
  maxminError = 'Maximum value is less than the minimum value. Please check your data and try again.'
  @ViewChild(GridComponent)
  public grid: GridComponent;
  enumBulkEditParent: string = '';
  bitmaskForm: any;
  multilineText = `You have unsaved changes. You can discard your changes, or cancel to continue editing.`

  //Copy SRDO From Other Component
  copySRDOFromOtherComp = false;
  selectedSRDODetails = { componentName: '', componentId: '', version: '', versionId: '', srdo: '', srdoId: '', pageNumber: '' }
  copySRDODetails: DisplayItem[] = [];
  copyButtonState: boolean = true;
  componentDetails = {};
  copySRDOError: string = ''
  pageData: any = [];
  selectedFilter = FilterType.Name;
  ascending = true
  //Custom data type rearrange
  rearrangeCustomTypes = false;
  rearrangeState = false;
  sortyByItems = [FilterType.Name, FilterType.Id, FilterType.CreatedDate, FilterType.ModifiedDate]
  isVersionReopned = false
  showNotFound = false
  groupDisable = false
  constructor(private srdoDataService: SrdoDataService,
    private formBuilder: UntypedFormBuilder,
    public toastService: ToastService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private confirmationDialogService: DialogboxService,
    private fb: UntypedFormBuilder,
    private commandResponseService: CommandResponseService,
    private customFormValidationsService: CustomFormValidationsService,
    private useraccess: UserAccess,
    private componentService: ComponentListService
  ) { }

  ngOnInit() {
    this.srdoImportFile = this.formBuilder.group({
      formFile: ['', Validators.required]
    });
    this.bulkEnumFromData = this.formBuilder.group({
      enumTextarea: ['', Validators.required]
    });
    this.initForm();
    this.onChanges();
    this.router.paramMap.subscribe(params => {
      if (this.router.snapshot.paramMap.get('id') != null) {
        this.spinner.show();
        this.isVersionReopned = this.commonService.isReopened;
        this.componentId = Number(this.router.snapshot.paramMap.get('id'));
        const componentVersionId = this.router.snapshot.paramMap.get('componentVersionId');
        this.componentVersionId = componentVersionId;
        this.selectedFilter = FilterType.Name;
        this.ascending = true
        this.selectedPage = ""
        this.getPageByComponentIdVersionNumber(this.componentVersionId, '');
        this.srdoDataService.getSRDOlistbyComponentService(componentVersionId).subscribe((listRes) => {
          this.selectedComponentSRDOData = listRes;
          if (this.selectedComponentSRDOData.srdos.length > 0) {
            let index = 0
            if (this.commonService.selectedSRDOCommand) {
              let foundIndex = this.selectedComponentSRDOData.srdos.findIndex(item => item.name === this.commonService.selectedSRDOCommand.linkName)
              index = foundIndex === -1 ? 0 : foundIndex
              if (foundIndex !== -1) {
                this.text = this.commonService.selectedSRDOCommand.linkName
              }
              this.srdoListRes = this.selectedComponentSRDOData.srdos;
              this.srdo_id = this.selectedComponentSRDOData.srdos[index].id;
              this.srdoListItemSelected= this.selectedComponentSRDOData.srdos[index].id;
            }
            else {
              this.srdoListRes = this.selectedComponentSRDOData.srdos;
              if (this.text && this.text.length > 0) {
                let searchText = this.text
                this.srdoListRes = this.selectedComponentSRDOData.srdos.filter(function (item) {
                  return JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase());
                });
                this.srdo_id = this.srdoListRes[0]?.id;
                this.srdoListItemSelected = this.srdoListRes[0]?.id;
                if (this.srdoListRes.length === 0) {
                  this.spinner.hide();
                  this.showNotFound = true
                  this.srdo_id = null
                  return;
                }
              } else {
                this.srdo_id = this.srdoListRes[0]?.id;
                this.srdoListItemSelected = this.srdoListRes[0]?.id;
              }
            }
            // this.getSrdoByPageCompVer(this.componentId, componentVersionId, 0);
            // binding dynamic dropdown master data
            this.getSrdoListData();
          } else {
            this.checkAccess(SRDODataActions.import, this.componentId);
            this.checkAccess(SRDODataActions.AddSRDO, this.componentId);
            this.checkAccess(SRDODataActions.UpdateSRDO, this.componentId);
            this.srdoListRes = [];
            this.srdo_id = null;
            this.showNotFound = true;
            // binding dynamic dropdown master data
            this.getSrdoListData();
          }
          //Imp: Set this to undefined to avoid auto selecting
          this.commonService.selectedSRDOCommand = undefined
        }, (() => {
          this.spinner.hide();
        }));
      }
    });
    this.commonService.reopenedVersionObservable().subscribe((status) => {
        this.isVersionReopned = this.commonService.isReopened
    })
    this.getBitmaskDataType();
  }

  @HostListener('window:beforeunload', ['$event'])
  async ngOnDestroy($event) {
    $event?.preventDefault();
    if (this.srdFrom.dirty) {
      let result = window.confirm("Changes you made may not be saved.");
      $event.returnValue = 'A message.';
    }
  }


  initForm() {
    this.srdFrom = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      srdoid: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      page: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      dataType: [{ value: null }, Validators.required],
      quantity: [null, [Validators.pattern('^[0-9]*$')]],
      arrayColumnName: [null, Validators.maxLength(50)],
      units: [null, Validators.maxLength(50)],
      description: [null, Validators.required],
      searchKeywords: [''],
      groupVal:[{value: false}],
      defaultValue: [null, [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]],
      inputMin: [null, [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]],
      inputMax: [null, [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]],
      extraValues: [null],
      // dataDetails: [null], /^\w+(,\w+)*$/ Validators.pattern('/[0-9]+[,]?[0-9]*?$/')
      access: [{ value: null }, Validators.required],
      readSecurityLevel: [{ value: null }, Validators.required],
      writeSecurityLevel: [{ value: null }, Validators.required],
      address: [{ value: '' }, Validators.required],
      spare1: [null],
      dataCategory: [null],
      cachedData: [{ value: null, disabled: true }],
      published: [{ value: false, disabled: true }],
      messageId: [null],
      messageStructureItem: [null],
      bitmasks: this.fb.array([this.bitmask()]),
      customs: this.fb.array([this.custom()])
    }, {
      validator: [
        this.customFormValidationsService.MatchDataTypeValidations('inputMin', 'inputMax', 'dataType', 'extraValues', 'defaultValue'),
        this.customFormValidationsService.setBitmaskValidation('dataType', 'bitmasks')
      ],
    });
  }

  copyText() {
    var copyText = this.bulkEnumFromData.get('enumTextarea').value
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText);
  }

  getSrdoListData() {
    this.spinner.show()
    this.srdoDataService.getSrdoMasterListData().subscribe((data) => {
      if (data !== null) {
        this.srdoDataTypes = data.srdoDataTypes.filter((x) => {
         return !(x.name == 'Command' || x.name == 'Char' || x.name.includes('Hex') || x.name.includes("Text"))
        });
        if (this.srdoDataTypes.length > 0) {
          // tslint:disable-next-line: no-shadowed-variable
          const customDataTypes = this.srdoDataTypes.filter((DataType) => {
            return DataType.name != 'Custom';
          });
          this.customDataType = customDataTypes;
        }
        this.srdoAccess = data.srdoAccess;
        this.srdoSecurityLevels = data.srdoSecurityLevels;
        this.srdoCachedData = data.srdoCachedData;
        this.srdoPublished = data.srdoPublished;
      }
      this.spinner.hide();
      if(this.srdo_id) {
        this.onSrdoSelect(this.srdo_id)
      }
    },
      (() => {
        this.spinner.hide();
      }));
  }

  // convenience getter for easy access to form fields
  get srdoFormControl() { return this.srdFrom.controls; }

  onDataTypeChange(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;
    this.selectedDataTypeLabel = 'Data Type -' + selectedText;
    this.selectedDataTypeVal = event.target.value;
    this.dataTypeName = event.target.value;
    if (selectedText === 'OUint8') {
      this.dataTypeClass = 'data-type-hide';
    } else {
      this.dataTypeClass = '';
    }
  }
  
  hasDuplicates(arr) {
    return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
  }

  hasNegitiveIndexes(arr: number[]) {
    let items = arr.find(x => x < 0);
    if(items) {
      return true
    }
    return false
  }

  checkForDuplicateSrdo(id: number, name: string) {
      let found = false
      this.srdoListRes.forEach((item) => {
          if(item.id != +id) {
             if(item.name.toLowerCase() == name.toLowerCase())
             {
              found = true;
              return
             }
          }
      })
      return found
  }

  onSrdoSubmit() {
    if (this.rearrangeCustomTypes) {
      return
    }
    this.bitmask_Enum_Errors = []
    if (this.srdFrom.get('cachedData').disabled && this.srdFrom.valid) {
      this.srdFrom.controls.cachedData.enable();
    }
    if (this.srdFrom.get('published').disabled && this.srdFrom.valid) {
      this.srdFrom.controls.published.enable();
    }
    this.errorMsg = '';
    this.submitted = true;
    let dataTpDetails: any = [];
    let srdoFormData: object;
    const invalid = [];
    if (this.dataTypeName.includes('Custom')) {
      this.srdFrom.get('defaultValue').setValidators([]);
      this.srdFrom.get('defaultValue').updateValueAndValidity();
      this.srdFrom.get('inputMin').setValidators([]);
      this.srdFrom.get('inputMin').updateValueAndValidity();
      this.srdFrom.get('inputMax').setValidators([]);
      this.srdFrom.get('inputMax').updateValueAndValidity();
    }
    if (this.dataTypeName.includes('Boolean') || this.dataTypeName.includes('Float') || this.dataTypeName.toLowerCase().includes('int')) {
      this.removeBitmaskValidators();
    }
    const controls = this.srdFrom.controls;
    // tslint:disable-next-line:no-shadowed-variable
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    if (invalid.length > 0) {
      this.errorMsg = 'Please check the ' + invalid + ' data.';
    }
    if (this.srdFrom.invalid) {
      return;
    }
    const srdoData = this.srdFrom.getRawValue();
    const name = srdoData.name;
    const srdoid = srdoData.srdoid;
    const page = srdoData.page;
    const dataType = Number(srdoData.dataType);
    const quantity = srdoData.quantity;
    const arrayColumnName = srdoData.arrayColumnName;
    const description = srdoData.description;
    const units = srdoData.units;
    const defaultValue = srdoData.defaultValue;
    const inputMin = srdoData.inputMin;
    const inputMax = srdoData.inputMax;
    const extraValues = srdoData.extraValues;
    const searchKeywords = srdoData.searchKeywords;
    const groupVal = srdoData.groupVal
    const access = srdoData.access;
    const readSecurityLevel = srdoData.readSecurityLevel;
    const writeSecurityLevel = srdoData.writeSecurityLevel;
    const address = srdoData.address;
    const spare1 = srdoData.spare1;
    const dataCategory = srdoData.dataCategory;
    const cachedData = srdoData.cachedData;
    const published = srdoData.published;
    const messageId = srdoData.messageId;
    const messageStructureItem = srdoData.messageStructureItem;
    const createdBy = 'test@gmail.com';

    let duplicateSrdo = this.checkForDuplicateSrdo(this.srdo_id , name)
    if(duplicateSrdo) {
      this.errorMsg = 'SRDO name should be unique';
      return
    }
    this.checkArrayNameAccess(quantity);
    if (this.srdFrom.get('extraValues').value !== null) {
      this.extraValueSplit = this.srdFrom.get('extraValues').value.length > 0 ? this.srdFrom.get('extraValues').value.split(',') : [];
      if(!this.dataTypeName.includes('Float') && !this.dataTypeName.includes('Custom')) {
        for (const entry of this.extraValueSplit) {
          if (this.customFormValidationsService.getDataTypeRange(srdoData.dataType, BigInt(entry)) === false) {
            this.errorMsg = 'Value should be between in the range of min to max ';
            return;
          }
        }
      }
    }
    if (this.dataTypeName.toLowerCase().includes('int') ||
      this.dataTypeName.toLowerCase().includes('boolean') ||
      this.dataTypeName.toLowerCase().includes('float')) {
      dataTpDetails = [{
        defaultValue,
        inputMin,
        inputMax,
        extraValues
      }];
      if( Number(this.srdFrom.get('inputMax').getRawValue()) < Number(this.srdFrom.get('inputMin').getRawValue())) {
        this.errorMsg = this.maxminError;
        return;
      }
    } else if (this.dataTypeName.includes('Enum')) {
      const EnumObjArr = [];
      let i = 0;
      if (this.srdFrom.get('extraValues').getRawValue() !== null) {
        this.extraValueSplit = this.srdFrom.get('extraValues').getRawValue().length > 0 ? this.srdFrom.get('extraValues').getRawValue().split(',') : [];
      }
      if (this.gridView.length === 0) {
        this.errorMsg = ' Provide Enumeration information';
        return;
      }
      if( Number(this.srdFrom.get('inputMax').getRawValue()) < Number(this.srdFrom.get('inputMin').getRawValue())) {
        this.errorMsg = this.maxminError;
        return;
      }
      const enumArray: number[] = [];
      this.gridView.forEach((element, index) => {
        enumArray.push(Number(this.gridView[index].index));
      });
      if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
        this.errorMsg = 'Please provide valid enumeration data';
        return;
      }
      if (enumArray && enumArray.length > 0) {
        for (const enm of enumArray) {
          if(this.extraValueSplit.length > 0 && this.extraValueSplit.find(item => +item == enm)){
          }
          else if((Number(inputMax) < enm)  || (Number( inputMin ) > enm)) {
            this.errorMsg = 'Enumeration value should be between min and max range';
            return;
          }
        }
      }
      for (const prop of this.gridView) {
        for (const entry of this.extraValueSplit) {
          if (Number(entry) === Number(prop.index)) {
            i = 1;
          }
        }
        if ((i === 0 && (Number(prop.index) > Number(this.srdFrom.get('inputMax').getRawValue())))
          || (i === 0 && (Number(prop.index) < Number(this.srdFrom.get('inputMin').getRawValue())))) {
          this.errorMsg = EnumErrorMsg;
          return;
        }
      }
      for (const prop of this.gridView) {
        const enumObj = {
          index: prop.index,
          value: prop.value,
          inputMin: srdoData.inputMin,
          inputMax: srdoData.inputMax,
          extraValues: srdoData.extraValues,
          defaultValue: srdoData.defaultValue
        };
        dataTpDetails.push(enumObj);
      }
    } else if (this.dataTypeName.includes('Bitmask')) {
      const bitmasksArr = this.srdFrom.get('bitmasks').getRawValue();
      if( Number(this.srdFrom.get('inputMax').getRawValue()) < Number(this.srdFrom.get('inputMin').getRawValue())) {
        this.errorMsg = this.maxminError
        return;
      }
      if (bitmasksArr.length > 0) {
        let bitmaskData;
        let bitmaskIndex = -1;
        for (const bitmask of bitmasksArr) {
          bitmaskIndex += 1
          //If the inputString is NOT a match
          if (/[a-zA-Z]/.test(bitmask.bits)) {
            this.errorMsg = 'Bitmask should be allowed only interger values';
            return;
          } else {
            this.errorMsg = '';
          }
          const bitmasksEnumsArr = bitmask.bitmasks_enums;

          const bitdataType = bitmask.data_type;
          const bitEnumObjArr = [];
          if (Number(bitmask.data_type) === 1) {
            let enmValu = '';

            const enumArray: any[] = [];
            bitmasksEnumsArr.forEach((element, index) => {
              enumArray.push(Number(bitmasksEnumsArr[index].bit_index));
            });
            if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
              this.bitmask_Enum_Errors[bitmaskIndex] = 'Please provide valid bitmask data';
              return;
            }
            for (const bitEnum of bitmasksEnumsArr) {
              // tslint:disable-next-line:max-line-length

              const messageFlagBits = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(bitmask.bits, 0);
              const messageFlag = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(bitmask.bits, 1);

              if (enmValu < messageFlag && enmValu === '') {
                enmValu = bitEnum.bit_index;
              } else if (enmValu < bitEnum.bit_index) {
                enmValu = bitEnum.bit_index;
              }
              if (enmValu >= messageFlag) {
                this.bitmask_Enum_Errors[bitmaskIndex] = 'Value should not be greater than ' + (messageFlag - 1);
                return;
              }
              const enumObj = {
                index: bitEnum.bit_index,
                value: bitEnum.bit_value,
                inputMin: srdoData.inputMin,
                inputMax: srdoData.inputMax
              };
              bitEnumObjArr.push(enumObj);
              if (messageFlag < bitEnumObjArr.length) {
                this.bitmask_Enum_Errors[bitmaskIndex] = 'Enumeration bit rows should not be greater than ' + messageFlagBits;
                return;
              }
            }

            bitmaskData = {
              index: bitmask.bits,
              defaultValue: srdoData.defaultValue,
              dataTypeId: bitmask.data_type,
              value: bitEnumObjArr,
              inputMin: srdoData.inputMin,
              inputMax: srdoData.inputMax,
              text: bitmask.value,
              extraValues: extraValues,
            };
          } else {

            bitmaskData = {
              index: bitmask.bits,
              dataTypeId: bitmask.data_type,
              defaultValue: srdoData.defaultValue,
              value: bitmask.value,
              text: bitmask.value,
              inputMin: srdoData.inputMin,
              inputMax: srdoData.inputMax,
              extraValues: extraValues,
            };
          }
          dataTpDetails.push(bitmaskData);
        }
      }
    } else if (this.dataTypeName.includes('Custom')) {
      const customsArr = this.srdFrom.get('customs').getRawValue();
      let c = 0;
      if (customsArr.length > 0) {
        let customData;
        for (const custom of customsArr) {
          if (custom.data_types != undefined) {
            if(Number(custom.data_types) == 10) {
              this.minMaxValidations(custom.data_types, custom.minval, c, 'yes')
              if (this.customError[c]) {
                this.errorMsg ='Min value should be 0 and Max value should be between 0 to 255'
                return;
              }
              this.errorMsg = ''
              this.minMaxValidations(custom.data_types, custom.maxval, c);
            }
            else {
              this.minMaxValidations(custom.data_types, custom.minval, c);
              this.minMaxValidations(custom.data_types, custom.maxval, c);
            }
            if (!!this.errorMsg) {
              return;
            }
            let ctmArray = [];
            this.customError.forEach(object => {
              ctmArray.unshift(object);
            });
            if (ctmArray[0] !== null)
              return;
          }
          custom.data_types = this.srdFrom.get('customs')['controls'][c].controls.data_types.getRawValue();
          if(+custom.data_types !== 9) { // Float should not have validations
            if( BigInt(custom.maxval) < BigInt(custom.minval)) {
              this.errorMsg = this.maxminError;
              return;
            }
          }  
          const customsEnumsArr = custom.customs_enums;
          const customsBitmaskArr = custom.customs_bitmasks;
          const customEnumObjArr = [];
          const customBitmaskObjArr = [];
          const primitiveDataTypeFound = this.primitiveDataType.find((primDataType) => {
            return primDataType === Number(custom.data_types);
          });
          const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
            return enumDataTyp === Number(custom.data_types);
          });
          const bitmaskDataTypeFound = this.bitmaskDataType.find((bitmaskDataTyp) => {
            return bitmaskDataTyp === Number(custom.data_types);
          });
          this.srdoDataTypes.forEach((value) => {
            if (value.id === Number(custom.data_types)) {
              custom.dataTypeName = value.name;
            }
          });
          if (enumDataTypeFound) {
            if (customsEnumsArr.length > 0) {
              const enumArray: any[] = [];
              customsEnumsArr.forEach((element, index) => {
                enumArray.push(Number(customsEnumsArr[index].custom_enum_index));
              });
              if (this.srdFrom.get('customs')['controls'][c].controls['extraval'].getRawValue() !== null) {
                let value = this.srdFrom.get('customs')['controls'][c].controls['extraval'].getRawValue()
                this.extraValueSplit = value.length > 0 ? value.split(',') : [];
              }
              if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
                this.errorMsg = 'Please provide valid enumeration data';
                return;
              }
              for (const customEnum of customsEnumsArr) {
                const enumObj = {
                  index: customEnum.custom_enum_index,
                  value: customEnum.custom_enum_value,
                };
                let inputMin = this.srdFrom.get('customs')['controls'][c]['controls'].minval.getRawValue()
                let inputMax = this.srdFrom.get('customs')['controls'][c]['controls'].maxval.getRawValue()
                if(this.extraValueSplit.length > 0 && this.extraValueSplit.find(item => +item == Number(customEnum.custom_enum_index))){
                }
                else if((Number(inputMax) < Number(customEnum.custom_enum_index))  || (Number( inputMin ) > Number(customEnum.custom_enum_index))) {
                  this.errorMsg = 'Enumeration value should be between min and max range'
                  return;
                }
                customEnumObjArr.push(enumObj);
              }
              customData = {
                name: custom.name,
                quantity: custom.qty,
                description: custom.desc,
                dataTypeId: custom.data_types,
                dataTypeName: custom.dataTypeName,
                defaultValue,
                inputMin: custom.minval,
                inputMax: custom.maxval,
                extraValues: custom.extraval,
                customDtaDeatils: customEnumObjArr
              };
            }
            else {
                this.errorMsg = 'Please provide valid enumeration data';
                return;
            }
          }
          if (bitmaskDataTypeFound) {
            let bitmaskData;
            let bitmaskIndex = -1;
            if (customsBitmaskArr && customsBitmaskArr.length > 0) {
              for (const customBitmask of customsBitmaskArr) {
                bitmaskIndex += 1
                const cusBitMaskDataType = customBitmask.data_type;
                const bitmasksCustEnumsArr = customBitmask.custom_bitmasks_enums;
                const bitCustEnumObjArr = [];
                let cusDataTypeName = '';
                // tslint:disable-next-line:prefer-const
                var bitMaskArray = [];
                bitMaskArray[1] = 'enum';
                bitMaskArray[2] = 'bitmask';
                bitMaskArray[3] = 'number';

                // tslint:disable-next-line:forin
                for (let bitmaskkey in bitMaskArray) {
                  var value = bitMaskArray[bitmaskkey];
                  if (Number(bitmaskkey) === Number(cusBitMaskDataType)) {
                    cusDataTypeName = value;
                  }
                }
                if (Number(cusBitMaskDataType) === 1) {
                  let enmValu = '';
                  const enumArray: any[] = [];
                  bitmasksCustEnumsArr.forEach((element, index) => {
                    enumArray.push(Number(bitmasksCustEnumsArr[index].custom_bit_index));
                  });

                  if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
                    this.bitmask_Enum_Errors[bitmaskIndex] = 'Please provide valid enumeration data';
                    return;
                  }

                  for (const bitCusEnum of bitmasksCustEnumsArr) {
                    const messageFlagBits = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(customBitmask.bits, 0);
                    const messageFlag = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(customBitmask.bits, 1);

                    if (enmValu < messageFlag && enmValu === '') {
                      enmValu = bitCusEnum.custom_bit_index;
                    } else if (enmValu < bitCusEnum.custom_bit_index) {
                      enmValu = bitCusEnum.custom_bit_index;
                    }
                    if (enmValu >= messageFlag) {
                      this.bitmask_Enum_Errors[bitmaskIndex] = 'Value should not be greater than ' + (messageFlag - 1);
                      return;
                    }

                    const enumObj = {
                      index: bitCusEnum.custom_bit_index,
                      value: bitCusEnum.custom_bit_value,
                    };
                    bitCustEnumObjArr.push(enumObj);
                    if (messageFlag < bitCustEnumObjArr.length) {
                      this.bitmask_Enum_Errors[bitmaskIndex] = 'Enumeration bit rows should not be greater than ' + messageFlagBits;
                      return;
                    }
                  }

                  bitmaskData = {
                    index: customBitmask.bits,
                    dataTypeId: cusBitMaskDataType,
                    dataTypeName: cusDataTypeName,
                    value: bitCustEnumObjArr,
                    text: customBitmask.value
                  };
                } else {
                  bitmaskData = {
                    index: customBitmask.bits,
                    dataTypeId: cusBitMaskDataType,
                    dataTypeName: cusDataTypeName,
                    value: customBitmask.value,
                    text: customBitmask.value
                  };
                }
                customBitmaskObjArr.push(bitmaskData);
              }
              customData = {
                name: custom.name,
                quantity: custom.qty,
                description: custom.desc,
                dataTypeId: custom.data_types,
                dataTypeName: custom.dataTypeName,
                defaultValue,
                inputMin: custom.minval,
                inputMax: custom.maxval,
                extraValues: custom.extraval,
                customDtaDeatils: customBitmaskObjArr
              };
            }
            else {
              this.errorMsg = 'Please provide valid bitmask data';
              return;
            }
          }
          if (primitiveDataTypeFound) {
            customData = {
              name: custom.name,
              quantity: custom.qty,
              description: custom.desc,
              dataTypeId: custom.data_types,
              dataTypeName: custom.dataTypeName,
              defaultValue,
              inputMin: custom.minval,
              inputMax: custom.maxval,
              extraValues: custom.extraval,
              customDtaDeatils: null
            };
          }
          //this.gridView.push(bitmaskData);
          if (customData !== undefined) {
            dataTpDetails.push(customData);
          } else {
            dataTpDetails.push({});
          }
          c = c + 1;
        }
      } else {
        this.errorMsg = "Custom data type details are required"
        return;
      }
    }
    srdoFormData = {
      name,
      description,
      srdoid,
      page,
      dataType,
      quantity,
      searchKeywords,
      groupVal,
      arrayColumnName,
      units,
      dataTypeDetails: dataTpDetails,
      address,
      spare1,
      access,
      readSecurityLevel,
      writeSecurityLevel,
      messageId,
      messageStructureItem,
      dataCategory,
      cachedData
    };
    if (this.srdo_id === null) {
      const addsrdoFormData = Object.assign(srdoFormData, { createdBy });
      this.spinner.show();
      this.srdoDataService.addSRDOData(addsrdoFormData, this.componentVersionId).subscribe((res) => {
        if (res !== null) {
          this.rearrangeState = false;
          this.srdo_id = Number(res);
          this.addStatus = 0;
          this.showNotFound = false
          this.ToastMessage('Srdo Created Successfully.', 'bg-success', 'Success');
          this.loadSrdoListByComponentId(Number(res));
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.ToastMessage(res, 'bg-danger', 'Error');
        }
        this.disableSelectBox();
      }, () => {
        this.disableSelectBox();
        this.spinner.hide();
      });
    } else {
      this.spinner.show();
      const editsrdoFormData = Object.assign(srdoFormData,
        { id: this.srdo_id, componentId: this.componentVersionId, modifiedBy: createdBy });
      this.srdoDataService.updateSRDOData(editsrdoFormData).subscribe((res) => {
        if (res === true) {
          this.rearrangeState = false;
          this.ToastMessage('Srdo Updated Successfully.', 'bg-success', 'Success');
          this.loadSrdoListByComponentId(this.srdo_id);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.ToastMessage(res, 'bg-danger', 'Error');
        }
        this.disableSelectBox();
      }, () => {
        this.disableSelectBox();
        this.spinner.hide();
      });
    }

  }

  onSelectedFileClick(event) {
    this.fileevent = event
    if(!this.srdFrom.pristine) {
      this.showConfirmation = true;
      this.selectionFlag = 5
    }else {
      this.onSelectedFile(this.fileevent)
    }
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.srdoImportFile.get('formFile').setValue(file);
      this.onSubmit();
    }
  }

  onSubmit() {
    const formData = new FormData();
    this.spinner.show();
    formData.append('formFile', this.srdoImportFile.get('formFile').value);
    this.srdoDataService.srdoFileUploadService(formData, this.router.snapshot.paramMap.get('componentVersionId')).subscribe(
      (res) => {
        if (res === true) {
          this.srdoDataService.getSRDOlistbyComponentService(
            this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
              this.spinner.hide();
              this.ToastMessage('Srdo imported successfully', 'bg-success', 'Success');
              this.selectedComponentSRDOData = listRes;
              if (this.selectedComponentSRDOData.srdos.length > 0) {
                this.srdoListRes = this.selectedComponentSRDOData.srdos;
                this.srdo_id = this.selectedComponentSRDOData.srdos[0].id;
                if (this.srdo_id != null) {
                  this.onSrdoSelect(this.srdo_id);
                }
              }
            });
        } else {
          this.spinner.hide();
          this.ToastMessage(res, 'bg-danger', 'Error');
        }
      }, (() => {
        this.spinner.hide();
      }));
  }

  ToastMessage(message, type, headermsg) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: headermsg
    });
  }

  clearSrdoForm() {
    this.srdFrom.reset();
    this.submitted = false;
  }



  srdoListItemClick(id: number) {
    this.srdoListItemSelected = id;
    if(!this.srdFrom.pristine || this.rearrangeState) {
      this.selectionFlag = 1;
      this.showConfirmation = true
    } else {
      this.onSrdoSelect(this.srdoListItemSelected)
    }
  }

  public copyEnumData() {
    this.copyEnumErrorMessage = '';
    this.enumBulkEditParent = 'Enumeration';;
    this.submittedEnumData = false;

    this.textValue = "";
    if (this.gridView.length != 0) {
      for (const prop of this.gridView) {
        let value = prop.index + ":" + prop.value + "\n"
        this.textValue = this.textValue + value
      }
    }
    this.bulkEnumFromData.get('enumTextarea').setValue(this.textValue);
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Show modal with enum values to do bulk editing
  Data Type: Bitmask
  */
  public addEnumDataFromForm(bitmask) {
    this.bitmaskForm = bitmask;
    this.copyEnumErrorMessage = '';
    this.submittedEnumData = false;
    this.enumBulkEditParent = 'Bitmask';
    this.textValue = "";
    if (bitmask.value.bitmasks_enums) {
      let value = ""
      bitmask.value.bitmasks_enums.forEach((item) => {
        if (item.bit_value && item.bit_value.length > 0) {
          value += `${item.bit_index}:${item.bit_value}\n`
        }
      });
      this.textValue = value;
    }
    this.bulkEnumFromData.get('enumTextarea').setValue(this.textValue);
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Show modal with enum values to do bulk editing
  */
  public addCustomEnumData(customEnum) {
    this.copyEnumErrorMessage = '';
    this.bitmaskForm = customEnum
    this.enumBulkEditParent = 'Custom';
    this.submittedEnumData = false;
    let enums = customEnum.get('customs_enums').value
    this.textValue = "";
    let value = ""
    enums.forEach((item) => {
      if (item.custom_enum_value && item.custom_enum_value.length > 0) {
        value += `${item.custom_enum_index}:${item.custom_enum_value}\n`
      }
    });
    this.textValue = value;
    this.bulkEnumFromData.get('enumTextarea').setValue(this.textValue);
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Show modal with enum values to do bulk editing
  */
  public addCustomBitmaskEnumData(customEnum) {
    this.copyEnumErrorMessage = '';
    this.bitmaskForm = customEnum
    this.enumBulkEditParent = 'Custom Bitmask';
    this.submittedEnumData = false;
    let enums = customEnum.get('custom_bitmasks_enums').value
    this.textValue = "";
    let value = ""
    if (enums) {
      enums.forEach((item) => {
        if (item.custom_bit_value && item.custom_bit_value.length > 0) {
          value += `${item.custom_bit_index}:${item.custom_bit_value}\n`
        }
      });
    }
    this.textValue = value;
    this.bulkEnumFromData.get('enumTextarea').setValue(this.textValue);
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Enum Add/Edit Validations
  Data Type: BitMask -> Enum
  */
  public updateBitMaskEnums() {
    let minmax = this.bitmaskForm.value.bits //Read bits values Ex: "bits": "0-1",
    let values = minmax.split('-');
    let maximumValue = 0;
    let value = 0
    if (values.length > 1) {
      value = parseInt(values[1]) - parseInt(values[0])
      maximumValue = Math.pow(2, value + 1)
    } else {
      maximumValue = 2
    }
    this.constructEnums(maximumValue)
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Enum Add/Edit - Creating new array of Enums
  Data Type: BitMask -> Enum
  Data Type: Custom -> Enum
  Data Type: Custom -> BitMask -> Enum
  */
  constructEnums(maximumValue: number) {
    this.copyEnumErrorMessage = ''
    this.spinner.show()
    setTimeout(()=> {
    let arrayValues = this.bulkEnumFromData.get('enumTextarea').value.split("\n").filter(x => x != '');
    let indexesArray = []
    let valuesArray = {}
    let existedElement = ""
    if(arrayValues.length > 2000) {
      this.copyEnumErrorMessage = "Data entries should not exceed 2000 rows."
      this.spinner.hide()
      return
    }
    arrayValues.forEach((item: string) => {
      let spliited = item.split(':')
      const element = indexesArray.find((item) => {
        return item === spliited[0]
      })
      if (element) {
        existedElement = element;
      }
      indexesArray.push(spliited[0])
      valuesArray[spliited[0]] = spliited[1]
    })
    if (existedElement.length > 0) {
      this.copyEnumErrorMessage = 'Duplicate enumeration index value ' + existedElement;
      this.spinner.hide()
      return;
    }
    if (this.enumBulkEditParent === "Custom") {
      this.upDateFormWithContsructedEnums(indexesArray, valuesArray, 'customs_enums')
    } else if (this.enumBulkEditParent === "Custom Bitmask") {
      this.upDateFormWithContsructedEnums(indexesArray, valuesArray, 'custom_bitmasks_enums')
    }
    else {
      this.showErrorsIfAny(indexesArray, maximumValue, valuesArray)
    }
    this.spinner.hide()
    }, 100)

  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Create Error Messages if any of the enum values are not valid
  Data Type: BitMask -> Enum
  Data Type: Custom -> Enum
  Data Type: Custom -> BitMask -> Enum
  */
  public showErrorsIfAny(indexesArray: string[], maximumValue: number, valuesArray: {}): void {
    let sorted = indexesArray.sort((first, second) => {
      if (parseInt(first) > parseInt(second)) {
        return 1;
      }
      return -1;
    })
    this.copyEnumErrorMessage = ""
    if (sorted.length > maximumValue) {
      this.copyEnumErrorMessage = 'Enumeration rows should not be greater than ' + maximumValue;
      return
    }
    else if (parseInt(sorted[sorted.length - 1]) > maximumValue - 1) {
      this.copyEnumErrorMessage = 'Enumeration index should not be greater than ' + (maximumValue - 1);
      return
    }
    this.upDateFormWithContsructedEnums(indexesArray, valuesArray, 'bitmasks_enums')
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Create/Update Enum form elements
  Data Type: BitMask -> Enum
  Data Type: Custom -> Enum
  Data Type: Custom -> BitMask -> Enum
  */
  upDateFormWithContsructedEnums(indexesArray: string[], valuesArray: {}, key: string) {
    this.submittedEnumData = true;
    //Clear Controls
    let clearall = this.bitmaskForm.get(key);
    clearall.clear(); //Corrected approach to clear all controls
    //Add New Controls
    if (key === 'customs_enums') {
      indexesArray.forEach(item => {
        this.bitmaskForm.get(key).push(
          this.fb.group({
            custom_enum_index: item,
            custom_enum_value: valuesArray[item]
          })
        );
      })
    } else if (key === 'custom_bitmasks_enums') {
      indexesArray.forEach(item => {
        this.bitmaskForm.get(key).push(
          this.fb.group({
            custom_bit_index: item,
            custom_bit_value: valuesArray[item]
          })
        );
      })
    }
    else {
      indexesArray.forEach(item => {
        this.bitmaskForm.get(key).push(
          this.fb.group({
            bit_index: item,
            bit_value: valuesArray[item]
          })
        );
      })
    }
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Enum Add/Edit Form Submission
  Data Type: Custom
  */
  updateCustomEnumData() {
    this.constructEnums(0)
  }

  /*
  ------------------------------IMPORT ENUM------------------------------
  Enums Add/Edit form submit actions for different datatypes
  - Enumeration
  - Bitmasks
  - Custom
  */
  onEnumDataSubmit() {
    if (this.enumBulkEditParent === 'Bitmask') {
      this.updateBitMaskEnums()
    } else if (this.enumBulkEditParent === 'Enumeration') {
      this.addBulkEnumData();
    } else {
      this.updateCustomEnumData();
    }
  }


  public addBulkEnumData() {
    this.copyEnumErrorMessage = null;
    if (this.bulkEnumFromData.get('enumTextarea').value != null && this.bulkEnumFromData.get('enumTextarea').value != '') {
      this.submittedEnumData = true;
      const srdoData = this.srdFrom.value;
      var arrayValues = this.bulkEnumFromData.get('enumTextarea').value.split("\n").filter(x => x != '');
      var foundDuplicates = this.checkDuplicateValues(arrayValues, srdoData);
      if (foundDuplicates) {
        this.gridView = [{}];
        let count = 0
        for (var i in arrayValues) {
          var enumsArray = arrayValues[i].split(":");
          const enumObj = {
            index: enumsArray[0],
            value: enumsArray[1],
            inputMin: srdoData.inputMin,
            inputMax: srdoData.inputMax,
            extraValues: srdoData.extraValues
          };
          if (count === 2000) { break; }

          if (i == "0") {
            this.gridView.splice(0, 1);
          }
          this.gridView.push(enumObj);
          count += 1;
        }
      }
    } else {
      this.copyEnumErrorMessage = 'Provide Enumeration information';
      this.submittedEnumData = false;
    }
  }

  checkDuplicateValues(arrayValues, srdoData) {
    var indexArray = [];
    for (var i in arrayValues) {
      var enumsArray = arrayValues[i].split(":");
      indexArray.push(enumsArray[0]);
    }
    //Find Duplicates
    var countArray = indexArray.filter((element, index, array) => array.indexOf(element) !== index)
    if (countArray.length == 1) {
      this.copyEnumErrorMessage = 'Please check the Enumeration index value ' + countArray[0];
      this.submittedEnumData = false;
      return false;
    } else if (countArray.length > 1) {
      let uniqueChars = [...new Set(countArray)];
      this.copyEnumErrorMessage = 'Please check the Enumeration index values ' + uniqueChars;
      this.submittedEnumData = false;
      return false;
    } else {
      const extravalue = this.srdFrom.get('extraValues').value;
      if (extravalue !== null) {
        this.extraValueSplit = extravalue.length > 0 ?  extravalue.split(',') : [];
        var extraArray = [];
        this.extraValueSplit.forEach(element => {
          extraArray.push(element.trim());
        });
      }
      //Find  Min & Max range
      var maxArray = indexArray.filter(function (number) {
        return Number(number) > srdoData.inputMax;
      });

      var minArray = indexArray.filter(function (number) {
        return Number(number) < srdoData.inputMin;
      });

      if (maxArray.length > 0 || minArray.length > 0) {
        if (this.extraValueSplit.length > 0) {
          var result = maxArray.filter(f => !extraArray.includes(f));
          if (result.length > 0) {
            this.copyEnumErrorMessage = EnumErrorMsg;
            this.submittedEnumData = false;
            return false;
          }
        } else {
          this.copyEnumErrorMessage = EnumErrorMsg;
          this.submittedEnumData = false;
          return false;
        }
      }
    }
    return true;
  }


  onSrdoSelect(Id) {
    this.rearrangeState = false
    this.showNotFound = false
    this.srdFrom.markAsPristine()
    this.bitmask_Enum_Errors = [];
    this.enumErrorMessage = '';
    this.checkboxFlag = false;
    this.showGrid = true;
    this.showForm = false;
    this.spinner.show();
    this.submitted = false;
    this.errorMsg = '';
    this.skip = 0;
    this.pageSize = 5;
    this.CAskip = 0;
    this.CApageSize = 5;
    this.srdFrom.patchValue({ address: '', cachedData: 0, published: 0 });
    if (Id !== null) {
      const SrdoId = Id.toString();
      this.srdo_id = SrdoId;
      this.spinner.show()
      this.srdoDataService.getSrdoDataList(SrdoId).subscribe((srdoDataItem) => {
        if (srdoDataItem !== null) {
          this.checkAccess(SRDODataActions.AddSRDO, this.componentId);
          this.checkAccess(SRDODataActions.UpdateSRDO, this.componentId);
          this.checkAccess(SRDODataActions.DeleteSRDO, this.componentId);
          this.checkAccess(SRDODataActions.import, this.componentId);
          this.enableDisableGroupBox()
          const SRDOData = JSON.stringify(srdoDataItem);
          const dataSrdo = JSON.parse(SRDOData);
          this.srdoDataList = srdoDataItem;
          this.selectedDataType = dataSrdo.dataType.id;
          this.selectedaccess = dataSrdo.access.id;
          this.selectedreadSecurityLevel = dataSrdo.readSecurityLevel.id;
          this.selectedwriteSecurityLevel = dataSrdo.writeSecurityLevel.id;
          this.selectedcachedData = (srdoDataItem.cachedData === false ? 0 : 1);
          this.selectedpublished = (srdoDataItem.published === false ? 0 : 1);
          this.dataTypeName = dataSrdo.dataType.name;
          this.selDataTypeName = dataSrdo.dataType.name;

          this.checkUnitDescriptionAccess(this.selectedDataType);
          this.checkArrayNameAccess(srdoDataItem.quantity);

          if (this.dataTypeName.indexOf('enum') !== -1 || this.dataTypeName.indexOf('Enum') !== -1) {
            this.gridView = dataSrdo.dataTypeDetails;
          }
          if (this.dataTypeName === 'Custom') {
            this.customDataField = 0;
          } else {
            this.customDataField = 1;
          }
          if (this.dataTypeName.indexOf('custom') !== -1 ||
            this.dataTypeName.indexOf('Custom') !== -1) {
            this.customaData = dataSrdo.dataTypeDetails;
            this.loadDataTable();
            this.srdFrom.setControl('customs', this.setCustoms(dataSrdo));
          }
          if (this.dataTypeName.indexOf('bitmask') !== -1 || this.dataTypeName.indexOf('Bitmask') !== -1) {
            this.bitmaskGridView = dataSrdo.dataTypeDetails;
            this.editBitmaskData = dataSrdo.dataTypeDetails;
            this.loadbitmaskDataTable();
            this.bitmasks = this.setBitmasks(dataSrdo);
            this.srdFrom.setControl('bitmasks', this.setBitmasks(dataSrdo));
          }
          // tslint:disable-next-line: no-shadowed-variable
          const srdoDataListValue = {
            name: srdoDataItem.name,
            srdoid: srdoDataItem.srdoid,
            page: srdoDataItem.page,
            dataType: this.selectedDataType,
            quantity: srdoDataItem.quantity,
            arrayColumnName: srdoDataItem.arrayColumnName,
            units: srdoDataItem.units,
            description: srdoDataItem.description,
            defaultValue: srdoDataItem.defaultValue,
            inputMin: srdoDataItem.inputMin,
            inputMax: srdoDataItem.inputMax,
            extraValues: srdoDataItem.extraValues,
            searchKeywords: srdoDataItem.searchKeywords,
            groupVal: srdoDataItem.groupVal,
            access: this.selectedaccess,
            readSecurityLevel: this.selectedreadSecurityLevel,
            writeSecurityLevel: this.selectedwriteSecurityLevel,
            address: srdoDataItem.address,
            spare1: srdoDataItem.spare1,
            dataCategory: srdoDataItem.dataCategory,
            cachedData: this.selectedcachedData,
            published: this.selectedpublished,
            messageId: srdoDataItem.messageId,
            messageStructureItem: srdoDataItem.messageStructureItem
          };
          this.srdFrom.patchValue(srdoDataListValue);
          this.srdFrom.markAllAsTouched();
          if (!this.dataTypeName.toLocaleLowerCase().includes('custom')) {
            this.removeCustomValidators();
          }
          if (this.dataTypeName.toLocaleLowerCase().includes('custom')) {
            this.setCustomValidators();
          }
         
          if (!this.dataTypeName.toLocaleLowerCase().includes('bitmask')) {
            this.removeBitmaskValidators();
            //Due to set bismask validators, It is showing the bitmask invalid error message for non bitmask datatypenames when we click on the submit button twice.
            // this.setBitmaskValidator();
          }
         
          this.spinner.hide();
          this.commonService.takenInput = false;
          if(this.isVersionReopned) {
            this.srdFrom.get('dataType')?.disable()
            this.srdFrom.get('srdoid')?.disable()
            this.srdFrom.get('page')?.disable()
            this.srdFrom.get('quantity')?.disable()
            this.srdFrom.get('arrayColumnName')?.disable()
            this.srdFrom.get('units')?.disable()
            this.srdFrom.get('address')?.disable()
            this.srdFrom.get('spare1')?.disable()
            this.srdFrom.get('dataCategory')?.disable()
            this.srdFrom.get('access')?.disable()
            this.srdFrom.get('readSecurityLevel')?.disable()
            this.srdFrom.get('writeSecurityLevel')?.disable()
          }
      
        } else {
          this.ToastMessage('204 : No Content', 'bg-danger', 'Error');
        }
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
    } else {
      this.addSRDO();
    }
  }

  getPageByComponentIdVersionNumber(componentId, compVersion) {
    let options = '';
    if (this.selectedPage == '') {
      options = '<option selected = true  value="" >All Page</option>';
    } else {
      options = '<option  value="" >All Page</option>';
    }
    this.srdoDataService.getPageByComponentIdVersionNumber(componentId, compVersion).subscribe((data) => {
      if (data !== null) {
        this.pageData = data;
        if (data.length > 0) {
          data.map((pages) => {
            options += '<option';
            if (this.selectedPage != '') {
              if (pages.pageNumber == this.selectedPage) {
                options += ' selected =\'true\'';
              }
            }
            options += ' value=\'' + pages.pageNumber + '\'>' + pages.pageText + '</option>';
          });
        }
      }
      if(document.getElementById('allpages')) {
        document.getElementById('allpages').innerHTML = options;
      }
    }, () => {
      this.spinner.hide();
    });
  }

  fetchSRDOByPage(event) {
    this.srdoPageSelected = event.target.value;
    if(!this.srdFrom.pristine || this.rearrangeState) {
      this.selectionFlag = 0;
      this.showConfirmation = true
    } else {
      this.getSRDOByPage(this.srdoPageSelected)
    }
  }

  getSRDOByPage(srdoPage) {
    this.spinner.show();
    this.oldSrdoPageSelected = srdoPage;
    if (srdoPage !== '') {
      this.selectedPage = srdoPage;
      this.getSrdoByPageCompVer(this.componentVersionId, '', srdoPage);
    } else {
      this.selectedPage = '';
      this.srdoListRes = this.selectedComponentSRDOData.srdos;
      if (this.srdo_id == null) {
        if (this.srdoListRes.length > 0) {
          this.srdo_id = this.srdoListRes[0].id;
        } else {
          this.srdo_id = null;
        }
      }
      this.spinner.hide();
      if(this.srdo_id) {
        this.onSrdoSelect(this.srdo_id);
      }else {
        this.showNotFound = true
      }
    }
  }

  deleteSRDOClick() {
    if(!this.srdFrom.pristine) {
      this.selectionFlag = 4
      this.showConfirmation = true;
    }else {
      this.deleteSrdo()
    }
  }

  deleteSrdo() {
    const srdoId = this.srdo_id;
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          this.srdoDataService.deleteSrdo(srdoId).subscribe((res) => {
            if (res === true) {
              this.srdo_id = null;
              this.loadSrdoListByComponentId(null);
              this.spinner.hide();
              this.ToastMessage('SRDO deleted Successfully.', 'bg-success', 'Success');
            } else {
              this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
              this.spinner.hide();
            }
          }, () => {
            this.spinner.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  addSRDOClick() {
    if(!this.srdFrom.pristine) {
      this.showConfirmation = true;
      this.selectionFlag = 2
    }else {
      this.addSRDO()
    }
  }

  addSRDO() {   
    this.enableDisableGroupBox() 
    this.addStatus = 1;
    this.showNotFound = false;
    this.enableFieldFLag = true;
    this.srdFrom.reset();
    this.srdo_id = null;
    let pageNumber;
    this.gridView = [{}];
    this.dataTypeName = 'Oint';
    this.UnitDescriptorAccess = true;
    this.ArrayNameAccess = false;
    const srdoDataListValue = {
      dataType: this.srdoDataTypes[0].id,
      access: this.srdoAccess[0].id,
      readSecurityLevel: this.srdoSecurityLevels[5].id,
      writeSecurityLevel: this.srdoSecurityLevels[5].id,
      address: '*',
      dataCategory: 'Not applicable',
      cachedData: this.srdoCachedData[0].value,
      published: this.srdoPublished[0].value,
      messageId: '',
      messageStructureItem: '',
      quantity: 1,
      arrayColumnName: '',
      units: '',
      defaultValue: 0
    };

    if (this.selectedPage !== '') {
      pageNumber = this.selectedPage;
    } else {
      pageNumber = 0;
    }
    this.srdFrom.patchValue({ inputMin: 0 });
    this.srdFrom.patchValue({ inputMax: 127 });

    this.getNextSrdoOfCompnent(pageNumber, this.componentVersionId);
    this.srdFrom.patchValue({ page: pageNumber });
    this.srdFrom.patchValue(srdoDataListValue);
    if (!this.dataTypeName.toLocaleLowerCase().includes('custom')) {
      this.removeCustomValidators();
      this.customControls.clear();
      this.customDataField = 1;
    }
    if (this.dataTypeName.toLocaleLowerCase().includes('custom')) {
      this.setCustomValidators();
      this.customDataField = 0;
      this.addCustom();
    }
    if (!this.dataTypeName.toLocaleLowerCase().includes('bitmask')) {
      this.removeBitmaskValidators();
      this.bitmaskControls.clear();
    } else if (this.dataTypeName.toLocaleLowerCase().includes('bitmask')) {
      this.setBitmaskValidator();
      this.addBitmask();
      this.bitmaskGridView = {} as any;
    }
    this.dataTypeChangeEvent = 1;
  }

  loadSrdoListByComponentId(srdoId: any) {
    this.srdoDataService.getSRDOlistbyComponentService(this.componentVersionId).subscribe((listRes) => {
      this.clearSrdoForm();
      this.selectedComponentSRDOData = listRes;
      if (this.selectedComponentSRDOData.srdos.length > 0) {
        this.srdoListRes = this.selectedComponentSRDOData.srdos;
        if (srdoId !== null) {
          this.srdo_id = srdoId;
        } else {
          this.srdo_id = this.selectedComponentSRDOData.srdos[0].id;
        }
        this.getPageByComponentIdVersionNumber(this.componentVersionId, '');
      } else {
        this.spinner.hide();
        this.srdo_id = null;
        this.showNotFound = true
      }
      this.getSRDOByPage(this.selectedPage);
    }, (() => {
      this.spinner.hide();
    }));
  }

  searchClear() {
    this.text = null;
    this.srdoListRes = this.selectedComponentSRDOData.srdos
    if(this.srdo_id == null || this.srdo_id == undefined) {
      if (this.srdoListRes.length > 0) {
        this.srdo_id = this.srdoListRes[0].id;
        this.onSrdoSelect(this.srdoListRes[0].id)
      } else {
        this.srdo_id = null;
      }
    }
  }
  onOptionsSelected(event) {
    this.checkboxFlag = false
    this.errorMsg = '';
    if (Object.keys(this.gridView).length > 0 && Object.keys(this.gridView[0]).length === 4) {
      this.gridView.splice(0, 1);
    } else if (Object.keys(this.gridView).length > 0 && this.gridView[0].index === undefined) {
      this.gridView.splice(0, 1);
    }
    if (this.addStatus === 1) {
      this.gridView = [{}];
      this.gridView.splice(0, 1);
      this.bitmaskGridView = {} as any;
    }
    this.dataTypeName = event.target.options[event.target.options.selectedIndex].text;
    const dataTypeValue: Number = Number(event.target.value);
    
    if (this.dataTypeName.toLocaleLowerCase().includes('enum') && this.selDataTypeName.toLocaleLowerCase().includes('bitmask')) {
      this.gridView = [{}];
      this.gridView.splice(0, 1);
    }
    if (this.dataTypeName.toLocaleLowerCase().includes('bitmask') && this.selDataTypeName.toLocaleLowerCase().includes('enum')) {
      this.bitmaskGridView = {} as any;
    }
    if (this.dataTypeName.toLocaleLowerCase().includes('bitmask') && this.selDataTypeName.toLocaleLowerCase().includes('int')) {
      this.bitmaskGridView = {} as any;
    }
    if (this.dataTypeName.toLocaleLowerCase().includes('bitmask') && this.selDataTypeName.toLocaleLowerCase().includes('custom')) {
      this.bitmaskGridView = {} as any;
    }

    this.checkUnitDescriptionAccess(dataTypeValue);
    this.removeBooleanMinValidators()
    if (this.dataTypeChangeEvent === 1) {
      switch (event.target.options[event.target.options.selectedIndex].value) {
        case '1':
          this.srdFrom.patchValue({ inputMax: 127 });
          break;
        case '2':
          this.srdFrom.patchValue({ inputMax: 32767 });
          break;
        case '3':
          this.srdFrom.patchValue({ inputMax: 2147483647 });
          break;
        case '4':
          this.srdFrom.patchValue({ inputMax: "9223372036854775807" });
          break;
        case '5':
          this.srdFrom.patchValue({ inputMax: 255 });
          break;
        case '6':
          this.srdFrom.patchValue({ inputMax: 65535 });
          break;
        case '7':
          this.srdFrom.patchValue({ inputMax: 4294967295 });
          break;
        case '8':
          this.setInputMax = '18446744073709551615';
          this.srdFrom.patchValue({ inputMax: this.setInputMax.toString() });
          break;
        case '9':
          this.srdFrom.patchValue({ inputMin: 0, inputMax: 255 });
          // tslint:disable-next-line:no-unused-expression
          break;
        case '10'://Boolean
          this.addBooleanMinValidators()
          this.srdFrom.patchValue({ inputMax: 255 });
          break;
        case '11':
          this.srdFrom.patchValue({ inputMax: 255 });
          break;
        case '12':
          this.srdFrom.patchValue({ inputMax: 65535 });
          break;
        case '13':
          this.srdFrom.patchValue({ inputMax: 16777215 });
          break;
        case '14':
          this.srdFrom.patchValue({ inputMax: 4294967295 });
          break;
        case '15':
          this.srdFrom.patchValue({ inputMax: 255 });
          break;
        case '16':
          this.srdFrom.patchValue({ inputMax: 65535 });
          break;
        case '17':
          this.srdFrom.patchValue({ inputMax: 4294967295 });
          break;
        case '18':
          this.srdFrom.patchValue({ inputMax: 0 });
          break;
      }
    }
    // tslint:disable-next-line: ban-types
    const dataTypeVal: Number = Number(event.target.value);
    if (!((this.dataTypeName.toLocaleLowerCase().includes('bitmask') && this.selDataTypeName.toLocaleLowerCase().includes('bitmask')) || 
    (this.dataTypeName.toLocaleLowerCase().includes('enum') && this.selDataTypeName.toLocaleLowerCase().includes('enum')))) {
      this.removeFieldValidator(dataTypeVal);
    }
    if (this.dataTypeName === 'Custom') {
      this.customDataField = 0;
    } else {
      this.customDataField = 1;
    }
  }


  addBooleanMinValidators() {
    this.srdFrom.get('inputMin').setValidators([Validators.min(0), Validators.max(0)])
  }

  removeBooleanMinValidators() {
    this.srdFrom.get('inputMin').clearValidators()
  }


  public checkUnitDescriptionAccess(dataTypeValue) {
    if (dataTypeValue == 1 || dataTypeValue == 2 || dataTypeValue == 3 || dataTypeValue == 4 || dataTypeValue == 5 || dataTypeValue == 6 || dataTypeValue == 7 || dataTypeValue == 8 || dataTypeValue == 9) {
      this.UnitDescriptorAccess = true;
    } else {
      this.UnitDescriptorAccess = false;
    }
  }

  onChangeQuantity(quanity: string): void {
    this.checkArrayNameAccess(quanity);
  }

  public checkArrayNameAccess(quanity) {
    if (quanity > 1) {
      this.ArrayNameAccess = true;
    } else {
      this.ArrayNameAccess = false;
    }
  }

  public removeHandler({ sender, dataItem, rowIndex }) {
    this.gridView.splice(rowIndex, 1);
    this.enumErrorMessage = null;
    sender.cancelCell();
  }
  public cancelHandler({ sender, rowIndex }) {
    this.enumErrorMessage = null;
    if (sender !== null)
      sender.closeRow(rowIndex);
  }
  public addHandler() {
    this.enumErrorMessage = null;
    const addEnum = { index: '', value: '' };
    this.grid.addRow(this.createFormGroup({ index: '', value: '' }));
  }
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }
  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formBuilder = undefined;
  }
  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      index: new UntypedFormControl(dataItem.index, [Validators.required,
      Validators.pattern('^[0-9]*$'),]),
      value: new UntypedFormControl(dataItem.value, [Validators.required]),
      defaultValue: new UntypedFormControl(this.srdFrom.value.defaultValue),
      extraValues: new UntypedFormControl(this.srdFrom.value.extraValues),
      inputMax: new UntypedFormControl(this.srdFrom.value.inputMax),
      inputMin: new UntypedFormControl(this.srdFrom.value.inputMin)
    });
  }

  /**
   * Save or Update enum values - Would like to improve this method
   * @param param0 
   * @returns 
   */
  public saveHandler({ sender, formGroup, rowIndex, isNew, e }) {
    if (formGroup.valid) {
      const extravalue = this.srdFrom.get('extraValues').value;
      if (extravalue && extravalue.length > 0) {
        this.extraValueSplit = extravalue.split(',');
      } else {
        this.extraValueSplit = []
      }
      if (this.extraValueSplit.length > 0) {
              let inputMax = this.srdFrom.get('inputMax').getRawValue();
              let inputMin = this.srdFrom.get('inputMin').getRawValue();
              const enumArray: number[] = [];
              this.gridView.forEach((element: any, index: number) => {
                enumArray.push(Number(this.gridView[index].index));
              });
              if(formGroup.value && formGroup.value.index) {
              if(isNew) {
                  enumArray.push(Number(formGroup.value.index))
              } else {
                  enumArray[rowIndex] = (Number(formGroup.value.index))
                }
              }
              if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
                this.enumErrorMessage = 'Please provide valid enumeration data'
                this.errorMsg = '';
                return;
              }
              if (enumArray && enumArray.length > 0) {
                for (const enm of enumArray) {
                  if(this.extraValueSplit.length > 0 && this.extraValueSplit.find(item => +item == enm)){
                  }
                  else if((Number(inputMax) < enm)  || (Number( inputMin ) > enm)) {
                    this.enumErrorMessage = 'Enumeration value should be between min and max range'
                    this.errorMsg = '';
                    return;
                  }
                }
            } else {
              if (Number(formGroup.value.index) > Number(this.srdFrom.get('inputMax').getRawValue())
                || Number(formGroup.value.index) < Number(this.srdFrom.get('inputMin').getRawValue())) {
                this.enumErrorMessage = EnumErrorMsg;
                return false;
              }
            }
          }
      // Adding new row
      if (isNew) {
        this.enumErrorMessage = null;
        this.gridView.push(formGroup.value);
        this.rearrangeState = true
        if (sender !== null)
          sender.closeRow(rowIndex);
      } 
      //Editing or updating existing row
      else {
        this.enumErrorMessage = null;
        this.gridView.splice(rowIndex, 1, formGroup.value);
        this.rearrangeState = true
        if (sender !== null)
          sender.closeRow(rowIndex);
      }
    }
  }
    

  getNextSrdoOfCompnent(page, componentId) {
    let nextSrdoId = null;
    this.spinner.show();
    this.srdoDataService.getNextSrdoOfCompnent(page, componentId).subscribe((data) => {
      if (data !== null || data !== '') {
        nextSrdoId = data;
      }
      this.srdFrom.patchValue({ srdoid: nextSrdoId });
      this.spinner.hide();
    }, (() => {
      this.spinner.hide();
    }));
  }
  getSrdoByPageCompVer(componentId, versionNumber, pageNumber) {
    this.spinner.show();
    this.srdoDataService.getSrdoByComponentIdVersionNumber(componentId, versionNumber, pageNumber).subscribe((listRes) => {
      if (listRes.length > 0) {
        const srdoListResArr = listRes.map(s => {
          if (s.hasOwnProperty('srdoName')) {
            s.name = s.srdoName;
            delete s.srdoName;
          }
          return s;
        });
        this.srdoListRes = srdoListResArr;
        if (this.srdo_id === null) {
          this.srdo_id = srdoListResArr[0].id;
        }
        this.spinner.hide();
        if(this.srdo_id != null) {
          this.onSrdoSelect(this.srdo_id);
        }
      }
    }, (() => {
      this.spinner.hide();
    }));
  }
  disableSelectBox() {
    if (this.srdFrom.get('cachedData').enabled) {
      this.srdFrom.controls.cachedData.disable();
    }
    if (this.srdFrom.get('published').enabled) {
      this.srdFrom.controls.published.disable();
    }
  }

  enableDisableGroupBox() {
    if(this.AddSRDOAccess) {
        if(this.commonService.isDeployedFlag && !this.isVersionReopned) {
          this.groupDisable = true
          this.srdFrom.controls.groupVal.disable()
        }
        else {
          this.groupDisable = false
          this.srdFrom.controls.groupVal.enable()
        }
    } else {
      this.groupDisable = true
      this.srdFrom.controls.groupVal.disable()
    }
  }

  bitmask() {
    return this.fb.group({
      bits: [null],
      data_type: [null],
      value: [null],
      bitmasks_enums: this.fb.array([this.bitmaskEnums])
    });
  }
  deleteBitmasks(argument, index) {
    this.bitmasks = this.srdFrom.get('bitmasks') as UntypedFormArray;
    this.bitmasks.removeAt(index);
  }
  addBitmask() {
    this.bitmasks = this.srdFrom.get('bitmasks') as UntypedFormArray;
    this.bitmasks.push(this.bitmask());
    this.setBitmaskValidator();
  }
  get bitmaskEnums() {
    return this.fb.group({
      bit_index: '',
      bit_value: ''
    });
  }
  getBitmaskEnumDisplay(bitmaskDataType) {
    if (Number(bitmaskDataType) === 1) {
      return 'block';
    } else {
      return 'none';
    }
  }
  getBitmaskDataType() {
    this.commandResponseService.getBitmaskDataType().subscribe((data) => {
      if (data !== null) {
        this.bitmaskDataTypeArr = data;
      }
      this.spinner.hide();
    },
      (() => {
        this.spinner.hide();
      }));
  }
  addBitmaskEnum(bitmasks, index) {
    const dataType = Number(this.srdFrom.get('dataType').getRawValue());
    const messageFlag = this.customFormValidationsService.setBitmaskEnumValidation(bitmasks, dataType);
    const messageFlagBits = this.customFormValidationsService.setBitmaskEnumValidationForVal(bitmasks, dataType);
    if (messageFlagBits === false || messageFlagBits === null) {
      this.bitmask_Enum_Errors[index] = 'Enter Value for bit field' + messageFlagBits;
    }
    if (messageFlag === null) {
      this.bitmask_Enum_Errors[index] = 'Enter Value for bit field';
    } else {
      if (messageFlag === false) {
        bitmasks.get('bitmasks_enums').push(this.bitmaskEnums);
        this.bitmask_Enum_Errors = [];
      } else {
        this.bitmask_Enum_Errors[index] = 'Enumeration rows should not be greater than ' + messageFlag;
      }
    }
  }

  deleteBitmaskEnums(bitmasks, index) {
    bitmasks.get('bitmasks_enums').removeAt(index);
  }

  hideEdit(control, EnumIndex, bitmaskIndex, bitmasks) {
    if (control === 'enum') {
      document.getElementById('index_' + EnumIndex).style.display = 'block';
      document.getElementById('value_' + EnumIndex).style.display = 'block';
      document.getElementById('add_enumbtn_' + EnumIndex).style.display = 'inline-block';
      document.getElementById('index_span_' + EnumIndex).style.display = 'none';
      document.getElementById('value_span_' + EnumIndex).style.display = 'none';
      document.getElementById('edit_enumbtn_' + EnumIndex).style.display = 'none';
    } else {
      document.getElementById('bit_index_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('bit_value_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('add_bitmask_enumbtn_' + bitmaskIndex + '_' + EnumIndex).style.display = 'inline-block';
      document.getElementById('bit_index_span_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('bit_value_span_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('edit_bitmask_enumbtn_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
    }
  }
  showResArgBitEnumDetails(dataItem: any, index: number): boolean {
    if (dataItem.dataTypeId === 1) {
      return true;
    } else {
      return false;
    }
  }
  setBitmasks(bitmasksArr) {
    if (bitmasksArr.dataTypeDetails.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      bitmasksArr.dataTypeDetails.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bits: element.index,
          data_type: [{ value: element.dataTypeId, disabled: true }],
          value: element.text,
          text: element.text,
          bitmasks_enums: this.setBitmasksEnums(element)
        }));
      });
      return formBitMaskArray;
    }
  }
  setBitmasksEnums(argumentsArr) {
    if (argumentsArr.dataTypeId === 1 && argumentsArr.value != '' && argumentsArr.value.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      argumentsArr.value.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bit_index: element.index,
          bit_value: element.value,
        }));
      });
      return formBitMaskArray;
    }
  }
  custom() {
    return this.fb.group({
      name: [null],
      data_types: [null],
      desc: [null],
      qty: [null],
      array_name: [null],
      minval: [null],
      maxval: [null],
      extraval: [null],
      customs_enums: this.fb.array([this.customEnums]),
      customs_bitmasks: this.fb.array([this.customBitmask])
    });
  }
  addCustom() {
    this.customs = this.srdFrom.get('customs') as UntypedFormArray;
    this.customs.push(this.custom());
    this.setCustomValidators();
  }
  deleteCustom(index) {
    this.customs = this.srdFrom.get('customs') as UntypedFormArray;
    this.customs.removeAt(index);
  }
  get customEnums() {
    return this.fb.group({
      custom_enum_index: '',
      custom_enum_value: ''
    });
  }
  getCustomEnumDisplay(customDataType, type) {
    if (type === 'Custom_Enum') {
      const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
        return enumDataTyp === Number(customDataType);
      });
      if (enumDataTypeFound) {
        return 'inline-block';
      } else {
        return 'none';

      }
    }
    if (type === 'Custom_Bitmask') {
      const bitmaskFound = this.bitmaskDataType.find((enumDataTyp) => {
        return enumDataTyp === Number(customDataType);
      });
      if (bitmaskFound) {
        return 'block';
      } else {
        return 'none';
      }
    }
  }

  addCustomEnum(customs, type) {
    const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
      return enumDataTyp === Number(type);
    });
    if (enumDataTypeFound) {
      customs.get('customs_enums').push(this.customEnums);
    }
    const bitDataTypeFound = this.bitmaskDataType.find((DataTyp) => {
      return DataTyp === Number(type);
    });
    if (bitDataTypeFound) {
      customs.get('customs_bitmasks').push(this.customBitmask);
    }
  }
  deleteCustomEnums(customs, index) {
    customs.get('customs_enums').removeAt(index);
  }

  showSrdoBitEnum(dataItem: any, index: number): boolean {
    if (Number(dataItem.dataTypeId) === 1) {
      return true;
    } else {
      return false;
    }
  }
  showSrdoBitEnumDetails(dataItem: any, index: number): boolean {
    const enumDataType = [15, 16, 17];

    const enumDataTypeFound = enumDataType.find((enumDataTyp) => {
      return enumDataTyp === Number(dataItem.dataTypeId);
    });
    const bitmaskDataType = [11, 12, 13, 14];
    const bitDataTypeFound = bitmaskDataType.find((DataTyp) => {
      return DataTyp === Number(dataItem.dataTypeId);
    });
    if (enumDataTypeFound || bitDataTypeFound) {
      return true;
    } else {
      return false;
    }
  }
  showEnumGrid(dataType) {
    const enumDataType = [15, 16, 17];
    const enumDataTypeFound = enumDataType.find((enumDataTyp) => {
      return enumDataTyp === Number(dataType);
    });
    if (enumDataTypeFound) {
      return true;
    } else {
      return false;
    }
  }

  showBitmaskGrid(dataType) {
    const bitmaskDataType = [11, 12, 13, 14];
    const bitDataTypeFound = bitmaskDataType.find((DataTyp) => {
      return DataTyp === Number(dataType);
    });
    if (bitDataTypeFound) {
      return true;
    } else {
      return false;
    }
  }
  public pageChange({ skip, take, sort }: DataStateChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.sort = sort;
    this.loadDataTable();
  }
  private loadDataTable(): void {
    this.customGridView = {
      data: this.customaData.slice(this.skip, this.skip + this.pageSize),
      total: this.customaData.length
    };
  }

  public pageBitmaskChange({ skip, take, sort }: DataStateChangeEvent): void {
    this.CAskip = skip;
    this.CApageSize = take;
    this.loadbitmaskDataTable();
  }

  private loadbitmaskDataTable(): void {
    this.bitmaskGridView = {
        data: this.editBitmaskData.slice(this.CAskip, this.CAskip + this.CApageSize),
        total: this.editBitmaskData.length
    };
  }

  get customBitmask(): UntypedFormGroup {
    return this.fb.group({
      bits: [null],
      data_type: [null],
      value: [null],
      custom_bitmasks_enums: this.fb.array([this.customBitmaskEnums])
    });
  }
  get customBitmaskEnums() {
    return this.fb.group({
      custom_bit_index: [null],
      custom_bit_value: [null]
    });
  }
  getCustomBitmaskEnumDisplay(bitmaskDataType) {
    if (Number(bitmaskDataType) === 1) {
      return 'inline-block';
    } else {
      return 'none';

    }
  }
  addCustomBitmask(custom) {
    if (custom) {
      custom.get('customs_bitmasks').push(this.customBitmask);
    }
  }

  deleteCustomBitmasks(custom, index) {
    if (custom) {
      custom.get('customs_bitmasks').removeAt(index);
      if (custom[index]) {
        custom[index].get('customs_bitmasks').clearValidators();
      }
    }
  }

  addCustomBitmaskEnum(customBitmask) {
    customBitmask.get('custom_bitmasks_enums').push(this.customBitmaskEnums);
  }

  deleteCustomBitmaskEnums(customBitmask, index) {
    customBitmask.get('custom_bitmasks_enums').removeAt(index);
  }

  ShowCustom() {
    if (this.dataTypeName.includes('Custom') && this.srdo_id === null) {
      return 'inline-block';
    } else {
      return 'none';

    }
  }
  ShowHideCustom(event) {
    if (event.target.checked === false) {
      this.showGrid = true;
      this.showForm = false;
    } else {
      this.showGrid = false;
      this.showForm = true;
    }
  }
  setCustoms(customsArr) {
    if (customsArr.dataTypeDetails !== null && customsArr.dataTypeDetails.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      customsArr.dataTypeDetails.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          name: element.name,
          data_types: [{ value: element.dataTypeId, disabled: true }],
          desc: element.description,
          qty: element.quantity,
          minval: element.inputMin,
          maxval: element.inputMax,
          extraval: element.extraValues,
          customs_enums: this.setCustomEnums(element),
          customs_bitmasks: this.setCustomBitmasks(element)
        }));
      });
      return formBitMaskArray;
    }
  }
  setCustomEnums(customsArr) {
    if ((customsArr.customDtaDeatils !== undefined && customsArr.customDtaDeatils !== null)) {
      const formBitMaskArray = new UntypedFormArray([]);
      if (customsArr.customDtaDeatils.length > 0) {
        customsArr.customDtaDeatils.forEach((element, i) => {
          formBitMaskArray.push(this.fb.group({
            custom_enum_index: element.index,
            custom_enum_value: element.value
          }));
        });
        return formBitMaskArray;
      }
    }
  }
  setCustomBitmasks(bitmasksArr) {
    if ((bitmasksArr.customDtaDeatils !== undefined && bitmasksArr.customDtaDeatils !== null)) {
      if (bitmasksArr.customDtaDeatils.length > 0) {
        const formBitMaskArray = new UntypedFormArray([]);
        bitmasksArr.customDtaDeatils.forEach((element, i) => {
          formBitMaskArray.push(this.fb.group({
            bits: element.index,
            data_type:  [{ value: element.dataTypeId, disabled: true }],
            value: element.text,
            custom_bitmasks_enums: this.setCustomsBitmasksEnums(element)
          }));
        });
        return formBitMaskArray;
      }
    }
  }
  setCustomsBitmasksEnums(enumArr) {
    if (Number(enumArr.dataTypeId) === 1 && enumArr.value.length > 0) {
      const formBitMaskEnumArray = new UntypedFormArray([]);
      enumArr.value.forEach((element, i) => {
        formBitMaskEnumArray.push(this.fb.group({
          custom_bit_index: element.index,
          custom_bit_value: element.value,
        }));
      });
      return formBitMaskEnumArray;
    }
  }
  onSwitch(event) {
    this.checkboxFlag = event.target.checked;
  }
  get customControls() {
    return this.srdFrom.get('customs') as UntypedFormArray;
  }
  get bitmaskControls() {
    return this.srdFrom.get('bitmasks') as UntypedFormArray;
  }

  showCustomAddBtn(dataType) {
    const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
      return enumDataTyp === Number(dataType);
    });
    const bitDataTypeFound = this.bitmaskDataType.find((DataTyp) => {
      return DataTyp === Number(dataType);
    });
    if (enumDataTypeFound || bitDataTypeFound) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  showCustomEnumImportBtn(dataType) {
    const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
      return enumDataTyp === Number(dataType);
    });
    if (enumDataTypeFound) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  removeFieldValidator(dataType) {
    // tslint:disable-next-line: ban-types
    const dataTypeVal = dataType;
    // Start : Custom add and remove validators
    if (!this.dataTypeName.toLocaleLowerCase().includes('custom')) {
      this.removeCustomValidators();
    }
    // if (dataTypeVal !== 18 ) {
    //   this.removeCustomValidators();
    // }

    if (this.dataTypeName.toLocaleLowerCase().includes('custom')) {
      this.customControls.clear();
      this.addCustom();
      this.setCustomValidators();
    }
    // if (dataTypeVal === 18) {
    //   this.customControls.clear();
    //   this.addCustom();
    //   this.setCustomValidators();
    // }
    // End : Custom add and remove validators

    // Start :Bitmaks add and remove validators
    if (!this.dataTypeName.toLocaleLowerCase().includes('bitmask')) {
      this.removeBitmaskValidators();
    }
    // if (dataTypeVal !== 11 || dataTypeVal !== 12 || dataTypeVal !== 13 || dataTypeVal !== 14) {
    //   this.removeBitmaskValidators();
    // }
    if (this.dataTypeName.toLocaleLowerCase().includes('bitmask')) {
      this.bitmaskControls.clear();
      this.addBitmask();
      this.setBitmaskValidator();
    }
    // if (dataTypeVal === 11 || dataTypeVal === 12 || dataTypeVal === 13 || dataTypeVal === 14) {
    //   this.bitmaskControls.clear();
    //   this.addBitmask();
    //   this.setBitmaskValidator();
    // }
    // End :Bitmaks add and remove validators

    this.srdFrom.get('inputMin').setValidators([Validators.required,
    Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]);
    this.srdFrom.get('inputMin').updateValueAndValidity();
    this.srdFrom.get('inputMax').setValidators([Validators.required,
    Validators.pattern('^-?[0-9]\\d*(\\.\\d{0,4})?$')]);
    this.srdFrom.get('inputMax').updateValueAndValidity();
  }
  removeCustomValidators() {
    if (this.customControls.controls !== undefined) {
      const customControl = this.customControls.controls;
      if (customControl.length > 0) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < customControl.length; i++) {
          customControl[i].get('name').clearValidators();
          customControl[i].get('data_types').clearValidators();
          customControl[i].get('qty').clearValidators();
          customControl[i].get('minval').clearValidators();
          customControl[i].get('maxval').clearValidators();
          customControl[i].get('extraval').clearValidators();
          customControl[i].get('desc').clearValidators();
          customControl[i].get('name').updateValueAndValidity();
          customControl[i].get('data_types').updateValueAndValidity();
          customControl[i].get('qty').updateValueAndValidity();
          customControl[i].get('minval').updateValueAndValidity();
          customControl[i].get('maxval').updateValueAndValidity();
          customControl[i].get('extraval').updateValueAndValidity();
          customControl[i].get('desc').updateValueAndValidity();
          if (customControl[i].get('data_types').value !== 11 || customControl[i].get('data_types').value !== 12 ||
            customControl[i].get('data_types').value !== 13 || customControl[i].get('data_types').value !== 14) {
            const custBitmask = customControl[i].get('customs_bitmasks') as UntypedFormArray;
            this.removeCustomBitmaskValidator(custBitmask);
          }
          if (customControl[i].get('data_types').value !== 15 || customControl[i].get('data_types').value !== 16 ||
            customControl[i].get('data_types').value !== 17) {
            const custEnum = customControl[i].get('customs_enums') as UntypedFormArray;
            this.removeCustomEnumValidator(custEnum);
          }
        }
      }
    }

  }
  removeBitmaskValidators() {
    const bitmaskControl = this.bitmaskControls.controls;
    if (bitmaskControl.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < bitmaskControl.length; i++) {
        bitmaskControl[i].get('bits').clearValidators();
        bitmaskControl[i].get('data_type').clearValidators();
        bitmaskControl[i].get('value').clearValidators();
        bitmaskControl[i].get('bits').updateValueAndValidity();
        bitmaskControl[i].get('data_type').updateValueAndValidity();
        bitmaskControl[i].get('value').updateValueAndValidity();
      }
    }
  }
  addCustomNestedValidators(customIndex, customdataTyp) {
    const customdataType = Number(customdataTyp);
    const customControl = this.customControls.controls;
    const custEnum = customControl[customIndex].get('customs_enums') as UntypedFormArray;
    const custEnumControl = custEnum.controls;
    if (customdataType === 15 || customdataType === 16 || customdataType === 17) {
      if (custEnumControl.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let k = 0; k < custEnumControl.length; k++) {
          custEnumControl[k].get('custom_enum_index').setValidators([Validators.required]);
          custEnumControl[k].get('custom_enum_value').setValidators([Validators.required]);
          custEnumControl[k].get('custom_enum_index').updateValueAndValidity();
          custEnumControl[k].get('custom_enum_value').updateValueAndValidity();
        }
      }
    } else {
      this.removeCustomEnumValidator(custEnum);
    }
    const custBitmask = customControl[customIndex].get('customs_bitmasks') as UntypedFormArray;
    const custBitmaskControl = custBitmask.controls;
    if (customdataType === 11 || customdataType === 12 || customdataType === 13 || customdataType === 14) {

      // tslint:disable-next-line: prefer-for-of
      if (custBitmaskControl.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < custBitmaskControl.length; j++) {
          custBitmaskControl[j].get('bits').setValidators([Validators.required]);
          custBitmaskControl[j].get('data_type').setValidators([Validators.required]);
          custBitmaskControl[j].get('value').setValidators([Validators.required]);
          custBitmaskControl[j].get('bits').updateValueAndValidity();
          custBitmaskControl[j].get('data_type').updateValueAndValidity();
          custBitmaskControl[j].get('value').updateValueAndValidity();
        }
      }
    } else {
      this.removeCustomBitmaskValidator(custBitmask);
    }
  }
  removeCustomEnumValidator(custEnum) {
    const custEnumControl = custEnum.controls;
    if (custEnumControl !== undefined) {
      if (custEnumControl.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let k = 0; k < custEnumControl.length; k++) {
          custEnumControl[k].get('custom_enum_index').clearValidators();
          custEnumControl[k].get('custom_enum_value').clearValidators();
          custEnumControl[k].get('custom_enum_index').updateValueAndValidity();
          custEnumControl[k].get('custom_enum_value').updateValueAndValidity();
        }
      }
    }
  }
  removeCustomBitmaskValidator(custBitmask) {
    const custBitmaskControl = custBitmask.controls;
    if (custBitmaskControl !== undefined) {
      // tslint:disable-next-line: prefer-for-of
      if (custBitmaskControl.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < custBitmaskControl.length; j++) {
          custBitmaskControl[j].get('bits').clearValidators([Validators.required]);
          custBitmaskControl[j].get('data_type').clearValidators([Validators.required]);
          custBitmaskControl[j].get('value').clearValidators();
          custBitmaskControl[j].get('bits').updateValueAndValidity();
          custBitmaskControl[j].get('data_type').updateValueAndValidity();
          custBitmaskControl[j].get('value').updateValueAndValidity();
        }
      }
    }
  }
  resetBitmaskEnumError(e, bitmaksIndex) {
    if (e.target.value !== '') {
      this.bitmask_Enum_Errors = [];
    }
  }
  setBitmaskValidator() {
    const bitmaskControl = this.bitmaskControls.controls;
    if (bitmaskControl !== undefined && bitmaskControl.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < bitmaskControl.length; i++) {
        bitmaskControl[i].get('bits').setValidators([Validators.required]);
        bitmaskControl[i].get('data_type').setValidators([Validators.required]);
        bitmaskControl[i].get('value').setValidators([Validators.required]);
        bitmaskControl[i].get('bits').updateValueAndValidity();
        bitmaskControl[i].get('data_type').updateValueAndValidity();
        bitmaskControl[i].get('value').updateValueAndValidity();
      }
    }
  }
  setCustomValidators() {
    const customControl = this.customControls.controls;
    if (customControl !== undefined && customControl.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < customControl.length; i++) {
        customControl[i].get('name').setValidators([Validators.required]);
        customControl[i].get('data_types').setValidators([Validators.required]);
        customControl[i].get('qty').setValidators([Validators.required]);
        customControl[i].get('minval').setValidators([Validators.required]);
        customControl[i].get('maxval').setValidators([Validators.required]);
        customControl[i].get('desc').setValidators([Validators.required]);
        customControl[i].get('name').updateValueAndValidity();
        customControl[i].get('data_types').updateValueAndValidity();
        customControl[i].get('qty').updateValueAndValidity();
        customControl[i].get('minval').updateValueAndValidity();
        customControl[i].get('maxval').updateValueAndValidity();
        customControl[i].get('desc').updateValueAndValidity();
      }
    }
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case SRDODataActions.AddSRDO:
        this.AddSRDOAccess = this.useraccess.getUserAccess('components', action, dataItem);
        break;
      case SRDODataActions.UpdateSRDO:
        this.editSRDOAccess = this.useraccess.getUserAccess('components', action, dataItem);
        break;
      case SRDODataActions.DeleteSRDO:
        this.deleteSRDOAccess = this.useraccess.getUserAccess('components', action, dataItem);
        break;
      case SRDODataActions.import:
        this.ImportSRDOAccess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }

  copySRDOClick() {
    if(!this.srdFrom.pristine) {
      this.showConfirmation = true;
      this.selectionFlag = 3
    }else {
      this.copySrdo()
    }
  }

  copySrdo() {
    let pageNumber;
    this.srdo_id = null;
    pageNumber = this.srdFrom.value.page;
    this.getNextSrdoOfCompnent(pageNumber, this.componentVersionId);
    this.ToastMessage('Srdo Copied Successfully. Click Submit button to Save the details in Database.', 'bg-success', 'Success');
    const srdoData = this.srdFrom.value;
    const name = srdoData.name;
    this.srdFrom.patchValue({ name: name + "-copy" });
  }

  public checkAccessForItem(action: string, dataItem: any = undefined): Boolean {
    var state = this.useraccess.getUserAccess('components',
      action, dataItem !== undefined ? dataItem.id : -1);
    return state;
  }

  public qtyValidation(value, customIndex) {
    if (this.inRange(value, 0, 65535) === false)
      this.customError[customIndex] = 'Quanity value should be between 0 to 65535';
    else
      this.customError[customIndex] = null;
  }

  public minMaxValidations(dataType, value, i, v='no') {
    switch (Number(dataType)) {
      case 1:
        if (this.inRange(value, -128, 127) === false)
          this.customError[i] = 'Min and Max value should be between -128 to 127';
        else
          this.customError[i] = null;
        break;
      case 2:
        if (this.inRange(value, -32768, 32767) === false)
          this.customError[i] = 'Min and Max value should be between -32768 to 32767';
        else
          this.customError[i] = null;
        break;
      case 3:
        if (this.inRange(value, -2147483648, 2147483647) === false)
          this.customError[i] = 'Min and Max value should be between -2147483648 to 2147483647';
        else
          this.customError[i] = null;
        break;
      case 4:
        // tslint:disable-next-line:max-line-length
        if (this.inRange(value, -9223372036854775808, 9223372036854775807) === false)
          this.customError[i] = 'Min and Max value should be between -9223372036854775808 to 9223372036854775807';
        else
          this.customError[i] = null;
        break;
      case 5:
        if (this.inRange(value, 0, 255) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 255';
        else
          this.customError[i] = null;
        break;
      case 6:
        if (this.inRange(value, 0, 65535) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 65535';
        else
          this.customError[i] = null;
        break;
      case 7:
        if (this.inRange(value, 0, 4294967295) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 4294967295';
        else
          this.customError[i] = null;
        break;
      case 8:
        if (this.inRange(value, 0, 18446744073709551615) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 18446744073709551615';
        else
          this.customError[i] = null;
        break;
      case 10:
        if(v == 'yes' && value != 0) {
          this.customError[i] = 'Min value should be 0 and Max value should be between 0 to 255'
        }
        else if (this.inRange(value, 0, 255) === false)
          this.customError[i] = 'Min value should be 0 and Max value should be between 0 to 255';
        else
          this.customError[i] = null;
        break;
      case 11:
        if (this.inRange(value, 0, 255) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 255';
        else
          this.customError[i] = null;
        break;
      case 12:
        if (this.inRange(value, 0, 65535) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 65535';
        else
          this.customError[i] = null;
        break;
      case 13:
        if (this.inRange(value, 0, 16777215) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 16777215';
        else
          this.customError[i] = null;
        break;
      case 14:
        if (this.inRange(value, 0, 4294967295) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 4294967295';
        else
          this.customError[i] = null;
        break;
      case 15:
        if (this.inRange(value, 0, 255) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 255';
        else
          this.customError[i] = null;
        break;
      case 16:
        if (this.inRange(value, 0, 65535) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 65535';
        else
          this.customError[i] = null;
        break;
      case 17:
        if (this.inRange(value, 0, 4294967295) === false)
          this.customError[i] = 'Min and Max value should be between 0 to 4294967295';
        else
          this.customError[i] = null;
        break;
    }
  }
  public inRange(x: number, min: number, max: number) {
    return ((x - min) * (x - max) <= 0);
  }

  public closeCopySRDO(state: boolean) {
    if (this.copyButtonState === false && state === true) {
      this.spinner.show()
      this.srdoDataService.copySRDOFromOtherComp(this.copySRDODetails[3].selectedValue.id.toString(), this.componentVersionId, this.selectedPage).subscribe(res => {
        if (res) {
          this.copySRDOFromOtherComp = false;
          this.ToastMessage('SRDO copied successfully.', 'bg-success', 'Success');
          this.loadSrdoListByComponentId(res);
        } else {
          this.copySRDOError = 'SRDO copy failed.';
        }
      }, error => {
        this.copySRDOError = error;
        this.spinner.hide()
      })
    } else {
      this.copySRDOFromOtherComp = false;
    }
    this.copyButtonState = true;
  }

  getComponentDetail(component) {
    const compFound = this.componentService.componentData.find((data) => {
      return data.id === Number(component);
    });
    return compFound;
  }

  openCopySrdoModalClick() {
    if(!this.srdFrom.pristine) {
      this.showConfirmation = true;
      this.selectionFlag = 6
    }else {
      this.openCopySrdoModal()
    }
  }

  public openCopySrdoModal() {
    //Model preparation
    // this.selectedSRDODetails.componentName = "Global"
    this.copySRDOError = ''
    this.copySRDODetails = []
    let compDetails = this.getComponentDetail(this.componentId);
    let versionName = this.componentService.versionSelected
    let item = this.pageData.find((item) => item.pageNumber == this.selectedPage)
    this.componentDetails = { "Name": compDetails.name, "Version": versionName, "Page No": item ? (item.pageText ? item.pageText : item.pageNumber.toString()) : '' }
    this.copySRDODetails.push({
      title: "Component", values: this.componentService.componentData,
      disabled: false, selectedValue: { id: '', name: 'Please select source component' }
    });
    this.copySRDODetails.push({ title: "Version", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    this.copySRDODetails.push({ title: "Page No", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    this.copySRDODetails.push({ title: "SRDO", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    this.copySRDOFromOtherComp = true
  }

  public selectionChange(item: DisplayModel) {
    //Changed Item - Component/Version/SRDO/Page No selection change
    this.copySRDOError = ''
    let selectedItem: DisplayModel;
    let selctedType = ''
    this.copySRDODetails.forEach(element => {
      if (element.values.length > 0) {
        let found = element.values.find(v => v.name === item.name && v.id === item.id);
        if (found) {
          selectedItem = found
          selctedType = element.title
        }
      }
    });

    switch (selctedType) {
      case 'Component':
        this.componentService.getversionlistbycomponent(selectedItem.id.toString()).subscribe((res) => {
          this.copySRDODetails[1].disabled = false;
          this.copySRDODetails[2].disabled = true;
          this.copySRDODetails[3].disabled = true;
          this.copySRDODetails.map((res, index) => {
            res.disabled = false;
            if (index > 1) {
              res.disabled = true;
            }
            if (index > 0) {
              res.selectedValue = { id: '', name: '' }
            }
          })
          Object(res).forEach(obj => {
            renameKey(obj, 'versionNumberId', 'id')
            renameKey(obj, 'versionNumber', 'name')
          });
          this.copySRDODetails[1].values = JSON.parse(JSON.stringify(res));;
          this.changeCopyButtonState()
        })
        break;
      case 'Version':
        this.spinner.show();
        this.srdoDataService.getPageByComponentIdVersionNumber(this.copySRDODetails[1].selectedValue.id.toString(), '').subscribe((data) => {
          Object(data).forEach(obj => {
            renameKey(obj, 'pageNumber', 'id')
            renameKey(obj, 'pageText', 'name')
          });

          this.copySRDODetails[2].values = JSON.parse(JSON.stringify(data));
          this.copySRDODetails[2].disabled = false;
          this.copySRDODetails[2].selectedValue = { id: '', name: '' }
          this.copySRDODetails[3].selectedValue = { id: '', name: '' }
          this.changeCopyButtonState()
          this.spinner.hide();
        });
        break;
      case 'SRDO':
        this.changeCopyButtonState()
        break;
      case 'Page No':
        this.spinner.show();
        this.srdoDataService.getSrdoByComponentIdVersionNumber(this.copySRDODetails[1].selectedValue.id.toString(), "", this.copySRDODetails[2].selectedValue.id.toString()).subscribe((data) => {
          Object(data).forEach(obj => {
            renameKey(obj, 'srdoName', 'name')
          });
          this.copySRDODetails[3].selectedValue = { id: '', name: '' }
          this.copySRDODetails[3].values = JSON.parse(JSON.stringify(data));
          this.copySRDODetails[3].disabled = false;
          this.changeCopyButtonState()
          this.spinner.hide();
        })
        break;
      default:
        break;
    }
  }

  public changeCopyButtonState() {
    let item = this.copySRDODetails.find((item) => item.selectedValue.id.length === 0)
    this.copyButtonState = false;
    if (item) {
      this.copyButtonState = true;
    }
  }

  showModal() {
    this.copysrdoDataList = JSON.parse(JSON.stringify(this.srdoDataList))
    this.rearrangeCustomTypes = true
  }

  public closeRearange(state: boolean) {
    this.rearrangeCustomTypes = false
  }

  public saveRearrangeData(data: SrdoModel) {
    this.customaData = data.dataTypeDetails;
    this.srdoDataList = JSON.parse(JSON.stringify(this.copysrdoDataList))
    this.rearrangeState = true;
    this.customGridView = {
      data: this.customaData.slice(this.skip, this.skip + this.pageSize),
      total: this.customaData.length
    };
    this.rearrangeCustomTypes = false;
    const SRDOData = JSON.stringify(data);
    const dataSrdo = JSON.parse(SRDOData);
    this.srdFrom.setControl('customs', this.setCustoms(dataSrdo));
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.commonService.takenInput === true) {
      this.commonService.takenInput = false;
      return true;
    }
    if (!this.srdFrom.pristine || this.rearrangeState ) {
      const result = window.confirm('Are you sure you want to leave the page?');
      if (result === true) {
        this.srdFrom.markAsPristine()
        this.rearrangeState = false
        this.commonService.srdoChanges = false;
      } else {
        this.commonService.reverseRequest()
        return false;
      }
      return true;
    }
    return true;
  }

  onChanges(): void {
    this.srdFrom.valueChanges.subscribe({
      next: this.updateSRDOChanges.bind(this)
    })
  }

  updateSRDOChanges() {
    this.commonService.srdoChanges = !this.srdFrom.pristine
  }

  confirmationEvent(event: boolean) {
    this.showConfirmation = false;
    if(event) {
      this.commonService.takenInput = true;
      this.srdFrom.markAsPristine()
      this.commonService.takenInput = true;
      switch(this.selectionFlag) {
        case 0:
          this.getSRDOByPage(this.srdoPageSelected)
          break;
        case 1:
          this.onSrdoSelect(this.srdoListItemSelected)
          break;
        case 2:
          this.addSRDO()
          break;
        case 3:
          this.copySrdo()
          break;
        case 4:
          this.deleteSrdo()
          break;
        case 5:
          this.onSrdoSelect(this.srdoListItemSelected)
          this.onSelectedFile(this.fileevent)
          break;
        case 6:
          this.onSrdoSelect(this.srdoListItemSelected)
          this.openCopySrdoModal()
          break;
        default:
          break;
      }
      this.selectionFlag = -1
    } else {
      this.srdoPageSelected = this.oldSrdoPageSelected;
      return;
    }
  }

  closeEvent(event) {
    this.showLinkModal = false;
  }

  findSrdoLinkedFeatures() {
    this.spinner.show();
   this.componentService.getLinkedFeatures(this.componentVersionId, this.srdo_id, 0).subscribe((data) => {
      this.spinner.hide()
      this.linkedFeatures = data
      this.showLinkModal = true;
      this.oneOnOneMappingFeaturesMenus()
      let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
      let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
      if(isAdmin !== undefined || isGlobalRead !== undefined){
        this.linkedFeatures.map((linkedfeature) => {
          linkedfeature.isNavigable = true
        })
      }else{
         //Component User or Component Owner
         this.checkforIsNavigable()
      }
    }, ((error) => {
      this.spinner.hide()
    }));
  }

  /* Enable Linking on Menu and Feature */
  checkforIsNavigable() {
    this.linkedFeatures = this.linkedFeatures.filter(item => item.systemId !== -1);
    this.linkedFeatures.map((linkedfeature) => {
        let system = this.useraccess.actions.systems.find((system) => {
          return system.systemId === linkedfeature.systemId
        })
        if(system) {
          linkedfeature.isNavigable = true
        }else {
          linkedfeature.isNavigable = false
        }
    })
  }

  /* 1-1 mapping features and menus */
  oneOnOneMappingFeaturesMenus() {
    for (let i = 0; i < this.linkedFeatures.length ; i++) {
      for(let j = i+1; j < this.linkedFeatures.length; j++) {
          if(j < this.linkedFeatures.length && this.linkedFeatures[i].systemId === this.linkedFeatures[j].systemId) {
            if(this.linkedFeatures[i].featureId > 0) {
              if(this.linkedFeatures[j].featureId === 0) {
                this.linkedFeatures[i].menuId = this.linkedFeatures[j].menuId
                this.linkedFeatures[i].menuName = this.linkedFeatures[j].menuName
                this.linkedFeatures[j].systemId = -1
                break;
              }
            } else if(this.linkedFeatures[i].menuId > 0){
              if(this.linkedFeatures[j].menuId === 0) {
                this.linkedFeatures[i].featureId = this.linkedFeatures[j].featureId
                this.linkedFeatures[i].featureName = this.linkedFeatures[j].featureName
                this.linkedFeatures[j].systemId = -1
                break;
              }
            }
          }
      }
    }
    this.linkedFeatures = this.linkedFeatures.filter(x => x.systemId !== -1)
  }

  filterBy(type: FilterType) {
    if(this.selectedFilter === type) {
      this.ascending = !this.ascending
    } else {
      if(type === FilterType.CreatedDate || type === FilterType.ModifiedDate) {
        this.ascending = false
      } else {
        this.ascending = true
      }
    }
    this.selectedFilter = type
  }

  public get filterType() {
    return FilterType; 
  }

}
