import {
  Component, Renderer2, NgZone,
  AfterViewInit, OnInit, OnDestroy,
  ViewEncapsulation, ChangeDetectorRef, ViewChild, Input, ViewContainerRef } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { tap, take, switchMap } from 'rxjs/Operators';
import { State, process } from '@progress/kendo-data-query';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { FeatureService } from '../../services/system/feature.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faPlus, faEdit, faTrash, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateFeatureListingComponent } from '../../system/create-feature-listing/create-feature-listing.component';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserAccess } from 'src/app/global/user-access';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};
@Component({
  selector: 'app-feature-listing',
  templateUrl: './feature-listing.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./feature-listing.component.scss']
})
export class FeatureListingComponent implements OnInit, AfterViewInit, OnDestroy {
  public container: ViewContainerRef;
  @Input() gridViewData: any = [{}];
  @Input() recordCount: number;
  public gridView: GridDataResult;
  public pageSize = 50;
  public skip = 1;
  pageIndex = 1;
  featureId = '';
  closeResult: string;
  faTimes = faTimes;
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faRetweet = faRetweet;
  public state: State = {
      skip: 0,
      take: 50
  };
  reorderCount = 50;
  data = [{}];
  startPage: number;
  skipPageNumber: number;
  @Input() featureListingControlArr: any ;
  @Input() featureListingViewArr: any ;
  @Input() selectedcontentType: any = '';
  @Input() featureWidgetListDataArr: any = [];
  @Input() selectedWidgetType = '';
  @Input() selectedWidgetTypeName = '';
  @Input() selectedWidgetRemainSize: number;
  @Input() selectedWidgetSize: number;
  @Input() selectedView: any;
  @Input() getFeatureId: Observable<void>;
  @Input() getFeatureDBId: Observable<void>;

  globalFeatureId: number;
  public gridData: any = [{}];
  private currentSubscription: Subscription;
  featureListingControlTypesArr: any = [];
  selectedWidgetTyp = '';
  addListingAccess = false;
  listingData = {};
  systemId: number;
  constructor(
    private featureService: FeatureService,
    private router: ActivatedRoute,
    private route: Router,
    private commonService: CommonService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private zone: NgZone,
    private spinner: NgxSpinnerService,
    private confirmationDialogService: DialogboxService,
    private toastService: ToastService,
    private useraccess: UserAccess) {
      this.GetFeatureListingMasterData();
  }
  
  ngOnInit() {
    this.systemId = 0;
    this.getFeatureDBId.subscribe(() => this.globalFeatureId = this.featureService.getFeatureDBServiceId);
    this.getFeatureId.subscribe(() => {
      if(this.featureService.getFeatureServiceId == null){
        this.gridView = {
          data: [],
          total: 0
        };
    }else{
      this.featureId = this.featureService.getFeatureServiceId.toString();
      this.skip = 1;
      this.loadItems(this.pageSize, this.skip);
      this.checkAccess(FeatureListActions.AddListing, Number(this.systemId));
      this.featureService.getFeatureServiceId  = null
    }
    });
    this.router.params.subscribe(params => {
      this.systemId = params.id;
    });
  }
  public ngAfterViewInit(): void {
      this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
      this.currentSubscription.unsubscribe();
  }
  public pageChange(event: PageChangeEvent) {
      this.skip = event.skip;
      this.pageIndex = event.skip / event.take;
      this.pageIndex = this.pageIndex + 1;
      this.loadItems(event.take, this.pageIndex);
  }

  loadItems(getPageSize, getPageIndex) {
    this.spinner.show();
    const dataObj = {
      systemId : this.systemId,
      featureId : this.featureId.toString(),
      pageSize : getPageSize.toString(),
      pageIndex : getPageIndex.toString()
    };
    this.startPage = getPageSize;
    this.skipPageNumber = getPageIndex;
    this.commonService.notifyOther({option: 'page', value: this.featureId });
    if (Number(this.featureId) > 0 && Number(this.systemId) > 0) {
      this.featureService.getFeatureListing(dataObj).subscribe((featureListData) => {
        if (featureListData != null) {
          let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
          let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
          if(isAdmin !== undefined || isGlobalRead !== undefined){
                featureListData.featureListings.map((linkedfeature) => {
                  linkedfeature["isNavigable"] = true
            })
          } else {
            featureListData.featureListings.map((linkedfeature) => {
            let component = this.useraccess.actions.components.find((component) => {
              return component.componentName === linkedfeature.componentName
            })
            if(component) {
              linkedfeature["isNavigable"] = true
            }else {
              linkedfeature["isNavigable"] = false
            }
          });
          }
          this.gridView = {
            data: featureListData.featureListings,
            total: featureListData.recordCount
          };
          this.listingData = featureListData;
          this.state.skip = getPageIndex;
          this.state.take = getPageSize;
          this.reorderCount = (featureListData.recordCount === 0) ? 50 : featureListData.recordCount ;
          this.spinner.hide();
          const dataReOrderObj = {
            systemId : this.systemId,
            featureId : this.featureId.toString(),
            pageSize : this.reorderCount.toString(),
            pageIndex : getPageIndex.toString()
          };
          // this.data = featureListData.featureListings;
          // this.gridData = process(this.data, { });
          this.featureService.getFeatureListing(dataReOrderObj).subscribe((featureListReorderData) => {
            this.data = featureListReorderData.featureListings;
            this.gridData = process(this.data, { });
          }, (error) => {
            this.spinner.hide();
          });
        }
      }, (error) => {
        this.spinner.hide();
      });
    } else {
      this.gridView = {
        data: [],
        total: 0
      };
      this.spinner.hide();
    }
  }
  openModel(content) {
    setTimeout(() => {
      this.currentSubscription = this.handleDragAndDrop();
    }, 100);
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'xl',
      ariaLabelledBy: 'modal-basic-title'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  public dataStateChange(state: State): void {
    this.state = state;

    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1))
        .subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }
  public rowCallback(context: RowClassArgs) {
    return {
        dragging: context.dataItem.dragging
    };
  }
  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {});
    let draggedItemIndex;
    let dropPosition;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
        this.renderer.setAttribute(item, 'draggable', 'true');
        const dragStart = fromEvent<DragEvent>(item, 'dragstart');
        const dragOver = fromEvent(item, 'dragover');
        const dragEnd = fromEvent(item, 'dragend');

        sub.add(dragStart.pipe(
            tap(({ dataTransfer }) => {
                try {
                  const dragImgEl = document.createElement('span');
                  dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
                  document.body.appendChild(dragImgEl);
                  dataTransfer.setDragImage(dragImgEl, 0, 0);
                } catch (err) {
                  // IE doesn't support setDragImage
                }
                try {
                    // Firefox won't drag without setting data
                    dataTransfer.setData('application/json', '');
                } catch (err) {
                    // IE doesn't support MIME types in setData
                }
            })
        ).subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
        }));

        sub.add(dragOver.subscribe((e: any) => {
            e.preventDefault();
            const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
            const dropIndex = closest(e.target, tableRow).rowIndex;
            const dropItem = this.gridData.data[dropIndex];

            dropPosition = dropItem;
            draggedItemIndex = dropIndex;
            this.zone.run(() => {
              this.gridData.data.splice(dropIndex, 0, dataItem);
              }
            );
        }));

        sub.add(dragEnd.subscribe((e: any) => {
            e.preventDefault();
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = false;

            const objectData = {
              sourceId : dataItem.id.toString(),
              destinationId : (dropPosition !== undefined) ? dropPosition.id.toString() : '0'
            };
            this.featureService.SwapFeatureListingPosition(objectData).subscribe(( resData) => {
              if ( resData === true) {
                this.loadItems(this.startPage, this.skipPageNumber);
              }
            }, (error) => {
              this.spinner.hide();
            });
        }));
    });

    return sub;
  }

  deleteFeatureListing(gridData) {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
    .then((confirmed) => {
      if (confirmed === true) {
        this.spinner.show();
        this.featureService.deleteFeatureListing(gridData.id).subscribe((datRes) => {
            this.loadItems(this.startPage, this.skipPageNumber);
            this.spinner.hide();
        }, (error) => {
            this.spinner.hide();
        });
      }
    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  editFeatureListing(gridData) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const editId = gridData.id;
    const modalRef = this.modalService.open(CreateFeatureListingComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Edit ';
    modalRef.componentInstance.editId = editId;
    modalRef.componentInstance.editData = gridData;
    modalRef.componentInstance.featureListingControlTypes = this.featureListingControlArr;
    modalRef.componentInstance.featureListingView = this.featureListingViewArr;
    modalRef.componentInstance.featureWidgetListData = this.featureWidgetListDataArr;
    modalRef.componentInstance.selectedWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.selectedcontentType = this.selectedcontentType;
    modalRef.componentInstance.selectedWidgetType = this.selectedWidgetType;
    let found = this.featureWidgetListDataArr.find((item) => item.id == gridData.widgetId)
    modalRef.componentInstance.selectedWidgetTypeName = found ? found?.name : this.selectedWidgetTypeName;
    modalRef.componentInstance.selectedView = this.selectedView;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.UpdateFeatureList(result.features, editId).subscribe((res) => {
        if (res !== null) {
          this.ToastMessage('Feature List Updated Successfully.', 'bg-success');
          modalRef.close();
          this.loadItems(this.state.take, this.state.skip);
          this.spinner.hide();
        }
      }, (error) => {
      this.spinner.hide();
    });
    });
  }

  ToastMessage(message, type) {
    if(this.container) {
    this.toastService.show(message, {
    classname: type,
    delay: 3000 ,
    autohide: true,
    appendTo: this.container,
    headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }else {
    this.toastService.show(message, {
      classname: type,
      delay: 3000 ,
      autohide: true,
      headertext: type === 'bg-success' ? 'Success' : 'Error'
      });
  }
  }

  openFeatureListing() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const modalRef = this.modalService.open(CreateFeatureListingComponent,ngbModalOptions);
    modalRef.componentInstance.title = 'Add ';
    modalRef.componentInstance.featureListingControlTypes = this.featureListingControlTypesArr;
    modalRef.componentInstance.featureListingView = this.featureListingViewArr;
    modalRef.componentInstance.selectedcontentType = this.selectedcontentType;
    modalRef.componentInstance.selectedView = this.selectedView;
    modalRef.componentInstance.featureWidgetListData = this.featureWidgetListDataArr;
    modalRef.componentInstance.selectedWidgetType = this.selectedWidgetType;
    modalRef.componentInstance.selectedWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.selectedWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.defaultWidgetType = this.selectedWidgetType;
    modalRef.componentInstance.defaultWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.defaultWidgetSize = this.selectedWidgetSize;
    setTimeout(() => {
      this.container = modalRef.componentInstance.getRef()
    }, 1000)
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.CreateFeatureList(result.features, this.globalFeatureId).subscribe((res) => {
        if (res !== null) {
          if(!result.continue) {
               this.container = undefined
               modalRef.close();
          }
          this.ToastMessage('Feature List Created Successfully.', 'bg-success');
          this.loadItems(this.pageSize, this.state.skip);
          this.spinner.hide();
        }
      }, (error) => {
      this.spinner.hide();
    });
    });
  }
  GetFeatureListingMasterData() {
    this.featureService.GetFeatureListingMasterData().subscribe((data) => {
      if (data !== null) {
        this.featureListingControlTypesArr = data.featureListingControlTypes;
        this.featureListingViewArr = data.featureListingView;
        this.selectedcontentType = data.featureListingControlTypes[0].id;
        this.selectedView = data.featureListingView[0].id;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case FeatureListActions.AddListing:
        this.addListingAccess = this.useraccess.getUserAccess('systems', action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }
  public checkAccessForItem(action: string, dataItem: any = undefined): Boolean {
    var state = this.useraccess.getUserAccess('systems',
                action, dataItem !== undefined ? dataItem.id : -1);
    return state;
  }

  public navigateToComponentData(dataItem) {
    //Get the GetComponentDataForNavigation data before routing
    this.spinner.show()
    this.featureService.componentDataForNavigation(dataItem.id, "false").subscribe((versionId: Number) => {
      this.spinner.hide()
      dataItem.versionId = versionId;
      this.commonService.selectedSRDOCommand = dataItem;
      this.route.navigateByUrl('/component-data')
    }, error => {
      this.spinner.hide()
      this.commonService.selectedSRDOCommand = dataItem;
      this.route.navigateByUrl('/component-data')
    });
    
  }
}

export enum FeatureListActions {
  AddListing = 'AddListing'
 }
