import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { faEdit, faPlus, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentListHeader } from 'src/app/global/app-enums.enum';
import { UserAccess } from 'src/app/global/user-access';
import { AssociatedRoles, Language, Region } from 'src/app/models/component/component.data.model';
import { CommandResponseService } from 'src/app/services/component/command-response.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ComponentListService } from '../../services/component/component-data.service';
import { ToastService } from '../../services/toast/toast.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.scss']
})
export class ComponentListComponent implements AfterViewInit, OnInit, OnDestroy {
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faUsers = faUsers;
  public pageSize = 20;
  public skip = 0;
  // tslint:disable-next-line:ban-types
  private data: Object[];
  public recordCount = 0;
  public gridView: GridDataResult;
  private editedRowIndex: number;
  private isNew = false;
  public formGroup: UntypedFormGroup;
  public filteredValue = '';
  public editId: 0;
  public isAdmin = false
  list: {}[]; // Table data
  editingData: Region[] = [];
  regionsdata: Region[] = [];
  newregions: Region[] = [];
  newlanguages: Language[] = [{ id: 1, name: 'English', code: 'en' }];
  languagesData: Language[] = [];
  values: Region[] = [{ id: 1, name: 'All', description: 'All' }];
  addComponentAccess = false;
  rowItem: any = {}
  userRoles: AssociatedRoles[] = []
  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  private remIDPrefixValue: string = "0x";
  headers = [
    {
      key: 'name',
      value: ComponentListHeader.Name
    },
    {
      key: 'remTypeIdentifier',
      value: ComponentListHeader.remTypeIdentifier
    },
    {
      key: 'scn',
      value: ComponentListHeader.Scn
    },
    {
      key: 'regions',
      value: ComponentListHeader.Regions
    },
    {
      key: 'actions',
      value: ComponentListHeader.Actions
    }];
  headerData = { pageSize: this.pageSize.toString(), pageIndex: (this.skip + 1).toString(), sortorder: 'Descending', sortcolumname: 'CreatedOn' };
  isLoadingResults = false;
  showConfirmation = false;
  textFileData = ''
  title = ''
  opened = false;
  // For Angular 8
  @ViewChild(GridComponent, { static: true })
  public grid: GridComponent;
  public showDefaultModal = false
  // Language Subscription
  languageSubscription: Subscription;

  constructor(private componentListService: ComponentListService,
    public toastService: ToastService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private useraccess: UserAccess,
    private masterDataService: MasterDataService,
    private userAccess: UserAccess,
    private commandService: CommandResponseService,
    private authService: MsalService,
    private commonService: CommonService
  ) { }


  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe()
  }

  ToastMessage(message, type, headertext) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext
    });
  }
  
  ngAfterViewInit(): void {
    // https://blog.angular-university.io/angular-debugging/
    // In order to defer the code inside ngAfterViewInit to another Javascript turn
    setTimeout(() => {
      this.getComponentList();
      this.getLocations();
      let activeAccount = this.authService.instance.getActiveAccount();
      if (this.userAccess.actions === undefined && activeAccount) {
        const headerData = { userEmailId: activeAccount.username};
        this.userAccess.getUserClaims(headerData).subscribe((status) => {
          if (status == true) {
            this.checkAccess(ComponentActions.AddComponent);
            this.isAdmin = this.userAccess.actions.roles.find(x => x.id === 1) ? true : false
          }
          else {
            window.location.replace('/error')
          }
        });
      } else {
        this.checkAccess(ComponentActions.AddComponent);
        this.isAdmin = this.userAccess.actions.roles.find(x => x.id === 1) ? true : false
      }
    }, 500);
  }

  ngOnInit() {
    this.languageSubscription = this.commonService.languagesObservable$.subscribe((languages) => {
      if(languages) {
        this.languagesData = languages
      }
    })
  }

  //Filter ComponentList by ComponentName
  public filterChange(event) {
    this.filteredValue = '';
    this.skip = 0;
    this.filteredValue = event;
    this.getComponentList();
  }


  getComponentList() {
    this.spinner.show();
    this.componentListService.getComponentListData(this.headerData, this.filteredValue).subscribe((data) => {
      this.spinner.hide();
      this.list = data.components.map(component =>
      ({
        id: component.id, name: component.name, 
        scn: component.scn, 
        regions: component.regions.length === 0 ? [{ id: 1, name: 'All', description: 'All' }] : component.regions,
        languages: (component.languages.length == 0) ? [{id: 1, name: "English", code: "en"}] : component.languages,
        label: this.getItemLanguages(component.languages),
        state: component.deployed,
        remTypeIdentifier: this.remIDPrefixValue + component.remTypeIdentifier, isEditable: false
      })
      );

      this.loadComponents(data.recordCount)
     
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    let field = 'CreatedOn'
    if(this.sort[0].dir) {
      field = this.sort[0].field
    }
    this.headerData = {...this.headerData, sortcolumname: field, sortorder: this.sort[0].dir === 'asc' ? 'Ascending' : 'Descending'}
    this.getComponentList();
  }


loadComponents(count: number) {
  this.gridView = {
    data: orderBy(this.list, this.sort).slice(0, this.pageSize),
    total: count
  };
}
  

  getLocations() {
    this.spinner.show();
    this.masterDataService.getAllRegions().subscribe((regions) => {
      this.spinner.hide();
      if (regions !== null) {
        this.regionsdata = regions;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  onPageChanged(pageData: { pageIndex: number }) {
    this.headerData.pageIndex = pageData.pageIndex.toString();
    this.getComponentList();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.headerData.pageIndex = ((this.skip / this.pageSize) + 1).toString();
    this.getComponentList();
  }

  onRecordDeleted(Id) {
    this.componentListService.deleteComponentListData(Id).subscribe((res) => {
      if (res === true) {
        this.ToastMessage('Component deleted Successfully.', 'bg-success', 'Success');
        this.getComponentList();
      } else {
        this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
      }
    });
  }
  public cancelHandler({ sender, rowIndex, dataItem }) {
    //add the remtype identifier Prefix 0x to the field
    dataItem.remTypeIdentifier = this.remIDPrefixValue + dataItem.remTypeIdentifier;
    dataItem.isEditable = false;
    dataItem.regions = this.editingData;
    const [first, ...rest] = this.newlanguages
    this.newlanguages = [first]
    sender.closeRow(rowIndex);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    if (dataItem.remTypeIdentifier.includes(this.remIDPrefixValue)) {
      //remove the remtype identifier Prefix 0x before editing the field
      dataItem.remTypeIdentifier = dataItem.remTypeIdentifier.substring(2);
    }
    this.editingData = dataItem.regions;
    this.closeEditor(sender);
    dataItem.isEditable = true;
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.editId = dataItem.id;
    sender.editRow(rowIndex, this.formGroup);
  }

  public onValueChange(value, from: string) {
    if(from == 'regions') {
      this.newregions = []
      this.newregions = value
    } else {
      this.newlanguages = []
      this.newlanguages = value
    }
 }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(dataItem.name, [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]{3,22}$")]),
      remTypeIdentifier: new UntypedFormControl(dataItem.remTypeIdentifier, [Validators.required, Validators.pattern("[0-9A-F]{2}")]),
      scn: new UntypedFormControl(dataItem.scn, [Validators.min(1),
      Validators.max(99999), Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      createdBy: new UntypedFormControl('test@gmail.com')
    });
  }

  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formBuilder = undefined;
  }

  public addHandler() {
    this.grid.addRow(this.createFormGroup({ name: '', remTypeIdentifier: "00", scn: 1 }));
  }

  public saveHandler({ sender, formGroup, rowIndex, isNew, dataItem }) {
    if (formGroup.valid) {
      if (isNew) {
        let value = formGroup.value;
        let items = [];
        let status = this.checkForDuplicateName(value.name, undefined)
        if(status) {
          formGroup.controls['name'].setErrors({'incorrect': true});
          return
        }
        if (this.newregions.length === 0) {
          this.newregions = this.values;
        }
        this.newregions.forEach(function (item) {
          items.push(item.id);
        });
        let languages = [];
          if(this.newlanguages.length == 0) {
            this.newlanguages = [{ id: 1, name: 'English', code: 'en' }];
          }
          this.newlanguages.forEach(function (item) {
            languages.push(item.id);
           });
          value.regions = items;
          value.languages = languages
          this.newregions = [];
          this.newlanguages = [{ id: 1, name: 'English', code: 'en' }];
          value.createdBy = 'test@gmail.com';
          this.spinner.show();
          dataItem.isEditable = false;
          sender.closeRow(rowIndex);
          this.componentListService.addComponent(value).subscribe((res) => {
          this.spinner.hide();
          if (res === true) {
            this.ToastMessage('Component Created Successfully.', 'bg-success', 'Success');
            this.getComponentList();
          } else {
            const response = JSON.stringify(res);
            const resData = JSON.parse(response);
            this.ToastMessage(res, 'bg-danger', 'Error');
          }
        }, () => {
          this.spinner.hide();
        });
      } else {
        let items = [];
        let value = formGroup.value;
        let status = this.checkForDuplicateName(value.name, this.editId)
        if(status) {
          formGroup.controls['name'].setErrors({'incorrect': true});
          return
        }
        this.spinner.show();
        dataItem.regions.forEach(function (item) {
          items.push(item.id);
        });
        value.regions = items;
        let languages = [];
          dataItem.languages.forEach(function (item) {
            languages.push(item.id);
          });
          value.languages = languages
          dataItem.isEditable = false;
          sender.closeRow(rowIndex);
        this.componentListService.editComponent(value, this.editId).subscribe((res) => {
          this.spinner.hide();
          if (res === true) {
            this.ToastMessage('Component Updated Successfully.', 'bg-success', 'Success');
            this.getComponentList();
          } else {
            this.ToastMessage('HTTP ERROR........', 'bg-danger', 'Error');
            this.spinner.hide();
            this.getComponentList();
          }
        }, (error) => {
          this.spinner.hide();
          this.getComponentList();
        });
      }
      if (sender !== null)
        sender.closeRow(rowIndex);
    }
  }

  checkForDuplicateName(name: string, editingId: number) {
   let items = this.list.filter((item: any) => {
      if(item.name && item.name.length > 0) {
        if(editingId && editingId == item.id) {
            return false
        }
        else return item.name.toLowerCase() == name.toLowerCase()
      }
      return false
   })
   let found = false
   if(items) {
        found = items.length > 0 ? true : false 
   }
   return found
  }

  public checkAccessForItem(action: string, dataItem: any = undefined): Boolean {
    var state = this.useraccess.getUserAccess('components',
      action, dataItem !== undefined ? dataItem.id : -1);
    return state;
  }

  public getItemRegions(reguins: Region[]): string {
    if (reguins === undefined) {
      return '';
    }
    let items = [];
    reguins.forEach(function (item) {
      items.push(item.name);
    });
    return items.toString();
  }

  public getItemLanguages(languages: Language[] = []): string {
    if(languages.length == 0) {
      return 'English';
    }
    let items = [];
    languages.forEach(function (item) {
      items.push(item.name);
    });
    return items.toString();
  }


  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case ComponentActions.AddComponent:
        this.addComponentAccess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }

  public removeHandler({ sender, dataItem }) {
    this.rowItem = { sender, dataItem }
    this.showConfirmation = true
  }
  /*
   * name
  */
  deleteConfirmation(status: boolean) {
    this.showConfirmation = false
    if (status) {
      this.spinner.show();
      this.componentListService.deleteComponentListData(this.rowItem.dataItem.id).subscribe((res) => {
        this.spinner.hide();
        if (res === true) {
          this.ToastMessage('Component deleted Successfully.', 'bg-success', 'Success');
          this.getComponentList();
        } else {
          this.ToastMessage('HTTP ERROR....', 'bg-danger', 'danger');
        }
      }, (error) => {
        this.spinner.hide();
      });
      this.rowItem.sender.cancelCell();
    }
  }

importDefaultFile() {
    this.spinner.show();
    this.commandService.getComponentDefaultTextFile(
      2147483647).subscribe(
        (restext) => {
          this.textFileData = restext.dataText;
          this.showDefaultModal = true
          this.spinner.hide();
        }, () => {
          this.ToastMessage('Something went wrong.', 'bg-danger', 'Error');
          this.spinner.hide();
        });
  }

  closeEvent(state: boolean) {
    this.showDefaultModal = false
  }

  uploadFile(filedata: any) {
    this.spinner.show();
    this.commandService.defaultTextFileUpload(filedata, 2147483647).subscribe(
      (res) => {
        this.spinner.hide();
        this.showDefaultModal = false
        this.ToastMessage('Successfully uploaded.', 'bg-success', 'success');
      }, (() => {
        this.spinner.hide();
      }));
}

  showAssociatedUsers(dataItem: any) {
    this.title = `${dataItem.name} - Users & Roles`
    this.spinner.show()
    this.userRoles = []
    this.componentListService.fetchAssociatedUsers(dataItem.id).subscribe((users) => {
      this.spinner.hide()
      this.opened = true
      if(users && users.length > 0) {
        users.forEach((user)=> {
           let userRole = this.userRoles.find((obj) => user.emailAddress == obj.email)
            if(userRole) {
                let item = userRole
                item.roles.push(this.fetchRole(user.roleId))
            } else {
              let item = {email: user.emailAddress, roles: [this.fetchRole(user.roleId)]}
              this.userRoles.push(item)
            }
        })
      }
    }, (error) => {
      this.spinner.hide()
    });
  }

  close(state: string) {
    this.opened = false
  }

  fetchRole(roleId: number) {
    let roleName = ''
    switch (roleId) {
      case 1://Adminstartor
        roleName = 'Adminstartor';
        break;
      case 2://System Owner
        roleName = "System Owner";
        break;
      case 3://System User
        roleName = "System User";
        break;
      case 4://Component Owner
        roleName = "Component Owner";
        break;
      case 5: //Component User
        roleName = "Component User";
        break;
      case 6: //Phone Adminstrator
        roleName = "Phone Adminstrator";
        break;
      case 7: //Component Read Only
        roleName = "Component Read Only";
        break;
      case 8: //System Read Only
        roleName = "System Read Only";
        break;
      case 9: //Global Read Only
        roleName = "Global Read Only";
        break;
      default:
        break;
    }
    return roleName
  }

  
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index == 0; // disable the 3rd item
  }
  
}

export interface ComponentTableData {
  scn: number;
  name: string;
  deployed: boolean;
  id: number;
}


export enum ComponentActions {
  AddComponent = 'AddComponent',
}

