import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { environment } from 'src/environments/environment';
import { ComponentDataModule } from './component-data/component-data.module';
import { UsersModule} from './user/users.module';
import { SystemsModule } from './system/systems.module';
import { WidgetsModule } from './widget/widgets.module';
import { SharedModule } from './shared/shared.module';
import { ModelPopupComponent } from './common/model-popup/model-popup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { DialogboxService } from './services/confirmation-dialog/dialogbox.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LoginComponent } from './login/login.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ErrorComponent } from './error/error.component';
import {CookieService} from 'ngx-cookie-service';
import { ViewAccessComponent } from './access/view-access/view-access.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuditModule } from './audit/audit.module';
import { ChangelogModule } from './changelog/changelog.module';
import { SvgIconComponent } from './common/svg-icon/svg-icon.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}
  
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      authority: environment.msalConfig.auth.authority + environment.msalConfig.auth.tenantId,
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment
      redirectUri: '/',
      postLogoutRedirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`${environment.msalConfig.auth.clientId}/.default`]
    },
    loginFailedRoute: '/error'
  };
}


@NgModule({
    declarations: [
        AppComponent,
        ModelPopupComponent,
        LoginComponent,
        ErrorComponent,
        ViewAccessComponent,
        SvgIconComponent,
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FontAwesomeModule,
        UsersModule,
        ComponentDataModule,
        SystemsModule,
        WidgetsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        NgxSpinnerModule,
        TooltipModule,
        NotificationModule,
        DialogModule,
        LayoutModule,
        ButtonsModule,
        DropDownsModule,
        InputsModule,
        GridModule,
        MsalModule,
        AuditModule,
        ChangelogModule
    ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (appConfig: AppSettingsService) => () => appConfig.fetchAppSettings(),
    //   deps: [AppSettingsService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },

    DialogboxService,
    //CookieService
    CookieService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
