import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChangelogRoutingModule } from './changelog-routing.module';
import { ChangelogComponent } from './changelog.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { VersionchangesComponent } from './versionchanges.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputsModule } from '@progress/kendo-angular-inputs';


@NgModule({
  declarations: [
    ChangelogComponent,
    VersionchangesComponent,
    
  ],
  imports: [
    CommonModule,
    ChangelogRoutingModule,
    GridModule,
    DropDownListModule,
    FormsModule,
    ButtonsModule,
    NgxSpinnerModule,
    InputsModule
  ]
})
export class ChangelogModule { }
