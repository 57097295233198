import { Component, inject, OnInit } from '@angular/core';
import { DisplayItem, DisplayModel } from '../common/copy-modal/copy-modal.component';
import { ComponentListService } from '../services/component/component-data.service';
import { renameKey } from '../global/app-enums.enum';
import { VersionchangesService } from './versionchanges.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentInfo } from '../models/component/component.data.model';
import { CommonService } from '../services/common.service';
import { map } from 'rxjs/Operators';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit{
  private componentService = inject(ComponentListService);
  componentAndItsversions: DisplayItem[] = [];
  btnText = 'Version Changes'
  sourceComponentId: number = 0
  targetComponentId: number = 0

  constructor(private versionService: VersionchangesService, private spinner: NgxSpinnerService, private commonService: CommonService) {
    this.componentAndItsversions = [];
    // this.menuDetails = {"Destination Menu Node": this.selectedItem.name }
    this.componentAndItsversions.push({
      title: "Component",
      values: [],
      disabled: false,
      selectedValue: { id: "", name: "Please select a component" },
    });
    this.componentAndItsversions.push({
      title: "Source Version",
      values: [],
      disabled: true,
      selectedValue: { id: "", name: "Please select source version" },
    });
    this.componentAndItsversions.push({
      title: "Target Version",
      values: [],
      disabled: true,
      selectedValue: { id: "", name: "Please select target version" },
    });
    this.componentAndItsversions.push({
      title: "Type",
      values: [
        {id: "1", name: "SRDO"},
        // {id: "2", name: "CR"}
      ],
      disabled: true,
      selectedValue: {id: "1", name: "SRDO"},
    });
  }


  ngOnInit(): void {
    this.fetchComponents()
  }

  async fetchComponents() {
      this.spinner.show()
      let componentsData = await this.componentService.getComponentList(false, true).toPromise()
      let components = componentsData.map(item => {
        return {id: item.id.toString(), name: item.name }
      })
      this.spinner.hide()
      if(components && components.length > 0) {
        this.componentAndItsversions[0].values = components
        if(this.commonService.versionChangeData) {
          this.setComponentSelected(components)
        } else {
          console.log({id: components[0].id.toString(), name: components[0].name})
          this.componentAndItsversions[0].selectedValue = {id: components[0].id.toString(), name: components[0].name}
          this.fetchVersionsOfAComponent(+components[0].id)
        }
      }
  }

  async fetchVersionsOfAComponent(componentId: Number) {
      this.spinner.show()
      let versions = await this.componentService.getversionlistbycomponent(componentId).toPromise()
      this.spinner.hide()
      if(versions && versions.length > 0) {
        Object(versions).forEach((obj) => {
        renameKey(obj, "versionNumber", "name");
        renameKey(obj, "versionNumberId", "id"); 
        })       
        this.componentAndItsversions[1].values = versions
        this.componentAndItsversions[2].values = versions
        if(this.commonService.versionChangeData) {
          this.setVersionsSelected(versions)
        } else {
          const val = { id: "", name: "Please select source version" }
          this.componentAndItsversions[1].selectedValue = val
          this.componentAndItsversions[2].selectedValue = val
        }
      }
      this.commonService.versionChangeData = null
  }

  setComponentSelected(components: any[]) {
    let componentId = this.commonService.versionChangeData.baseComponentId
    let component = components.find((item) => item.id == componentId)
    if(componentId) {
      this.componentAndItsversions[0].selectedValue = {id: component.id.toString(), name: component.name}
      this.fetchVersionsOfAComponent(component.id)
    }
  }

  setVersionsSelected(versions) {
    let sourceId = this.commonService.versionChangeData.sourceComponentId
    let targetId = this.commonService.versionChangeData.targetComponentId
    let version = versions.find(item => item.id == sourceId)
    let versiontarget = versions.find(item => item.id == targetId)
    if(version && versiontarget) {
      this.componentAndItsversions[1].selectedValue = version
      this.componentAndItsversions[2].selectedValue = versiontarget
      setTimeout(() => {
        this.showDiff()
      }, 100)
    }
  }
  
  valueChanged(value: any, title: string): void {
    switch(title) {
      case this.componentAndItsversions[0].title:
          this.componentAndItsversions[0].selectedValue= value
          this.fetchVersionsOfAComponent(value.id)
        break;
    }
    if(value.displayText) {
      // this.responseDesc = value.displayText
    }
    value.extra = title
    // this.selectionChange.emit(value)
  }

  showDiff() {
    let sourceId = this.componentAndItsversions[1].selectedValue.name.length > 0 ? +this.componentAndItsversions[1].selectedValue.id : 0
    let destinationId = this.componentAndItsversions[2].selectedValue.name.length > 0 ? +this.componentAndItsversions[2].selectedValue.id : 0
     if (sourceId != 0 && destinationId != 0) {
        this.sourceComponentId = sourceId
        this.targetComponentId = destinationId
        this.versionService.notifyToFetch({sourceComponentId: sourceId, targetComponentId: destinationId, 
          sourceComponentVersion: this.componentAndItsversions[1].selectedValue.name,
          tragetComponentVersion: this.componentAndItsversions[2].selectedValue.name,
          componentName: this.componentAndItsversions[0].selectedValue.name
        })
     }
     else {
      return;
     }
  }

  
}
