import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { GridComponent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { AddUserComponent, Role } from '../add-user/add-user.component';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user/user.model';
import { UserAccess } from 'src/app/global/user-access';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  public opened = false; //Delete confirmation dialog
  public deleteItem: User;
  isAdmin = false;
  @ViewChild(GridComponent) grid: GridComponent;
  public gridView: any[];
  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.userid;
  }
  pageSize = 20;
  headerData = { pageSize: this.pageSize.toString(), pageIndex: (1).toString(), sortorder: 'Ascending', sortcolumname: 'Id' };

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public openedItems: Set<number>;
  public usersList: User[];

  public formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  constructor(private dialogService: DialogService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    public userAccess: UserAccess) { }
  ngOnInit() {
    this.openedItems = new Set<number>()
    let isfind = false;
    if(this.userAccess.actions) {
      isfind = this.userAccess.actions.roles.find(x => x.id === 1) === undefined ? false : true;
    }
    //If not admin stop calling the apis
    if (isfind) {
      setTimeout(() => {
        this.getUsersList();
        this.checkAccess("")  
      }, 1000);
    } else {
      window.location.replace('/error')
    }
  }

  expanded(e) {
    this.openedItems.add(e.index);
  }

  collapsed(e) {
    this.openedItems.delete(e.index);
  }

  pageChange(event) {

  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    // this.getComponentList();
    this.gridView = orderBy(this.usersList, this.sort)
  }

  ShowOrHide(index: number) {
    if (this.openedItems.has(index)) {
      this.grid.collapseRow(index);
      this.openedItems.delete(index);
      return;
    }
    // Expand the first row initially
    this.grid.expandRow(index);
    this.openedItems.add(index);
  }

  addUser() {
    this.openDialogForm(null);
  }

  getRoles(dataItem: User) {
    if (dataItem.roles) {
      return Array.prototype.map.call(dataItem.roles, function (item) { return item.roleName; }).join(", "); // "A,B,C"
    }
    return '';
  }

  getRolesCompos(dataItem: User, index: number): string {
    let final = ''
    if (dataItem.roles !== null) {
      let role = dataItem.roles[index];
      final += role.roleName;
      let comps: string = Array.prototype.map.call(role.mappedEntities, function (item) { return item.entityName; }).join(", ");
      if (comps !== null) {
        return comps.length > 0 ? final + " : " + comps : final;
      }
      return final;
    }
    return '';
  }

  getUsersList() {
    this.spinner.show();
    this.userService.getUsersList(this.headerData).subscribe((data) => {
      this.usersList = data;
      this.gridView = this.usersList;
      this.spinner.hide();
    },
      ((error) => {
        this.spinner.hide();
      }));

  }

  public openDialogForm(dataItem: User) {
    const dialogRef = this.dialogService.open({
      content: AddUserComponent
    });

    const userInfo = dialogRef.content.instance;
    userInfo.editItem = dataItem;
    dialogRef.result.subscribe(r => {
      if (r instanceof DialogCloseResult) {
        //Don't do anything - manual close
      } else {
        this.getUsersList();
      }
    });
  }

  editUser(dataItem: User) {
    this.openDialogForm(dataItem);
  }

  deleteUser(dataItem: User) {
    this.opened = true;
    this.deleteItem = dataItem;
  }

  public close(status: boolean) {
    this.opened = false;
    if (status == true) {
      this.spinner.show();
      this.userService.deleteUser(this.deleteItem.userId).subscribe((data) => {
        this.spinner.hide();
        this.getUsersList();
      },
        ((error) => {
          this.spinner.hide();
        }));
    }

  }

  public showOnlyRoles(dataItem: User, index: number): boolean {
    return dataItem.roles !== null;
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.usersList, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'userName',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'emailId',
            operator: 'contains',
            value: inputValue
          },
        ],
      }
    }).data
    this.dataBinding.skip = 0;
  }

  public checkAccess(action: string, dataItem: any = undefined) {
    this.isAdmin = this.userAccess.getUserAccess("", action, dataItem !== undefined ? dataItem.id : -1);
  }
}

