import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { SystemListService } from '../../services/system/system-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { faAngleDoubleDown, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { UserAccess } from 'src/app/global/user-access';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ArtifactsComponent } from '../artifacts/artifacts.component';
import { SideNavMenuItems } from 'src/app/global/app-enums.enum';
import { filter } from 'rxjs/Operators';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CopySystemComponent } from '../../system/copy-system/copy-system.component';
import { LinkedFeatureSystem } from 'src/app/common/display-list-modal/display-list-modal.component';
import { FeatureService } from 'src/app/services/system/feature.service';
import { SystemModel } from 'src/app/models/system/system.data.model';
import { NotificationRef, NotificationService } from "@progress/kendo-angular-notification"
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-system-data',
  templateUrl: './system-data.component.html',
  styleUrls: ['./system-data.component.scss'],
  providers: [DatePipe]
})
export class SystemDataComponent implements OnInit {
  systemList: SystemModel[] = [];
  routerId: number;
  selectedSystemId: number;
  parentRouterID: Subject<any> = new Subject();
  canshowDownload = false;
  canshowCopySystem = false;
  deployArtifacts: Artifacts;
  testArtifacts: Artifacts;
  faAngleDoubleDown = faAngleDoubleDown;
  faCopy = faCopy;
  faVoteYea = faVoteYea;
  deployAccess = false;
  testAccess = false;
  downloadAccess = false;
  isDeployedFlag = false;
  pageRefreshed: boolean = false;
  subscription: Subscription;
  @ViewChild('tabs', { static: true }) tabs: NgbNav;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;
  constructor(public router: Router,
              private systemListService: SystemListService,
              private spinner: NgxSpinnerService,
              private commonService: CommonService,
              private toastService: ToastService,
              private useraccess: UserAccess,
              private modalService: NgbModal,
              private dialogService: DialogService,
              private confirmationDialogService: DialogboxService,
              private featureService: FeatureService,
              private notificationService: NotificationService,
              private datePipe: DatePipe) {
    this.subscription = this.commonService.onMessage().subscribe((message) => {
      if (message.text === SideNavMenuItems.SystemData && this.pageRefreshed === false) {
        this.loadData();
      }
    });

    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if (
        event.id === 1 &&
        event.url === event.urlAfterRedirects 
      ) {
        this.pageRefreshed = true;
      }
    })

    this.commonService.selectMenuObservable().subscribe(() => {
      setTimeout(()=>{
        this.tabs.select("1")
      }, 1)
    })
  }
  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadData() {
    this.spinner.show();
    this.systemListService.getAllSystemList(false).subscribe((listRes) => {
      this.systemList = listRes;
      let index = 0;
      if(listRes) {
        this.systemListService.systemList =  listRes;
        if(this.commonService.selectedFeature) {
          let indexvalue = this.systemList.findIndex((item) => {
                return (+item.id === this.commonService.selectedFeature.systemId)
          })
          index = indexvalue > -1 ?  indexvalue : 0
        }
        if (this.router.url === '/system-data' || this.pageRefreshed === true) {
          this.pageRefreshed = false;
          if(this.commonService.selectedFeature && this.commonService.selectedFeature.isMenuClicked) {
            this.featureService.featureMenuId = this.commonService.selectedFeature.menuId
            this.commonService.selectedFeature.isMenuClicked = false
            this.router.navigate(['/system-data', 'menu', this.systemList[index].id]);
          } else {
            this.router.navigate(['/system-data/feature/', this.systemList[index].id]);
          }
        }
        this.setSelectedSystem(index)
        this.checkAccess(SystemDataActions.DownloadSystemArtifacts, this.routerId);
        this.checkAccess(SystemDataActions.Deploy, this.routerId);
        this.checkAccess(SystemDataActions.CopySystem, this.routerId);
        this.checkAccess(SystemDataActions.TestSystem, this.routerId);
        this.getArtifacts();
      }
      this.spinner.hide();
    }, () => {
      this.spinner.hide();
    });
  }

  setSelectedSystem(index: number) {
    this.selectedSystemId = this.systemList[index].id;
    this.systemListService.selectedSystemId = this.systemList[index].id;
    this.routerId = this.systemList[index].id;
    this.isDeployedFlag = this.systemList[index].deployed;
    if(this.commonService.selectedFeature) {
      this.featureService.getFeatureServiceId = this.commonService.selectedFeature.featureId
      // this.featureService.attachedFetureName(this.commonService.selectedFeature.featureName, this.commonService.selectedFeature.featureId);
      // this.commonService.selectedFeature = undefined
    }
  }

  public onTabChange(event) {
    switch (event.nextId) {
      case "0":
        this.router.navigate(['/system-data', 'feature', this.routerId]);
        break;
      case "1":
        this.router.navigate(['/system-data', 'menu', this.routerId]);
        break;
    }
  }

  getArtifacts() {
    this.spinner.show();
    this.systemListService.GetDownloadSystemArtifacts(this.routerId.toString(), true).subscribe(res => {
      this.canshowDownload =  (res && res.recordCount) > 0 ? true : false;
      this.deployArtifacts = res;
    }, () => {
        this.spinner.hide();
    });
    this.systemListService.GetDownloadSystemArtifacts(this.routerId.toString(), false).subscribe(res => {
      this.canshowDownload = (res && res.recordCount) > 0 ? true : false;
      this.testArtifacts = res;
    }, () => {
      this.spinner.hide();
    });
    this.spinner.hide();
  }

  changeSystemName(event) {
    this.routerId = event.target.value;
    this.canshowDownload = false;
    this.checkAccess(SystemDataActions.DownloadSystemArtifacts, this.routerId);
    this.checkAccess(SystemDataActions.Deploy, this.routerId);
    this.checkAccess(SystemDataActions.CopySystem, this.routerId);
    this.checkAccess(SystemDataActions.TestSystem, this.routerId);

    this.parentRouterID.next(event.target.value);
    const item = this.systemList.filter(system => system.id === +event.target.value);
    this.selectedSystemId = item[0].id;
    this.systemListService.selectedSystemId = item[0].id;
    this.isDeployedFlag = item[0].deployed;

    this.getArtifacts();

    const currentUrl = this.router.url;
    const splitted = currentUrl.split('/');
    this.router.navigate(['system-data/', splitted[2], event.target.value]);
    this.commonService.notifyOther({ option: 'system-data', value: this.routerId });
  }

  deploySystem() {
    this.confirmationDialogService.confirm('Please confirm',
    'Please confirm if you want to deploy this system')
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          this.systemListService.deploySystem(this.routerId.toString()).subscribe(res => {
            this.spinner.hide();
            if (res === true) {
              this.isDeployedFlag = true
              let system = this.systemList.find((item) => item.id == this.selectedSystemId)
              if(system) {
                system.deployed = true
              }
              this.ToastMessage('System deployment has been completed. Presentation layer data is ready to use.', 'bg-success');
              this.getArtifacts();
            } else {
              this.ToastMessage('System Deployment is failed', 'bg-danger');
              this.spinner.hide();
            }
          },
            (() => {
              this.spinner.hide();
          }));
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case SystemDataActions.Deploy:
        this.deployAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      case SystemDataActions.DownloadSystemArtifacts:
        this.verifyAccess(action,dataItem);
        break;
      case SystemDataActions.CopySystem:
        this.canshowCopySystem = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      case SystemDataActions.TestSystem:
        this.testAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }

  public verifyAccess(action: string, dataItem: number = -1){
    if(!this.useraccess.actions) {
      this.router.navigate(['component-list']);
      return
    }
    let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
    if(isAdmin != undefined){
      this.downloadAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
    }else{
          //System User or System Owner or System Read only
        let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
        let isSystemAccess = this.useraccess.actions.roles.find(x => x.id == 2 || x.id == 3 || x.id == 8);
        let isSystemMatch = this.useraccess.actions.systems.find(x => x.systemId === dataItem )
        if (isSystemAccess != undefined && isSystemMatch) {
          this.downloadAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        }
        else{
          if (isGlobalRead != undefined) {
            this.downloadAccess = this.useraccess.getUserAccess('GlobalReadOnly', action, dataItem !== -1 ? +dataItem : -1);
          }
        }
    }
  }

  public openDialogForm() {
    const dialogRef = this.dialogService.open({
      content: ArtifactsComponent
    });

    const userInfo: ArtifactsComponent = dialogRef.content.instance;
    if (this.deployArtifacts != null) {
      userInfo.deploy = this.deployArtifacts;
    }
    if (this.testArtifacts != null) {
      userInfo.test = this.testArtifacts;
    }
    dialogRef.result.subscribe(r => {

    });
  }

  public openCopySystemForm (){
    let userSystems = this.getUserSystems(this.useraccess.actions);
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg',
    };
    const modalRef = this.modalService.open(CopySystemComponent,ngbModalOptions);
    modalRef.componentInstance.my_modal_title = 'Copy System';
    modalRef.componentInstance.isAdmin = this.checkUserAdmin(this.useraccess.actions);
    modalRef.componentInstance.systemList = userSystems;
    modalRef.componentInstance.submitFormData.subscribe((systemDetails) => {
      this.spinner.show();
      this.systemListService.copySystem(systemDetails.sourceSystemId , systemDetails.destinationSystemId).subscribe((response) => {
        if (response !== null) {
          modalRef.close();
          this.ToastMessage('System Copied Successfully.', 'bg-success');
          this.spinner.hide();
          this.commonService.updateFeatureListAction();
        }
        else{
          modalRef.close();
          this.ToastMessage('System Copy is  failed.', 'bg-danger');
          this.spinner.hide();
        }      
      }, (errr) => {
        this.spinner.hide();
        modalRef.close();
      });
    });
  }

  filterSystems(systems){
    if (systems.length > 0) {
      const copySystemsArray: any[] = [];
      for(let j=0; j<systems.length; j++){
        systems[j].actions.find((item) => {
            if (item.actionName === "CopySystemData") {
                copySystemsArray.push(systems[j]);
            }
          });
        }
      return copySystemsArray;
    }
    else{
      this.ToastMessage('No Systems.', 'bg-danger');
    }
  }

  getUserSystems(userDetails){
    if(userDetails === undefined)
      this.ToastMessage('No Systems.', 'bg-danger');
    else{
      let isAdmin = this.checkUserAdmin(userDetails)
      if(isAdmin){
        return this.systemList;
      }else{
        let userSystems = this.filterSystems(userDetails.systems);
        if (userSystems.length > 0) {
          return userSystems;
        }else{
          this.ToastMessage('No Systems.', 'bg-danger');
        }
      }
    }
  }

  checkUserAdmin(details){
    let state = false;
    // Not specific component/system
    if(details === undefined)
      return false;
    let roles = details.roles;
    let isfind = details.roles.find(x => x.id === 1)
    if (isfind !== undefined) {
      state = true;
    }  
    return state;  
  }

  testSystem() {
    this.spinner.show();
    this.systemListService.testSystem(this.selectedSystemId).subscribe(res => {
      if (res === true) {
        this.spinner.hide();
        this.ToastMessage('System test artifacts has been generated successfully.', 'bg-success');
        this.getArtifacts();
      } else {
        this.ToastMessage('System test is failed', 'bg-danger');
        this.spinner.hide();
      }
    },() => {
        this.spinner.hide();
      });
  }

  activeNotification: boolean = false;
  toggleText() {
    if(this.activeNotification) {
      return
    }
    this.fetchDeploymentDetails()
  }

  async fetchDeploymentDetails() {
    try {
      this.spinner.show()
      let details = await this.systemListService.fetchSystemDeployedDetails(this.selectedSystemId).toPromise()
      if(details) {
        let content = `Last Deployed On: ${this.datePipe.transform(details.deployedOn, 'medium')} | Last Deployed By: ${details.deployedBy}`
        this.showNotification(content)
      }
      this.spinner.hide()
    } catch(e) {
      this.spinner.hide()
    }
  }

  showNotification(content: string) {
    this.activeNotification = true
    this.notificationService.show({
      content: content,
      appendTo: this.container,
      animation: { type: "slide", duration: 300 },
      position: { horizontal: "center", vertical: "top" },
      type: { style: "info", icon: false },
    });
    setTimeout(() => {
      this.activeNotification = false
    }, 3000);
  }
}

export enum SystemDataActions {
  Deploy = 'DeploySystem',
  DownloadSystemArtifacts = 'DownloadSystemArtifacts',
  CopySystem = 'CopySystemData',
  TestSystem = 'TestSystem'
}


export interface Artifacts {
  files: FileDetails[]
  recordCount: number;
  type: string;
  sasToken: string;
  deploymentDate: string;
  testDate: string;
}

export interface FileDetails {
  fileName: string;
  fileUrl: string;
  createdDate: string;
  modificationDate: string;
}
