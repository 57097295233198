import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { faSearch, faTrash, faEdit, faPlus, faFileImport, faTimes, faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { FeatureService } from '../../services/system/feature.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeatureListHeader } from 'src/app/global/app-enums.enum';
import { FeatureListingComponent } from 'src/app/system/feature-listing/feature-listing.component';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ModelPopupComponent } from '../../common/model-popup/model-popup.component';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';
import { CreateFeatureListingComponent } from '../../system/create-feature-listing/create-feature-listing.component';
import { CommonService } from 'src/app/services/common.service';
import { UserAccess } from 'src/app/global/user-access';
import { Subject, Subscription } from 'rxjs';
import { SystemListService } from 'src/app/services/system/system-list.service';
import { SystemModel } from 'src/app/models/system/system.data.model';
import { DropDownEvent } from 'src/app/common/copy-feature-modal/copy-feature-modal.component';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements AfterViewInit, OnInit {

  public gridViewData: any = [{}];
  recordCount: number;
  faSearch = faSearch;
  faFileImport = faFileImport;
  featureFrom: UntypedFormGroup;
  systemImport: UntypedFormGroup;
  submitted = false;
  selectedFeatureData = [];
  featureId: number;
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faTimes = faTimes;
  faLink = faLink;
  faCopy = faCopy;
  pageSize = 10;
  ImportFeature: UntypedFormGroup;
  globalFeatureId: number;
  closeResult: string;
  featureListingControlTypesArr: any = [];
  featureListingViewArr: any = [];
  selectedcontentType: any = '';
  featureWidgetListDataArr: any = [];
  selectedWidgetTyp = '';
  selectedWidgetTypeName = '';
  selectedWidgetRemainSize: number;
  selectedWidgetSize: number;
  selectedView: any;
  text: string;
  addFeatureAccess = false;
  deleteFeatureAccess = false;
  importFeatureAccess = false;
  importFeatureListAccess = false;
  updateFeatureAccess = false;
  openFeatureMenu = false;
  featureMenuId = 2147483647;
  private featureNameSetSubscription: Subscription;
  featureListHeaders = [
              {
                key: 'ControlType',
                value : FeatureListHeader.ControlType
              },
              {
                key: 'deviceId',
                value : FeatureListHeader.ComponentId
              },
              {
                key: 'Widget',
                value : FeatureListHeader.Widget
              },
              {
                key: 'Order',
                value : FeatureListHeader.Order
              },
              {
                key: 'View',
                value : FeatureListHeader.View
              },
              {
                key: 'Data1',
                value : FeatureListHeader.Data1
              },
              {
                key: 'Data2',
                value : FeatureListHeader.Data2
              },
              {
                key: 'Data3',
                value : FeatureListHeader.Data3
              },
              {
                key: 'Data4',
                value : FeatureListHeader.Data4
              }
    ];
  formTemplate = [
    {
      type: 'text',
      label: 'Title',
      formName: 'name',
      value: ''
    },
    {
      type: 'textarea',
      label: 'Description',
      formName: 'description',
      value: ''
    }
  ];
  formValidation = {
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
      description : ['', Validators.required]
    };
  headerData = {
                  pageSize: '10',
                  pageIndex: '1',
                  sortorder: 'Ascending',
                  sortcolumname: 'Id'
                };

  getFeatureIdEvent: Subject<void> = new Subject<void>();
  getFeatureDBIdEvent: Subject<void> = new Subject<void>();
  @ViewChild('FeatureListTable' , { read: FeatureListingComponent }) FeatureListTable: FeatureListingComponent;
  destinationList: SystemModel[] = [];
  sourceList : SystemModel[] = [];
  selectedDestId: number;
  selectedSourceId: number;
  selectedFeatureId: number;
  featuresData = [];
  copybtnState = true
  copyFeatureErrorMessage = ''
  titles = ['Destination System*', 'Source System*', 'Source System Feature*']
  constructor(private formBuilder: UntypedFormBuilder,
              private featureService: FeatureService,
              private router: ActivatedRoute,
              private toastService: ToastService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private confirmationDialogService: DialogboxService,
              private commonService: CommonService,
              private useraccess: UserAccess,
              public systemListService: SystemListService,
              ) {
                this.GetFeatureListingMasterData();
                this.GetFeatureDataWidgetList();
                this.initForm();
              }

  ngOnInit() {
    this.featureService.featureMenuId = 2147483647;
    this.ImportFeature = this.formBuilder.group({
      formFile: ['', Validators.required]
    });

    this.systemImport = this.formBuilder.group({
      featureFile: ['', Validators.required]
    });

    this.featureNameSetSubscription = this.featureService.currentFeatureName.subscribe((msg) => { 
      this.text = msg.replace(/[\n\r]+/g, '');
    });
    this.gridViewData = [{}];
    this.recordCount = 0;
    this.router.paramMap.subscribe(params => {
      if (this.router.snapshot.paramMap.get( 'id') != null) {
        this.initForm();
        let systemid = +this.router.snapshot.paramMap.get( 'id');
        setTimeout(()=> {
          this.spinner.show();
          this.featureService.getAllFeaturelist(this.router.snapshot.paramMap.get( 'id')).subscribe( (listRes) => {
            this.selectedFeatureData = listRes.features;
            if (this.selectedFeatureData.length > 0 ) {
              this.featureId = this.selectedFeatureData[0].featureId;
              this.globalFeatureId = this.selectedFeatureData[0].id;
              this.onFeatureSelect(this.featureService.getFeatureServiceId ? this.findFeatureId() : this.featureId);
              if( this.commonService.selectedFeature) {
                this.text =  this.commonService.selectedFeature.featureName.replace(/[\n\r]+/g, '');
                this.commonService.selectedFeature = undefined
              }
              let systemid = +this.router.snapshot.paramMap.get( 'id');
              this.checkAccess(FeatureaActions.DeleteFeature, systemid);
              this.checkAccess(FeatureaActions.UpdateFeature, systemid);
            }
            else{
              this.spinner.hide();
              this.featureService.getFeatureServiceId = null;
              this.getFeatureIdEvent.next();
            } 
          }, (error) => {
            this.globalFeatureId = 0;
            this.spinner.hide();
          });
          this.checkAccess(FeatureaActions.AddFeature, systemid); 
          this.checkAccess(FeatureaActions.ImportFeature, systemid);
          this.checkAccess(FeatureaActions.ImportFeatureListing, systemid);
        }, 1000)
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
    });
    //Bug-659808
    this.commonService.updateFeatureListObservable().subscribe((status) => {
        this.getFeatureData();
    })
  }
  initForm() {
    this.featureFrom = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(22)]],
      description: [null, Validators.required]
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    });
  }

  findFeatureId() {
    let item = this.selectedFeatureData.find(feature => +feature.featureId === this.featureService.getFeatureServiceId)
    if(item === undefined) {
      this.featureService.getFeatureServiceId =  this.selectedFeatureData[0].featureId;
    }      
    return this.featureService.getFeatureServiceId;
  }

  onSelectedFIle(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.systemImport.get('featureFile').setValue(file);
      this.onSubmit();
    }
  }
  onSubmit() {
    const formData = new FormData();
    formData.append('formFile', this.systemImport.get('featureFile').value);
    this.spinner.show();
    this.featureService.importFeatureExcelFile(formData, this.router.snapshot.paramMap.get( 'id')).subscribe((res) => {
      if (res === true) {
        //After File Creation the first Item should be selected by default
        this.featureId = 0;
        this.getFeatureData();
        this.ToastMessage('Feature imported successfully', 'bg-success');
        this.spinner.hide();

      } else {
        this.ToastMessage('Feature import failed', 'bg-danger');
      }
    }, () => {
      this.spinner.hide();
    });
  }

  getFeatureData() {
    this.spinner.show();
    this.featureService.getAllFeaturelist(this.router.snapshot.paramMap.get( 'id')).subscribe( (listRes) => {
      if (listRes.features.length > 0) {
        this.selectedFeatureData = listRes.features;
        if (this.featureId === null || this.featureId == 0) {
            this.featureId = this.selectedFeatureData[0].featureId;
            this.globalFeatureId = this.selectedFeatureData[0].id;
        }
        this.onFeatureSelect(this.featureId);
        this.spinner.hide();
        if (this.globalFeatureId != null) {
        }
      } else {
        this.spinner.hide();
      }
    }, () => {
      this.spinner.hide();
    });
  }
  onFeatureSubmit() {
    this.submitted = true;
    if (this.featureFrom.invalid) {
      return;
    }
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
    classname: type,
    delay: 3000 ,
    autohide: true,
    headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }

  onFeatureSelect(featureId) {
    this.featureService.getFeatureServiceId = featureId;
    let feature = this.selectedFeatureData.find(feature => +feature.featureId === +featureId)
    if(!feature) {
      this.spinner.hide()
      return
    }
    this.getFeatureIdEvent.next();
    this.submitted = false;
    if (featureId !== null) {
      try {
      this.featureService.getFeatureDataByFeatureIdService(this.headerData,
        this.router.snapshot.paramMap.get('id'), featureId).subscribe((listRes) => {
          if (listRes !== null ) {
            const featureFormData = {
              name: listRes.name,
              description: listRes.description
            };
            this.featureId = featureId;
            this.globalFeatureId = listRes.id;
            this.featureService.getFeatureDBServiceId = listRes.id;
            this.getFeatureDBIdEvent.next();
            this.featureFrom.patchValue(featureFormData);
            this.featureMenuId = listRes.menuId;
          } 
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
        });
    } 
  catch(e) {
    console.log(e)
  }
}
  }

  onPageChanged(pageData: { pageIndex: number }) {
    this.headerData.pageIndex = pageData.pageIndex.toString();
  }

  onFeatureFileSelected(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ImportFeature.get('formFile').setValue(file);
      const formData = new FormData();
      this.submitted = false;
      formData.append('formFile', this.ImportFeature.get('formFile').value);
      this.spinner.show();
      this.featureService.importFeatureListingExcelFile(formData, this.router.snapshot.paramMap.get( 'id')).subscribe((res) => {
        if (res === true) {
          this.getFeatureData();
          this.spinner.hide();
          this.ToastMessage('Feature Listing imported successfully', 'bg-success');
        } else {
          this.ToastMessage('Feature Listing import failed', 'bg-danger');
        }
      }, () => {
        this.spinner.hide();
      });
    }
  }
  open() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const modalRef = this.modalService.open(ModelPopupComponent,ngbModalOptions);
    modalRef.componentInstance.form_template = this.formTemplate;
    modalRef.componentInstance.my_modal_title = 'Add Feature';
    modalRef.componentInstance.my_modal_content = 'Add Feature';
    modalRef.componentInstance.form_validation = this.formValidation;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.CreateFeature(result, this.router.snapshot.paramMap.get('id')).subscribe((res) => {
        if (res !== null) {
          this.ToastMessage('Feature Created Successfully.', 'bg-success');
          this.featureId = Number(res)
          this.getFeatureData();
          // this.onFeatureSelect(res);
        }
      }, (error) => {
        this.spinner.hide();
      });
    });
  }
  editFeature(id) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.formTemplate[0].value = this.featureFrom.get('name').value;
    this.formTemplate[1].value = this.featureFrom.get('description').value;
    const modalRef = this.modalService.open(ModelPopupComponent,ngbModalOptions);
    modalRef.componentInstance.form_template = this.formTemplate;
    modalRef.componentInstance.my_modal_title = 'Edit Feature';
    modalRef.componentInstance.my_modal_content = 'Edit Feature';
    modalRef.componentInstance.form_validation = this.formValidation;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.editFeature(this.globalFeatureId, result, this.router.snapshot.paramMap.get('id')).subscribe((res) => {
        if (res === true) {
          this.ToastMessage('Feature Updated Successfully.', 'bg-success');
          this.getFeatureData();
          // this.onFeatureSelect(id);
        }
      }, (error) => {
        this.spinner.hide();
      });
    });
  }
  public openConfirmationDialog() {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
    .then((confirmed) => {
      if (confirmed === true) {
        this.spinner.show();
        this.featureService.deleteFeature(this.globalFeatureId).subscribe((res) => {
          if (res !== null) {
            this.featureFrom.reset();
            this.selectedFeatureData = [];
            //After File deletion the first Item should be selected by default
            this.featureId = 0;
            this.getFeatureData();
            this.globalFeatureId = null;
            this.spinner.hide();
            this.ToastMessage('Feature Deleted Successfully.', 'bg-success');
          }
        }, (error) => {
          this.spinner.hide();
        });
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  openFeatureListing() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
    };
    const modalRef = this.modalService.open(CreateFeatureListingComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Add ';
    modalRef.componentInstance.featureListingControlTypes = this.featureListingControlTypesArr;
    modalRef.componentInstance.featureListingView = this.featureListingViewArr;
    modalRef.componentInstance.selectedcontentType = this.selectedcontentType;
    modalRef.componentInstance.selectedView = this.selectedView;
    modalRef.componentInstance.featureWidgetListData = this.featureWidgetListDataArr;
    modalRef.componentInstance.selectedWidgetType = this.selectedWidgetTyp;
    modalRef.componentInstance.selectedWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.selectedWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.defaultWidgetType = this.selectedWidgetTyp;
    modalRef.componentInstance.defaultWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.defaultWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.CreateFeatureList(result, this.globalFeatureId).subscribe((res) => {
        if (res !== null) {
          modalRef.close();
          this.ToastMessage('Feature List Created Successfully.', 'bg-success');
          this.spinner.hide();
        }
      }, (error) => {
      this.spinner.hide();
    });
    });
  }

  GetFeatureListingMasterData() {
    this.featureService.GetFeatureListingMasterData().subscribe((data) => {
      if (data !== null) {
        this.featureListingControlTypesArr = data.featureListingControlTypes;
        this.featureListingViewArr = data.featureListingView;
        this.selectedcontentType = data.featureListingControlTypes[0].id;
        this.selectedView = data.featureListingView[0].id;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  GetFeatureDataWidgetList() {
    this.featureService.GetFeatureDataWidgetList().subscribe((data) => {
      if (data !== null) {
        this.featureWidgetListDataArr = data;
        this.selectedWidgetTyp = data[0].id;
        this.selectedWidgetTypeName = data[0].name;
        this.selectedWidgetSize = data[0].size;
        this.selectedWidgetRemainSize = data[0].remainingSize;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }
  searchClear() {
    this.text = null;
    this.featureService.attachedFetureName('','');
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch(action) {
      case FeatureaActions.AddFeature:
        let state = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        console.log(`State ${state}`)
        this.addFeatureAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      case FeatureaActions.DeleteFeature:
        this.deleteFeatureAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      case FeatureaActions.ImportFeature:
          this.importFeatureAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
          break;
      case FeatureaActions.ImportFeatureListing:
          this.importFeatureListAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
          break;
      case FeatureaActions.UpdateFeature:
            this.updateFeatureAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
          break;
      default:
        break;
    }
  }
  featureOnClick() {
    document.getElementById('1').click();
    this.featureService.goToFeatureMenuId(this.featureMenuId);
  }

  copyFeatureFromSystem($event: Event) {
    this.openFeatureMenu = true
    let selectedId = this.systemListService.selectedSystemId;
    this.destinationList.push(this.systemListService.systemList.find(system => system.id === selectedId))
    this.selectedDestId = selectedId
    this.sourceList = this.systemListService.systemList
  }


   refreshFesturesList($event: DropDownEvent) {
    if($event.type === 'system') {
      this.spinner.show();
      this.copybtnState = true
      this.featuresData = []
      this.selectedSourceId = $event.id
      this.featureService.getAllFeaturelist($event.id.toString()).subscribe((res) => {
        this.spinner.hide();
        if (res.features.length > 0) {
          this.featuresData = res.features;
        }
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.copybtnState = false
      this.selectedFeatureId =  $event.id
    }
  }

  closeFeatureMenuModal($event: Event) {
    this.openFeatureMenu = false
    this.featuresData = [] 
    this.copybtnState = true
  }

  copyFeaturesSystem($event: any) {
    this.spinner.show();
    this.copyFeatureErrorMessage = ''
    let data = {
      sourceSystemId: this.selectedSourceId, targetSystemId: this.selectedDestId, featureId: this.selectedFeatureId
    }
    this.featureService.copyFeatureDataByFeatureId(this.selectedFeatureId, this.selectedSourceId, this.selectedDestId).subscribe((res) => {
        this.spinner.hide();
        this.copybtnState = true
        this.featuresData = [] 
        this.openFeatureMenu = false
        this.ToastMessage('Feature copied successfully', 'bg-success');
        this.getFeatureData();
      }, (error) => {
        this.spinner.hide();
        this.copybtnState = false
        this.copyFeatureErrorMessage = error
    })
  }

  ngOnDestroy() {
    this.featureService.attachedFetureName('','');
    this.featureNameSetSubscription.unsubscribe()
  }
  
}

export enum FeatureaActions {
 AddFeature = 'AddFeature',
 DeleteFeature = 'DeleteFeature',
 ImportFeature = 'ImportFeature',
 ImportFeatureListing = 'ImportFeatureListing',
 UpdateFeature = 'UpdateFeature'
}
