export enum ComponentListHeader {
  Name = 'Name',
  Id = 'ID',
  Scn = 'SCN',
  Actions = 'Actions',
  Regions = 'Regions',
  remTypeIdentifier = 'REM ID'
}
export enum SystemListHeader {
  Name = 'Name',
  Description = 'Description',
  ControllerId = "Controller Id",
  Actions = 'Actions'
}
export enum commandArgumentHeader {
  displayText = 'Text',
  description = 'Description',
  dataPosition = 'Data Position',
  dataType = 'Data Type',
  count = 'Count',
  maxValue = 'Max Value',
  minValue = 'Min Value',
  details = 'Details',
  Actions = 'Actions'
}

export enum responseHeader {
  responseCode = 'Response Code',
  Text = 'Text',
  description = 'Description',
  Actions = 'Actions'
}

export enum FeatureListHeader {
  ControlType = 'Control Type',
  ComponentId = 'Component Id',
  Widget = 'Widget',
  Order = 'Order',
  Column = 'Column',
  View = 'View',
  Data1 = 'Data1',
  Data2 = 'Data2',
  Data3 = 'Data3',
  Data4 = 'Data4'
}
export enum DataWidgetListHeader {
  InputNumber = 'Input Number',
  DataIdentifier = 'Data Identifier'
}
export enum EnumDataTypeHeader {
  Name = 'Name',
  Description = 'Description',
  Actions = 'Actions'
}

export enum SideNavMenuItems {
  ComponentList = 'Component List',
  ComponentData = 'Component Data',
  SystemList = 'System List',
  SystemData = 'System Data',
  PhoneAdmin = 'Phone Administrator',
  UserManagement = 'User Management',
  AuditLog = 'Audit Log',
  ChangeLog = 'Version Changes'
}

export enum ResourceActions {
  GetResources = "GetComponentResourceList",
  DeleteResources = 'DeleteComponentResource'
}

export enum ComponentDataActions {
  Deploy = 'Deploy',
  DownloadArtifacts = 'DownloadTestArtifacts',
  AddVersion = 'AddVersion',
  Test = 'Test',
  GetBaseVersionComponents = 'GetBaseVersionComponents'
}


export enum SRDODataActions {
  AddSRDO = 'Add',
  UpdateSRDO = 'Update',
  DeleteSRDO = 'Delete',
  import = 'ImportSRDO',
}

export enum ChangeType {
  ChangeComponent = 1,
  ChangeVersion,
  Staging,
  Deploying
}

  /**
   * Helper function
   * @param obj
   * @param oldKey
   * @param newKey
   */
  export function renameKey(obj: any, oldKey: string, newKey: string) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
