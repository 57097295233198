import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { UserAccess } from "./user-access";
import { of } from "rxjs";
import { catchError } from "rxjs/Operators";

export const RolesResolver : ResolveFn<boolean> = (route, state) => {
    // const gameId = route.paramMap.get('id');
    let username = inject(MsalService).instance.getActiveAccount().username;
    const headerData = { userEmailId: username };
    let userAccess = inject(UserAccess)
    if(userAccess.actions && userAccess.actions.roles) {
      return of(true);
    }
    return userAccess.getUserClaims(headerData).pipe(
      catchError(error => {
        return of(false);
      })
    );
}