import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { menuModel, addMenuModel, menuReorder, CommonMenuModel } from '../../models/system/feature/feature.module';
import { Observable } from 'rxjs';
import { CommonMenu, MenuDetailsById, MenuTitle } from 'src/app/models/component/component.data.model';

@Injectable({
  providedIn: "root",
})
export class MenuService {
  constructor(private http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  /**
   * Menu Excel import file
   * param { system Id} headerData
   * memberof ImportMenuFile
   */
  importMenuExcelFile(data, systemId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId,
      }),
    };
    return this.http.post(
      getServiceUrl(AppService.import),
      data,
      this.httpOptions
    );
  }
  getMenuHeirarchy(systemId) : Observable<CommonMenu[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId,
      }),
    };
    return this.http.get<CommonMenu[]>(
      getServiceUrl(AppService.GetMenuHierarchy),
      this.httpOptions
    );
  }
  getMenuBasesOnMenuId(menuDetails): Observable<menuModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        menuId: menuDetails.selectedMenuID,
        systemid: menuDetails.systemId,
      }),
    };
    return this.http.get<menuModel>(
      getServiceUrl(AppService.MenuById),
      httpOptions
    );
  }
  /**
   * Menu swap
   * param { sourceMenuId, DestinationId, currentMenuId} headerData
   * memberof SwapPosition
   */
  getMenuReOrdering(menuDetails): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        sourceMenuId: menuDetails.sourceMenuId,
        destinationParentMenuId: menuDetails.destinationParentMenuId,
        currentPostionMenuId: menuDetails.currentPostionMenuId.toString(),
      }),
    };
    return this.http.post(
      getServiceUrl(AppService.ChangePosition),
      menuDetails,
      httpOptions
    );
  }
  /**
   * add menu
   * param { system Id} headerData
   * memberof addMenu
   */
  addMenuData(data, systemId): Observable<addMenuModel> {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId,
      }),
    };
    data.createdBy = "test@gmail.com";
    return this.http.post<addMenuModel>(
      getServiceUrl(AppService.MenuAdd),
      data,
      this.httpOptions
    );
  }
  /**
   * delete menu
   * param { system Id} headerData
   * memberof deleteMenu
   */
  deleteMenu(data): Observable<any> {
    if (data.deleteMenuChildren) {
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          deleteMenuChildren: data.deleteMenuChildren.toString(),
          deleteLinkedFeatures: data.deleteLinkedFeatures.toString(),
        }),
      };
      return this.http.delete(
        getServiceUrl(AppService.DeleteMenu, data.Id),
        httpOptions
      );
    } else {
      return this.http.delete(
        getServiceUrl(AppService.DeleteMenu, data.Id),
        this.httpOptions
      );
    }
  }
  /**
   * update menu
   * param { system Id} headerData
   * memberof updateMenu
   */
  updateMenu(data, systemId): Observable<any> {
    data.modifiedBy = "test@gmail.com";
    return this.http.put(
      getServiceUrl(AppService.updateMenu),
      data,
      this.httpOptions
    );
  }

  /**
   * unlink feature menu
   * param { system Id, Menu Id} headerData
   */
  unlinkFeature(mId, sid): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        systemid: sid.toString(),
        menuId: mId.toString(),
      }),
    };
    return this.http.post<any>(
      getServiceUrl(AppService.RemoveFeatureLink),
      sid,
      httpOptions
    );
  }

  /**
   * Copy System Menu
   * param { source system id, source menu id, target system id, target menu id, menu position} headerData
   */
  copyMenuSystem(
    sourceSystemId: number,
    sourceMenuId: number,
    targetSystemId: number,
    targetMenuId: number,
    menuPosition: string,
    isCopyLinkedDataRequired: boolean
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        sourceSystemId: sourceSystemId.toString(),
        sourceMenuId: sourceMenuId.toString(),
        targetSystemId: targetSystemId.toString(),
        targetMenuId: targetMenuId.toString(),
        menuPosition: menuPosition,
        isCopyLinkedDataRequired: isCopyLinkedDataRequired.toString(),
      }),
    };
    return this.http.post<any>(
      getServiceUrl(AppService.CopyMenu),
      null,
      httpOptions
    );
  }

  /*
   * Get Component Id and Version Id of a feature listing item
   * GetComponentDataForNavigation
   */
  componentDataForNavigationFromMenu(menuId: Number, isTemplateMenu: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        menuId: menuId.toString(),
        isTemplateMenu: isTemplateMenu
      }),
    };
    return this.http.get<Number>(
      getServiceUrl(AppService.GetComponentNavigationIdForMenu),
      this.httpOptions
    );
  }

  /**
   * Menu Excel import file
   * param { system Id} headerData
   * memberof ImportMenuFile
   */
  importCommonMenu(componentId: string, titleId: number, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
        titleId: titleId?.toString(),
      }),
    };
    return this.http.post(
      getServiceUrl(AppService.ImportCommonMenu),
      data,
      httpOptions
    );
  }

  /*
      Returns Common Menu Data
    */
  getCommonMenuHeirarchy(
    componentId: string,
    titleId: number
  ): Observable<CommonMenu[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
        titleId: titleId?.toString(),
      }),
    };
    return this.http.get<CommonMenu[]>(
      getServiceUrl(AppService.GetCommonMenuHierarchy),
      httpOptions
    );
  }

  /* Create Title of a common menu */
  createTitle(componentId: string, data: any): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.AddCommonMenuTitle),
      data,
      httpOptions
    );
  }

  /* Update title of a system template */
  updateTitle(componentId: string, data: any): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.UpdateCommonMenuTitle),
      data,
      httpOptions
    );
  }


  /* Create Title of a common menu */
  getMenuTitles(componentId: string): Observable<MenuTitle[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
      }),
    };
    return this.http.get<MenuTitle[]>(
      getServiceUrl(AppService.GetCommonMenuTitleList),
      httpOptions
    );
  }

  getCommonMenuById(menuDetails: MenuDetailsById): Observable<CommonMenuModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        componentId: menuDetails.componentId,
        titleId: menuDetails.titleId,
        commonMenuId: menuDetails.commonMenuId,
      }),
    };
    return this.http.get<CommonMenuModel>(
      getServiceUrl(AppService.GetCommonMenuById),
      httpOptions
    );
  }

  /**
   * add menu
   * param { system Id} headerData
   * memberof addMenu
   */
  addCommonMenuData(
    data,
    componentId: number,
    titleId: number
  ): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
      }),
    };
    data.createdBy = "test@gmail.com";
    return this.http.post<number>(
      getServiceUrl(AppService.AddCommonMenu),
      data,
      httpOptions
    );
  }

  /**
   * add menu
   * param { system Id} headerData
   * memberof addMenu
   */
  editCommonMenuData(
    data,
    componentId: number,
    titleId: number
  ): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
      }),
    };
    data.createdBy = "test@gmail.com";
    return this.http.post<number>(
      getServiceUrl(AppService.EditCommonMenu),
      data,
      httpOptions
    );
  }

  deleteCommonMenu(componentId: number, titleId: number, menuId: number, deleteLinkedFeatures: boolean) {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
        menuId: menuId.toString(),
        deleteMenuChildren: 'true',
        deleteLinkedFeatures: deleteLinkedFeatures.toString(),
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.DeleteCommonMenu),
      null,
      httpOptions
    );
  }

  /**
   * Link common menu data
   * @param componentId
   * @param titleId
   * @param targetMenuId
   * @param systemId
   * @returns
   */
  linkCommonMenuData(
    componentId: number,
    titleId: number,
    targetMenuId: number,
    systemId: number
  ): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
        systemId: systemId.toString(),
        targetMenuId: targetMenuId.toString(),
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.LinkCommonMenuToSystemLayer),
      null,
      httpOptions
    );
  }

  /**
   * Link common menu data
   * @param componentId
   * @param titleId
   * @param targetMenuId
   * @param systemId
   * @returns
   */
  unlinkCommonMenuData(
    componentId: number,
    titleId: number,
    systemId: number
  ): Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
        systemId: systemId.toString(),
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.DelinkCommonMenuFromSystemLayer),
      null,
      httpOptions
    );
  }

  /**
   * Copy system menu to common menu
   * @param componentId 
   * @param titleId 
   * @param targetMenuId 
   * @param systemId 
   * @param sourceMenuId 
   * @returns 
   */
  copySystemMenuToCommonMenu(componentId: number, titleId: number, targetMenuId: number, 
    systemId: number, sourceMenuId: number, isCopyLinkedDataRequired: boolean ) {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        titleId: titleId.toString(),
        systemId: systemId.toString(),
        targetMenuId: targetMenuId.toString(),
        sourceMenuId: sourceMenuId.toString(),
        isCopyLinkedDataRequired: isCopyLinkedDataRequired.toString()
      }),
    };
    return this.http.post<number>(
      getServiceUrl(AppService.CopySystemMenuToComponentLayer),
      null,
      httpOptions
    );
  }

  /**
   * unlink feature menu
   * param { system Id, Menu Id} headerData
   */
  unlinkCommonFeature(menuId: number): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        menuId: menuId.toString(),
      }),
    };
    return this.http.post<boolean>(
      getServiceUrl(AppService.RemoveCommonFeatureLink),
      null,
      httpOptions
    );
  }

  getCommonMenuReOrdering(sourceMenuId: number, targetParentMenuId: number, currentPostionMenuId: number): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        sourceMenuId: sourceMenuId,
        targetParentMenuId : targetParentMenuId,
        currentPostionMenuId: currentPostionMenuId,
      }),
    };
    return this.http.post<boolean>(
      getServiceUrl(AppService.RearrangeCommonMenuPosition),
      null,
      httpOptions
    );
  }
}
