import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ChangelogComponent } from './changelog.component';
import { RolesResolver } from '../global/rolesresolver';

const routes: Routes = [{ path: 'version-changes', canActivate: [MsalGuard], resolve: { roles: RolesResolver },component: ChangelogComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChangelogRoutingModule { }
