import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { faTrash, faEdit, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ModelPopupComponent } from '../../common/model-popup/model-popup.component';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SystemListHeader } from 'src/app/global/app-enums.enum';
import { SystemListService } from '../../services/system/system-list.service';
import {
  GridComponent,
  GridDataResult,
  PageChangeEvent
} from '@progress/kendo-angular-grid';
import { UserAccess } from 'src/app/global/user-access';
import { AssociatedRoles, Language, Region } from 'src/app/models/component/component.data.model';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-system-list',
  templateUrl: './system-list.component.html',
  styleUrls: ['./system-list.component.scss']
})
export class SystemListComponent implements AfterViewInit, OnInit, OnDestroy {
  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faUsers = faUsers;
  list: {}[]; // Table data
  regionsdata: Region[] = [];
  values: Region[] = [{ id: 1, name: 'All', description: 'All' }];
  newregions: Region[] = [];
  languagesData: Language[] = [];
  newlanguages: Language[] = [{ id: 1, name: 'English', code: 'en' }];
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  private data: Object[];
  public recordCount = 0;
  public filteredValue = '';
  private editedRowIndex: number;
  private isNew = false;
  public formGroup: UntypedFormGroup;
  public editId: 0;
  addSystemAccess = false;
  updateSystemAccess = false;
  deleteSystemAccess = false;
  showConfirmation = false;
  rowItem: any = {}
  title = ''
  opened = false
  userRoles: AssociatedRoles[] = []

 // For Angular 8
 @ViewChild(GridComponent, { static: true })
 public grid: GridComponent;

  headers = [
    {
      key: 'name',
      value : SystemListHeader.Name
    },
    {
      key: 'controllerId',
      value : SystemListHeader.ControllerId
    },
    {
      key : 'actions',
      value : SystemListHeader.Actions
    }
    ];

  headerData = { pageSize: this.pageSize.toString(), pageIndex: (this.skip + 1).toString(), sortorder: 'Descending', sortcolumname: 'CreatedOn' };
  isLoadingResults = false;
  languagesSubscription: Subscription;
  // tslint:disable-next-line: no-shadowed-variable
  constructor(private SystemListService: SystemListService, 
              private modalService: NgbModal,
              public toastService: ToastService , 
              private spinner: NgxSpinnerService, 
              private formBuilder: UntypedFormBuilder,
              private useraccess: UserAccess,
              private masterDataService: MasterDataService,
              private commonService: CommonService) { }


  ngOnDestroy(): void {
    this.languagesSubscription?.unsubscribe()
  }

  // component modal popup
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000 ,
      autohide: true,
      headertext: 'Success'
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getSystemList();
      this.getLocations();
      this.checkAccess(SystemActions.AddSystem);
    }, 1000);
  }

 
  ngOnInit() {
    this.languagesSubscription = this.commonService.languagesObservable$.subscribe((languages) => {
      if(languages) {
        this.languagesData = languages
      }
    })
  }


  //Filter SystemList by SystemName
  public filterChange(event) {
    this.filteredValue = '';
    this.filteredValue = event
    this.getSystemList();
  }

  getSystemList() {
    this.spinner.show();
    this.SystemListService.getSystemDataList(this.headerData, this.filteredValue).subscribe((data) => {
      this.spinner.hide();
      //this.table.collectionSize = data.recordCount;
      this.list = data.systems.map(system =>
        {
          return { id: system.id, name: system.name, controllerId: system.controllerId,
                  regions: system.regions.length === 0 ? [{ id: 1, name: 'All', description: 'All' }]: system.regions,
                  languages: (system.languages.length == 0) ? [{id: 1, name: "English", code: "en"}] : system.languages,
                  label: this.getItemLanguages(system.languages),
                  description: system.description, 
                  state: system.deployed,
                  isEditable: false
                } 
          }
      );
      delay: 100;
      this.gridView = {
        data: this.list.slice(0, this.pageSize),
        total: data.recordCount
      };
    },
    ((error) => {
      this.spinner.hide();
    }));
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    let field = 'CreatedOn'
    if(this.sort[0].dir) {
      field = this.sort[0].field
    }
    this.headerData = {...this.headerData, sortcolumname: field, sortorder: this.sort[0].dir === 'asc' ? 'Ascending' : 'Descending'}
    this.getSystemList();
  }

  getLocations() {
    this.spinner.show();
    this.masterDataService.getAllRegions().subscribe((regions) => {
      this.spinner.hide();
      if (regions !== null) {
        this.regionsdata = regions;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }
  
  onPageChanged(pageData: { pageIndex: number }) {
    this.headerData.pageIndex = pageData.pageIndex.toString();
    this.getSystemList();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.headerData.pageIndex = ((this.skip / this.pageSize) + 1).toString();
    this.getSystemList();
  }

  public removeHandler({ sender, dataItem }) {
    this.rowItem = { sender, dataItem }
    this.showConfirmation = true
  }

  public addHandler() {
    this.grid.addRow(this.createFormGroup({ name:'', controllerId: 1 }));
  }

  public onValueChange(value, from: string) {
    if(from == 'regions') {
      this.newregions = []
      this.newregions = value
    } else {
      this.newlanguages = []
      this.newlanguages = value
    }
 }

  public saveHandler({ sender, formGroup, rowIndex, isNew, dataItem}) {
      if (formGroup.valid) {
        if(isNew) {
            let value = formGroup.value;
            let items = [];
            if(this.newregions.length === 0){
              this.newregions = this.values;
            }
            this.newregions.forEach(function (item) {
             items.push(item.id);
            });

          let languages = [];
          if(this.newlanguages.length == 0) {
            this.newlanguages = [{ id: 1, name: 'English', code: 'en' }];
          }
          this.newlanguages.forEach(function (item) {
            languages.push(item.id);
           });
          value.regions = items;
          value.languages = languages
          this.newregions = [];
          this.newlanguages = [{ id: 1, name: 'English', code: 'en' }];
          value.createdBy= 'test@gmail.com';
          this.spinner.show();
          dataItem.isEditable = false;
          sender.closeRow(rowIndex);
          this.SystemListService.addSystem(value).subscribe((res) => {
            this.spinner.hide();
            if (res === true ) {
              this.ToastMessage('System created successfully.', 'bg-success');
              this.getSystemList();
            } else {
              const response = JSON.stringify(res);
              this.ToastMessage(res, 'bg-danger');
            }
          }, (error) => {
            this.spinner.hide();
          });
        }
        else {
          this.spinner.show();
          let items = [];
          dataItem.regions.forEach(function (item) {
           items.push(item.id);
          });
          let value = formGroup.value;
          value.regions = items;
          let languages = [];
          dataItem.languages.forEach(function (item) {
            languages.push(item.id);
          });
          value.languages = languages
          dataItem.isEditable = false;
          sender.closeRow(rowIndex);
          this.SystemListService.updateSystem(value, this.editId).subscribe((res) => {
            this.spinner.hide();
            if (res === true) {
              this.ToastMessage('System successfully updated.', 'bg-success');
              this.getSystemList();
            } else {
              this.ToastMessage('HTTP ERROR....', 'bg-danger');
              this.spinner.hide();
              this.getSystemList();
            }
          }, (error) => {
            this.spinner.hide();
            this.getSystemList();
          });
        }
    }
  }

  public cancelHandler({ sender, rowIndex, dataItem }) {
    dataItem.isEditable = false;
    const [first, ...rest] = this.newlanguages
    this.newlanguages = [first]
    sender.closeRow(rowIndex);
  }

  public editHandler({sender, rowIndex, dataItem}) {
    this.closeEditor(sender);
    dataItem.isEditable = true;
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.editId = dataItem.id;
    sender.editRow(rowIndex, this.formGroup);
}

  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formBuilder = undefined;
 }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      'name': new UntypedFormControl(dataItem.name, [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]{1,22}$")]),
      'controllerId': new UntypedFormControl(dataItem.controllerId, [Validators.required, 
        Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(255)]),
     });
  }

  public getItemRegions(reguins: Region[]): string {
    if(reguins === undefined) {
      return '';
    }
    let items = [];
    reguins.forEach(function (item) {
      items.push(item.name);
    });
    return items.toString();
  }

  public getItemLanguages(languages: Language[] = []): string {
    if(languages.length == 0) {
      return 'English';
    }
    let items = [];
    languages.forEach(function (item) {
      items.push(item.name);
    });
    return items.toString();
  }

  public checkAccess(action: string, dataItem: number = -1) {
    switch(action) {
      case SystemActions.AddSystem:
        this.addSystemAccess = this.useraccess.getUserAccess("systems", action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }

  public checkAccessForItem(action: string, dataItem: any = undefined): Boolean {
    var state = this.useraccess.getUserAccess("systems", action, dataItem !== undefined ? dataItem.id : -1);
    return state;
  }

   /*
   * name
  */
   deleteConfirmation(status: boolean) {
    this.showConfirmation = false
    if (status) {
      this.spinner.show();
      this.SystemListService.deleteSystemListData(this.rowItem.dataItem.id).subscribe((res) => {
        this.spinner.hide();
        if (res === true) {
          this.ToastMessage('System deleted Successfully.', 'bg-success');
          this.getSystemList();
        } else {
          this.ToastMessage('HTTP ERROR....', 'bg-danger');
        }
      }, (error) => {
        this.spinner.hide();
      });
      this.rowItem.sender.cancelCell();
    }
  }

  showAssociatedUsers(dataItem: any) {
    this.title = `${dataItem.name} - Users & Roles`
    this.spinner.show()
    this.userRoles = []
    this.SystemListService.fetchAssociatedUsers(dataItem.id).subscribe((users) => {
      this.spinner.hide()
      this.opened = true
      if(users && users.length > 0) {
        users.forEach((user)=> {
           let userRole = this.userRoles.find((obj) => user.emailAddress == obj.email)
            if(userRole) {
                let item = userRole
                item.roles.push(this.fetchRole(user.roleId))
            } else {
              let item = {email: user.emailAddress, roles: [this.fetchRole(user.roleId)]}
              this.userRoles.push(item)
            }
        })
      }
    }, (error) => {
      this.spinner.hide()
    });
  }

  fetchRole(roleId: number) {
    let roleName = ''
    switch (roleId) {
      case 1://Adminstartor
        roleName = 'Adminstartor';
        break;
      case 2://System Owner
        roleName = "System Owner";
        break;
      case 3://System User
        roleName = "System User";
        break;
      case 4://Component Owner
        roleName = "Component Owner";
        break;
      case 5: //Component User
        roleName = "Component User";
        break;
      case 6: //Phone Adminstrator
        roleName = "Phone Adminstrator";
        break;
      case 7: //Component Read Only
        roleName = "Component Read Only";
        break;
      case 8: //System Read Only
        roleName = "System Read Only";
        break;
      case 9: //Global Read Only
        roleName = "Global Read Only";
        break;
      default:
        break;
    }
    return roleName
  }

  
  close(state: string) {
    this.opened = false
  }

  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index == 0; // disable the 3rd item
  }

}

export enum SystemActions {
  AddSystem = 'AddSystem',
  UpdateSystem = 'UpdateSystem',
  DeleteSystem = 'DeleteSystem'
}
