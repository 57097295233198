import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LinkedFeatureSystem } from '../common/display-list-modal/display-list-modal.component';
import { UserAccess } from '../global/user-access';
import { Language, VersionData } from '../models/component/component.data.model';

@Injectable({
  providedIn: "root",
})
export class CommonService {
  
  private notify = new Subject<any>();
  private reverseAction = new Subject<any>();
  private updateFeatureListSubject = new Subject<any>();
  private selectMenuSubject = new Subject<any>();
  private reopenedVersionSubject = new Subject<any>();

  // Language Subject
  private languagesSubject = new BehaviorSubject([])
  // Language Observable
  languagesObservable$ = this.languagesSubject.asObservable() 
  public takenInput = false;
  public srdoChanges = false;
  public deployInstance = true;
  public isDeployedFlag = null;
  private _selectedFeature: LinkedFeatureSystem;
  get selectedFeature(): LinkedFeatureSystem {
    return this._selectedFeature;
  }
  set selectedFeature(value: LinkedFeatureSystem) {
    this._selectedFeature = value;
  }

  private _selectedSRDOCommand: any;
  get selectedSRDOCommand(): any {
    return this._selectedSRDOCommand;
  }
  set selectedSRDOCommand(value: any) {
    this._selectedSRDOCommand = value;
  }

  private _navigateToSystemTemplate: any;
  get navigateToSystemTemplate(): any {
    return this._navigateToSystemTemplate;
  }
  set navigateToSystemTemplate(value: any) {
    this._navigateToSystemTemplate = value;
  }

  private _isReopened = false;
  get isReopened(): boolean {
    return this._isReopened;
  }
  set isReopened(value: boolean) {
    this._isReopened = value;
  }

  private _isStaged = false;
  get isStaged(): boolean {
    return this._isStaged;
  }
  set isStaged(value: boolean) {
    this._isStaged = value;
  }

  private _versionChangeData: VersionData
  get versionChangeData(): VersionData {
    return this._versionChangeData;
  }
  set versionChangeData(value: VersionData) {
    this._versionChangeData = value;
  }

  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();

  constructor(private useraccess: UserAccess) {}

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  private _languages: Language[] = []
  public set languages(languages: Language[]) {
    if(languages) {
      this._languages = languages
      this.languagesSubject.next(languages);
    }
  }

  public get languages() : Language[] {
    return this._languages
  }

  sendMessage(message: string) {
    this.notify.next({ text: message });
  }

  onMessage(): Observable<any> {
    return this.notify.asObservable();
  }

  onRequest(): Observable<boolean> {
    return this.reverseAction.asObservable();
  }

  reverseRequest() {
    this.reverseAction.next(true);
  }

  selectMenuObservable(): Observable<any> {
    return this.selectMenuSubject.asObservable();
  }

  selectMenuAction() {
    this.selectMenuSubject.next();
  }

  reopenedVersionObservable(): Observable<any> {
    return this.reopenedVersionSubject.asObservable();
  }

  reopenedVersionAction() {
    this.reopenedVersionSubject.next(this._isReopened);
  }

  updateFeatureListObservable(): Observable<boolean> {
    return this.updateFeatureListSubject.asObservable();
  }

  updateFeatureListAction() {
    this.updateFeatureListSubject.next(true);
  }

  hasComponentAccess(componentName: string) {
    let isAdmin = this.useraccess.actions.roles.find((x) => x.id == 1);
    let isGlobalRead = this.useraccess.actions.roles.find((x) => x.id == 9);
    if (isAdmin != undefined || isGlobalRead != undefined) {
      return true;
    } else {
      let component = this.useraccess.actions.components.find((component) => {
        return component.componentName === componentName;
      });
      if (component) {
        return true;
      } else {
        return false;
      }
    }
  }

}
