import { Injectable } from '@angular/core';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataWidgetModel } from 'src/app/models/widget/data.widget.model';
import { MenuWidgetModel } from 'src/app/models/widget/menu.widget.model';
import { map } from 'rxjs/Operators';
@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private http: HttpClient) {
  }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Data widget List Data
   * returns {Observable<DataWidgetModel>}
   * memberof getDefaultDataWidgetList
   */
  getDataWidgetList(): Observable<DataWidgetModel[]> {
    const httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<DataWidgetModel[]>(getServiceUrl(AppService.DataWidgetList), this.httpOptions).pipe(
      map(messages => messages.sort((a1: MenuWidgetModel, a2: MenuWidgetModel) => {
          if(a1.name === "DefaultWidget" || a2.name === "DefaultWidget") return 0;
          if(a1.name.toLocaleLowerCase() < a2.name.toLocaleLowerCase()) return -1;
          if(a1.name.toLocaleLowerCase() > a2.name.toLocaleLowerCase()) return 1;
          return 0;
      }))
    );
  }

  /**
   * Data widget List Data
   * returns {Observable<DataWidgetModel>}
   * memberof getDefaultDataWidgetList
   */
  getMenuWidgetList(): Observable<MenuWidgetModel[]> {
    const httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<MenuWidgetModel[]>(getServiceUrl(AppService.MenuWidgetList), this.httpOptions).pipe(
      map(messages => messages.sort((a1: MenuWidgetModel, a2: MenuWidgetModel) => {
          if(a1.name === "DefaultMenu" || a2.name === "DefaultMenu") return 0;
          if(a1.name.toLocaleLowerCase() < a2.name.toLocaleLowerCase()) return -1;
          if(a1.name.toLocaleLowerCase() > a2.name.toLocaleLowerCase()) return 1;
          return 0;
      }))
    );
  }

 /**
   * Data widget based on widget id
   * returns {Observable<DataWidgetModel>}
   * memberof getDefaultDataWidgetList
   */

  WidgetDetailsById(id): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        widgetid : id
      })
     };
    return this.http.get<any>(getServiceUrl(AppService.WidgetDetailsById), this.httpOptions);
  }

  /**
   * Add Menu Widget
   * returns true
   * memberof WidgetService
   */
  addMenuWidget(data) {
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.AddMenuWidget), body, this.httpOptions);
  }
  
  /**
   * Update Menu Widget
   * returns true
   * memberof WidgetService
   */
  updateMenuWidget(data, id) {
    const WidgetData =  data;
    WidgetData.modifiedBy = 'test@gmail.com';
    WidgetData.id = id;
    const body = JSON.stringify(WidgetData);
    return this.http.put(getServiceUrl(AppService.UpdateMenuWidget), body, this.httpOptions);
  }

  
  /**
   * Delete Menu Widget with id
   * param  Id
   * returns
   * memberof WidgetService
   */
  deleteMenuWidget(Id) {
    return this.http.delete(getServiceUrl(AppService.DeleteMenuWidget, Id), this.httpOptions);
  }
  /**
   * Add Data Widget
   * returns true
   * memberof WidgetService
   */
  addDataWidget(data) {
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.AddDataWidget), body, this.httpOptions);
  }

  /**
   * Update Data Widget
   * returns true
   * memberof WidgetService
   */
  updateDataWidget(data, id) {
    const WidgetData =  data;
    WidgetData.modifiedBy = 'test@gmail.com';
    WidgetData.id = id;
    const body = JSON.stringify(WidgetData);
    return this.http.put(getServiceUrl(AppService.UpdateDataWidget), body, this.httpOptions);
  }

  /**
   * Delete Data Widget with id
   * param  Id
   * returns
   * memberof WidgetService
   */

  deleteDataWidget(Id) {
    return this.http.delete(getServiceUrl(AppService.DeleteDataWidget, Id), this.httpOptions);
  }
  /**
   * Update Input data
   * returns true
   * memberof WidgetService
   */
 updateInputData(data) {
    const InputData =  data;
    const body = JSON.stringify(InputData);
    return this.http.post(getServiceUrl(AppService.InputDataUpdate), body, this.httpOptions);
  }

  getSystemInfoByWidgetId(id: Number, isDataWidget: Boolean): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        widgetid : id.toString(),
        IsDataWidget: isDataWidget.toString()
      })
     };
    return this.http.get<any>(getServiceUrl(AppService.GetSystemInfoByWidgetId), this.httpOptions);
  }
}
