import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { faAngleDoubleDown, faQuestionCircle, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/Operators';
import { ChangeType, ComponentDataActions, ResourceActions, SideNavMenuItems } from 'src/app/global/app-enums.enum';
import { ComponentArtifacts, ComponentInfo, VersionData } from 'src/app/models/component/component.data.model';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserAccess } from '../../global/user-access';
import { CommonService } from '../../services/common.service';
import { ComponentListService } from '../../services/component/component-data.service';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';
import { ComponentVersionComponent } from '../component-version/component-version.component';

@Component({
  selector: 'app-component-data',
  templateUrl: './component-data.component.html',
  styleUrls: ['./component-data.component.scss']
})
export class ComponentDataComponent implements OnInit {
  public elevatordata: ComponentInfo[] = [];
  public compArtifacts: ComponentArtifacts;
  faQuestionCircle = faQuestionCircle;
  faAngleDoubleDown = faAngleDoubleDown;
  faVoteYea = faVoteYea;
  selectedComponentId: string;
  closeResult: string;
  routerId: number;
  canshowDownload = false;
  versionList: any = [{}];
  parentRouterID: Subject<any> = new Subject();
  isDeployedFlag = null;
  baselineComponentId = null;
  selectedBaselineComponentName = null;
  selectedComponentName = null;
  componentData: UntypedFormGroup;
  deployMessage = '';
  isBaseline = null;
  deployAccess = false;
  downloadAccess = false;
  addVersion = false;
  testAcess = false;
  resourceAccess = false;
  subscription: Subscription;
  reverseAction: Subscription;
  pageRefreshed: boolean = false;
  showConfirmation: boolean = false;
  componentId: number;
  componentName: string;
  versionId: number;
  selectionFlag = 0;
  multilineText = ''
  navigateToVersionChange = false;
  @ViewChild('tabs', { static: true }) tabs: NgbNav;

  //Confrimation Events button titles
  btnYesText = ''
  btnNoText = ''
  confirmationTitle = ''
  // confirmationNote = 'Are you sure you want to proceed?'
  constructor(public router: Router, private modalService: NgbModal,
    private componentService: ComponentListService,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    public commonService: CommonService,
    private confirmationDialogService: DialogboxService,
    private formBuilder: UntypedFormBuilder,
    private useraccess: UserAccess) {
    this.subscription = this.commonService.onMessage().subscribe((message) => {
      if (message.text === SideNavMenuItems.ComponentData) {
        this.loadData();
      }
    });

    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if (
        event.id === 1 &&
        event.url === event.urlAfterRedirects 
      ) {
        this.pageRefreshed = true;
      }
    })
  }

  ngOnInit() {
    this.componentData = this.formBuilder.group({
      component_name: [null],
      component_version: [null]
    });

    this.reverseAction = this.commonService.onRequest().subscribe((state) => {
      setTimeout(() => {
        this.tabs.activeId = "0"
        const currentUrl = this.router.url;
        const splitted = currentUrl.split('/');
        this.routerId = Number(splitted[3])
        this.versionId =  Number(splitted[4])
      })
    });

    this.commonService.reopenedVersionObservable().subscribe((status) => {
      this.versionList.map((item) => {
        if(item.versionNumberId.toString() === this.baselineComponentId) {
          this.commonService.isDeployedFlag = item.isDeployed = true;
          this.commonService.isReopened = item.reModify = true;
          this.commonService.isStaged = item.isStaged = false
        }
     })
  })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadData();

    }, 1000);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.reverseAction.unsubscribe();
  }

  loadData() {
    this.componentService.getComponentList(false, true).subscribe(res => {
      let componentIndex = 0
      if(this.commonService.selectedSRDOCommand) {
        componentIndex = res.findIndex((item) => {
          return item.name === this.commonService.selectedSRDOCommand.componentName
        })
        if(componentIndex === -1) {
          componentIndex = 0;
          this.commonService.selectedSRDOCommand = undefined
        }
      } else if(this.commonService.navigateToSystemTemplate) {
        componentIndex = res.findIndex((item) => {
          return item.name === this.commonService.navigateToSystemTemplate.componentName
        })
      }
      let component: ComponentInfo = res[componentIndex]
      this.componentService.componentData = res;
      this.elevatordata = res;
      const componentId = component.id;
      this.selectedComponentId = component.id.toString();
      this.checkAccess(ComponentDataActions.DownloadArtifacts, component.id);
      this.checkAccess(ComponentDataActions.Deploy, component.id);
      this.checkAccess(ComponentDataActions.AddVersion, component.id);
      this.checkAccess(ComponentDataActions.Test, component.id);
      this.checkAccess(ResourceActions.GetResources, component.id);
      this.componentService.getversionlistbycomponent(this.selectedComponentId).subscribe(res => {
        this.versionList = res;
        if (this.versionList.length > 0) {
          let versionIndex = 0
          if(this.commonService.selectedSRDOCommand) {
            if(this.commonService.selectedSRDOCommand.versionId) {
              versionIndex = this.versionList.findIndex((item) => {
                return item.versionNumberId === this.commonService.selectedSRDOCommand.versionId
              })
            }else {
              versionIndex = this.versionList.findIndex((item) => {
                return item.versionNumberId === this.commonService.selectedSRDOCommand.deviceId
              })
            }  
            versionIndex = versionIndex === -1 ? 0 : versionIndex
        }
          let version = this.versionList[versionIndex]
          this.isDeployedFlag = version.isDeployed;
          this.commonService.isDeployedFlag = version.isDeployed;
          this.commonService.isReopened = version.reModify;
          this.commonService.isStaged = version.isStaged;
          this.baselineComponentId = version.versionNumberId;
          this.isBaseline = version.isBaseline;
          this.componentService.versionSelected = version.versionNumber;
          setTimeout(() => {
            this.componentData.patchValue({
              component_name: this.routerId,
              component_version: version.versionNumberId
            });
          })
          if (this.elevatordata.length > 0) {
            // tslint:disable-next-line:no-shadowed-variable
            this.componentService.getComponentArtifacts(version.versionNumberId).subscribe(res => {
              this.canshowDownload = res.recordCount > 0 ? true : false;
              if (res.type === "Deploy") {
                this.commonService.deployInstance = false;
              }
              this.compArtifacts = res;
            });
          }
          if (this.router.url === '/component-data' || this.pageRefreshed == true) {
            this.pageRefreshed = false
            if(this.commonService.selectedSRDOCommand) {
              if(this.commonService.selectedSRDOCommand.commandResponseId !== 0) {
                this.router.navigate(['/component-data/responsedata/', componentId, this.baselineComponentId]);
                this.tabs.select("1") // Command Response
              } else {
                this.tabs.select("0")
                this.router.navigate(['/component-data/srdo/', componentId, this.baselineComponentId]);
              }
            }
            else if(this.commonService.navigateToSystemTemplate) {
              this.tabs.select("5") // System template
              this.router.navigate(['/component-data', 'system-templates', this.routerId, this.baselineComponentId]);
            }
            else {
              this.router.navigate(['/component-data/srdo/', componentId, this.baselineComponentId]);
            }
          }
        }
      });
      setTimeout(() => {
        this.routerId = component.id;
      })
      // this.getversionlistbycomponent(this.selectedComponentId);
    });
  }

  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      ariaLabelledBy: 'modal-basic-title'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public tabClick(event) {
    switch (event.nextId) {
      case "0":
        this.router.navigate(['/component-data', 'srdo', this.routerId, this.baselineComponentId]);
        break;
      case "1":
        this.router.navigate(['/component-data', 'responsedata', this.routerId, this.baselineComponentId]);
        break;
      case "2":
        this.router.navigate(['/component-data', 'datatextfile', this.routerId, this.baselineComponentId]);
        break;
      case "3":
        this.router.navigate(['/component-data', 'page-details', this.routerId, this.baselineComponentId]);
        break;
      case "4":
        this.router.navigate(['/component-data', 'resources', this.routerId, this.baselineComponentId]);
        break;
      case "5":
        this.router.navigate(['/component-data', 'system-templates', this.routerId, this.baselineComponentId]);
        break;
    }
  }

  testComponent() {
    this.spinner.show();
    this.componentService.testComponent(this.baselineComponentId).subscribe(res => {
      if (res === true) {
        this.getArtifacts(this.baselineComponentId);
        this.spinner.hide();
        this.ToastMessage('Component test artifacts has been generated successfully.', 'bg-success');
      } else {
        this.ToastMessage('Component test is failed', 'bg-danger');
        this.spinner.hide();
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  setConfirmationToFalse() {
    this.showConfirmation = false
    this.navigateToVersionChange = false
  }

  setConfirmationToTrue() {
    this.btnYesText = 'Yes'
    this.btnNoText = 'No'
    this.confirmationTitle = 'Please Confirm'
    this.showConfirmation = true
  }

  deployComponent() {
    this.selectionFlag = 4
    if (this.isDeployedFlag === false) {
      this.multilineText = `Once deployment is done, further modification is not allowed. Do you want to release this version of component?`;
    } else {
      this.multilineText = `This component is already deployed, Re-deployment may lead to change in existing software. Do you want to re-deploy component?`;
    }
    if(this.versionList && this.versionList.length > 1) {
      this.navigateToVersionChange = true
    }
    this.setConfirmationToTrue()
  }

  async deployAComponent() {
    this.spinner.show();
    try {
      let status = await this.componentService.deployComponent(this.baselineComponentId).toPromise()
      this.spinner.hide()
      if(status) {
        this.ToastMessage('Component deployed successfully.', 'bg-success');
        this.isDeployedFlag = true;
        this.commonService.deployInstance = false;
        this.commonService.isDeployedFlag = true;
        this.commonService.isReopened = false;
        this.commonService.isStaged = false;
        this.getArtifacts(this.baselineComponentId);
        this.versionList.map((item) => {
          if (
            item.versionNumberId.toString() === this.baselineComponentId
          ) {
             item.isDeployed = true;
             item.reModify = false;
             item.isStaged = false;
          }
        });
      } else {
        this.ToastMessage('Component deployment failed', 'bg-danger');
      }
    } catch(e) {
      this.spinner.hide()
    }
    
  }

  
navigateToVersionChangeCallback($event: boolean) {
    this.fetchVersionData()
 }

 async fetchVersionData() {
  this.spinner.show();
  try {
    let data = await this.componentService
      .getGetComponentVersionsDetailForComparision(this.baselineComponentId)
      .toPromise();
    if (data) {
      data.isSrdo = true
      let versionData: VersionData = data
      this.commonService.versionChangeData = versionData;
      this.router.navigateByUrl("/version-changes");
    }
    this.spinner.hide();
  } catch (e) {
    this.spinner.hide();
  }
 }

  stageComponent() {
    this.selectionFlag = 3
    this.multilineText = 'Are you sure you want to proceed with staging this component version?'
    this.setConfirmationToTrue()
  }


  stageAComponent() {
    this.spinner.show();
    this.componentService.stageComponent(this.baselineComponentId).subscribe(res => {
      this.setConfirmationToFalse()
      if (res === true) {
        this.spinner.hide();
        this.versionList.map((item: any) => {
          if(item.versionNumberId.toString() == this.baselineComponentId) {
            this.commonService.isDeployedFlag = item.isDeployed = false;
            this.commonService.isReopened = item.reModify = false;
            this.commonService.isStaged = item.isStaged = true;
          }
       })
        this.ToastMessage('Stage version created successfully.', 'bg-success');
      } else {
        this.ToastMessage('Stage version creation is failed', 'bg-danger');
        this.spinner.hide();
      }
    },
      ((error) => {
        this.spinner.hide();
        this.setConfirmationToFalse()
      }));
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }


  confirmationEvent(event: boolean) {
    this.setConfirmationToFalse()
    if(event) {
      this.commonService.takenInput = true;
      switch(this.selectionFlag) {
        case ChangeType.ChangeComponent:
          this.onConfirmation()
          break;
        case ChangeType.ChangeVersion:
          this.changeVersion()
          break;
        case ChangeType.Staging:
          this.stageAComponent()
          break;
        case ChangeType.Deploying:
          this.deployAComponent()
          break;
        default:
          break
      }
    }else {
        const currentUrl = this.router.url;
        const splitted = currentUrl.split('/');
        this.routerId = Number(splitted[3])
        this.versionId =  Number(splitted[4])
    }
  }

  changeComponentName(event) {
    this.selectionFlag = 1;
    this.componentId = event.target.value;
    this.componentName = event.target.id.toString();
    if(this.commonService.srdoChanges) {
      this.settingConfirmationData()
      this.showConfirmation = true;
    }else {
      this.onConfirmation()
    }
  }

  settingConfirmationData() {
    this.btnYesText = 'Discard Changes'
    this.btnNoText = 'Cancel'
    this.multilineText = `You have unsaved changes. You can discard your changes, \nor cancel to continue editing`
    this.confirmationTitle = 'Are you sure you want to leave the page?'
  }

  onConfirmation() {
    this.checkAccess(ComponentDataActions.DownloadArtifacts, this.componentId);
    this.checkAccess(ComponentDataActions.Deploy, this.componentId);
    this.checkAccess(ComponentDataActions.AddVersion, this.componentId);
    this.checkAccess(ComponentDataActions.Test, this.componentId);
    this.checkAccess(ResourceActions.GetResources, this.componentId);
    this.selectedComponentId = this.componentId.toString();
    this.selectedComponentName = this.componentName;

    this.componentService.getversionlistbycomponent(this.selectedComponentId).subscribe(res => {
      this.versionList = res;
      if (this.versionList.length > 0) {
        this.isDeployedFlag = this.versionList[0].isDeployed;
        this.commonService.isDeployedFlag = this.versionList[0].isDeployed;
        this.commonService.isReopened = this.versionList[0].reModify;
        this.commonService.isStaged = this.versionList[0].isStaged;
        this.baselineComponentId = this.versionList[0].versionNumberId;
        this.isBaseline = this.versionList[0].isBaseline;
        this.componentService.versionSelected = this.versionList[0].versionNumber
        this.componentData.patchValue({
          component_version: this.versionList[0].versionNumberId
        });
        if (this.elevatordata.length > 0) {
          this.componentService.getComponentArtifacts(this.versionList[0].versionNumberId).subscribe(res => {
            this.canshowDownload = res.recordCount > 0 ? true : false;
            if (res.type === "Deploy") {
              this.commonService.deployInstance = false;
            }
            else {
              this.commonService.deployInstance = true;
            }
            this.compArtifacts = res;
          });
        }
      }
      const currentUrl = this.router.url;
      const splitted = currentUrl.split('/');
      this.routerId = this.componentId;
      this.parentRouterID.next(this.componentId);
      this.router.navigate(['component-data/', splitted[2], this.componentId, this.baselineComponentId]);
      this.commonService.notifyOther({ option: 'call_child', value: this.routerId });
    });
  }

  openComponentVersion(componentVersion, component) {
    this.baselineComponentId = componentVersion;
    const versionDetail = this.getVersionDetail(componentVersion);
    if (versionDetail) {
      this.selectedBaselineComponentName = versionDetail.versionNumber;
    }
    const compDetail = this.getComponentDetail(component);
    if (compDetail) {
      this.selectedComponentName = compDetail.name;
    }
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const modalRef = this.modalService.open(ComponentVersionComponent,ngbModalOptions);
    modalRef.componentInstance.title = 'Version Control';
    modalRef.componentInstance.baselineComponentId = this.baselineComponentId;
    modalRef.componentInstance.isDeployedFlag = this.isDeployedFlag;
    modalRef.componentInstance.isBaseline = this.isBaseline;
    modalRef.componentInstance.isStaged = this.commonService.isStaged;
    modalRef.componentInstance.selectedBaselineComponentName = this.selectedBaselineComponentName;
    modalRef.componentInstance.selectedComponentId = this.selectedComponentId;
    modalRef.componentInstance.selectedComponentName = this.selectedComponentName;
    modalRef.componentInstance.submitComponentVersion.subscribe((result) => {
      const componentVersionName = result.new_version_name;
      const componentAction = result.action_type;
      const compVersionObj = {
        newVersionName: componentVersionName,
        baselineComponentId: this.baselineComponentId,
      };
      const compVersionObjRename = {
        newVersionName: componentVersionName,
        componentVersionId: this.baselineComponentId,
      };
      if (componentAction === 'add') {
        this.spinner.show();
        this.componentService.AddVersion(compVersionObj).subscribe((res) => {
          if (res !== null) {
            this.getversionlistbycomponent(this.selectedComponentId);
            modalRef.close();
            this.ToastMessage('Component Version Created Successfully.', 'bg-success');
            this.spinner.hide();
          }
        }, (error) => {
          this.spinner.hide();
        });
      }
      else if (componentAction === 'rename') {
        this.spinner.show();
        this.componentService.RenameVersion(compVersionObjRename).subscribe((res) => {
          if (res !== null) {
            this.getversionlistbycomponent(this.selectedComponentId);
            modalRef.close();
            this.ToastMessage('Component Version Renamed Successfully.', 'bg-success');
            this.spinner.hide();
          }
        }, (error) => {
          this.spinner.hide();
        });
      }
      else if (componentAction === 'delete') {
        this.spinner.show();
        modalRef.close();
      } else {
        modalRef.close();
      }
    });
    modalRef.componentInstance.getversionlistofcomponent.subscribe((id) => {
      this.getversionlistbycomponent(id);
    });
  }

  componentVersionChange(event) {
    this.selectionFlag = 2;
    this.versionId = event.target.value;
    if(this.commonService.srdoChanges) {
      this.settingConfirmationData()
      this.showConfirmation = true;
    }else {
      this.changeVersion()
    }
  }

  changeVersion() {
    const componentVersion = this.versionId;
    this.baselineComponentId = componentVersion;
    const versionFound = this.versionList.find((data) => {
      return data.versionNumberId === Number(componentVersion);
    });
    if (versionFound) {
      this.isDeployedFlag = versionFound.isDeployed;
      this.isBaseline = versionFound.isBaseline;
      this.commonService.isDeployedFlag = versionFound.isDeployed;
      this.componentService.versionSelected = versionFound.versionNumber
      this.commonService.isReopened = versionFound.reModify
      this.commonService.isStaged = versionFound.isStaged;
    }
    this.componentService.getComponentArtifacts(componentVersion).subscribe(res => {
      this.canshowDownload = res.recordCount > 0 ? true : false;
      this.compArtifacts = res;
    });
    const currentUrl = this.router.url;
    const splitted = currentUrl.split('/');
    this.router.navigate(['component-data/', splitted[2], this.routerId, componentVersion]);
  }
  getVersionDetail(componentVersion) {
    const versionFound = this.versionList.find((data) => {
      return data.versionNumberId === Number(componentVersion);
    });
    return versionFound;
  }
  getComponentDetail(component) {
    const compFound = this.elevatordata.find((data) => {
      return data.id === Number(component);
    });
    return compFound;
  }
  getversionlistbycomponent(selectedComponentId) {
    this.componentService.getversionlistbycomponent(selectedComponentId).subscribe(res => {
      this.versionList = res;
      if (this.versionList.length > 0) {
        this.isDeployedFlag = this.versionList[0].isDeployed;
        this.commonService.isDeployedFlag = this.versionList[0].isDeployed;
        this.commonService.isStaged = this.versionList[0].isStaged;
        this.commonService.isReopened = this.versionList[0].reModify;
        this.baselineComponentId = this.versionList[0].versionNumberId;
        this.isBaseline = this.versionList[0].isBaseline;
        this.componentData.patchValue({
          component_version: this.versionList[0].versionNumberId
        });
        this.getArtifacts(this.baselineComponentId);
      }
    });
  }
  getArtifacts(versionNumberId) {
    this.componentService.getComponentArtifacts(versionNumberId).subscribe(res => {
      this.canshowDownload = res.recordCount > 0 ? true : false;
      this.compArtifacts = res;
    });
     const currentUrl = this.router.url;
    const splitted = currentUrl.split('/');
    this.router.navigate(['component-data/', splitted[2], this.routerId, versionNumberId]);
  }
  public checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case ComponentDataActions.Deploy:
        this.deployAccess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      case ComponentDataActions.DownloadArtifacts:
        this.verifyAccess(action,dataItem);
        break;
      case ComponentDataActions.AddVersion:
        this.addVersion = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      case ComponentDataActions.Test:
        this.testAcess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      case ResourceActions.GetResources:
        let access = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
        let isComponentRead = this.useraccess.actions.roles.find(x => x.id == 7);
        if (isGlobalRead || isComponentRead) {
          access = true
        }
        this.resourceAccess = access
        break;
      default:
        break;
    }
  }

  public verifyAccess(action: string, dataItem: number = -1){
    if(!this.useraccess.actions) {
      this.router.navigate(['component-list']);
      return
    }
    let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
    if(isAdmin != undefined){
      this.downloadAccess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
    }else{
       //Component User or Component Owner
        let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
        let isComponentAccess = this.useraccess.actions.roles.find(x => x.id == 5 || x.id == 4 || x.id == 7);
        let isComponentMatch = this.useraccess.actions.components.find(x => x.componentId == dataItem )
        if (isComponentAccess  != undefined && isComponentMatch) {
          this.downloadAccess = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        }
       else{
          if (isGlobalRead != undefined) {
            this.downloadAccess = this.useraccess.getUserAccess('GlobalReadOnly', action, dataItem !== -1 ? +dataItem : -1);
          }
        }
    }
  }
}

