import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  faTrash, faEdit, faHome, faLayerGroup,
  faBoxOpen, faUserTag, faPhoneAlt, faLaptopCode, faSlidersH, faBuilding, faFileContract, faPlusSquare,
  faCaretSquareUp, faArrowRight,faArrowsAltH
} from '@fortawesome/free-solid-svg-icons';
import { SideNavMenuItems } from './global/app-enums.enum';
import { UserAccess } from './global/user-access';
import { CommonService } from './services/common.service';
import {CookieService} from 'ngx-cookie-service';
import { ToastService } from './services/toast/toast.service';
import { Subject, Subscription } from 'rxjs';
import {  MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, InteractionStatus, EventType, AuthenticationResult } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/Operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Language } from 'src/app/models/component/component.data.model';
import { MasterDataService } from './services/master-data/master-data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'EDD';
  faTrash = faTrash;
  faEdit = faEdit;
  faHome = faHome;
  faLayerGroup = faLayerGroup;
  faBoxOpen = faBoxOpen;
  faUserTag = faUserTag;
  faBuilding = faBuilding;
  faFileContract = faFileContract;
  faPhoneAlt = faPhoneAlt;
  faPlusSquare = faPlusSquare;
  faCaretSquareUp = faCaretSquareUp;
  faArrowsAltH = faArrowsAltH;
  faArrowRight = faArrowRight
  isChecked = false;
  navRouter = [];
  username = '';
  menuData: [Menu];
  hideme = false;
  showViewAccess = false;
  reverseAction: Subscription;
  loginDisplay = false;
  isLoading = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: MsalService,
    public router: Router,
    private userAccess: UserAccess,
    private commonService: CommonService,
    private cookie: CookieService,
    private masterDataService: MasterDataService,
    private msalBroadcastService: MsalBroadcastService,
    public toastService: ToastService,
    private spinner: NgxSpinnerService) {
     this.router.events.subscribe((e) => { 
      if(this.router.url === '/login' || this.router.url === '/error') {
        this.hideme = false;
      }else {
        this.hideme = true;
      }
      if(e instanceof NavigationEnd) {
        this.settingNavActive(true)
      }
    });
    this.reverseAction = this.commonService.onRequest().subscribe((state) => {
      setTimeout(() => {
        this.settingNavActive(false)
      })
    })
  }

  async getData(headerData: any) {
    try {
      let status = await  this.userAccess.getUserClaims(headerData).toPromise();
      if (status == true) {
        this.checkUserAccess()
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    let account = this.authService.instance.getActiveAccount()
    if(account && account.username) {
      this.hideme = true;
      this.isChecked = true
      this.username = account.username;
      if(this.userAccess.actions === undefined) {
        const headerData = { userEmailId: account.username};
        this.getData(headerData)
      }
    }
    this.menuData = [{ title: 'Home', icon: ' faHome' }];
    this.navRouter = [
      {
        value: 'component-list',
        title: SideNavMenuItems.ComponentList,
        icon: faBuilding,
        active: false
      },
      {
        value: 'component-data',
        title: SideNavMenuItems.ComponentData,
        icon: faBoxOpen,
        active: false
      },
      {
        value: 'version-changes',
        title: SideNavMenuItems.ChangeLog,
        icon: faArrowsAltH,
        active: false
      },
      {
        value: 'system-list',
        title: SideNavMenuItems.SystemList,
        icon: faSlidersH,
        active: false
      },
      {
        value: 'system-data',
        title: SideNavMenuItems.SystemData,
        icon: faLaptopCode,
        active: false
      },
      {
        value: 'widget',
        title: SideNavMenuItems.PhoneAdmin,
        icon: faPhoneAlt,
        active: false
      },
      {
        value: 'user-roles',
        title: SideNavMenuItems.UserManagement,
        icon: faUserTag,
        active: false
      },
      {
        value: 'audit-log',
        title: SideNavMenuItems.AuditLog,
        icon: faFileContract,
        active: false
      }
    ];
    this.setLoginDisplay();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        this.logout()
      });
  }

  settingNavActive(state: Boolean) {
    let item = this.navRouter.find((x) =>  this.router.url.includes('/' + x.value))
        if(item) {
          item.active = true
          this.navRouter.forEach((x) => {
            if(!this.router.url.includes(('/' + x.value))) {
              x.active = false
            }
          })
          if(state) {
            this.checkUserAccess();
          }
    }
  }

  checkUserAccess() {
    if (this.userAccess.actions) {
      var isfind = this.userAccess.actions.roles.find(x => x.id === 1);
      //No admin access
      if (isfind === undefined) {
        this.navRouter = this.navRouter.filter((x) => x.value != 'user-roles');
        if (this.router.url === '/user-roles') {
          this.router.navigateByUrl('/error')
        }

        let isPhoneAdmin = this.userAccess.actions.roles.find(x => x.id == 6);
        //No Phone Admin access
        if (isPhoneAdmin === undefined) {
          this.navRouter = this.navRouter.filter((x) => (x.value != 'widget' || x.value != 'widget'));//If not phone admin remove phone admin tab
          if (this.router.url === '/widget') {
            this.router.navigateByUrl('/error')
          }
        }

        //If Global Read Only access
        let isGlobalRead = this.userAccess.actions.roles.find(x => x.id == 9);
        //No Global Read Only access
        if (isGlobalRead === undefined) {
          // If Component Read only  or Component User or Component Owner access
          let isComponentAccess = this.userAccess.actions.roles.find(x => x.id == 7 || x.id == 5 || x.id == 4);
          let isSystemAccess = this.userAccess.actions.roles.find(x => x.id == 2 || x.id == 3 || x.id == 8);

          if (isComponentAccess != undefined) {
            //Checking if any System owner or System User access is there
            if (isSystemAccess === undefined) {
              this.navRouter = this.navRouter.filter((x) => x.value != 'system-data');
              if (this.router.url === '/system-data') {
                this.router.navigateByUrl('/error')
              }
            }
          }
          //If System Read only  or System User or System Owner access
          else if (isSystemAccess != undefined) {
            //Checking if any System owner or System User access is there
            if (isComponentAccess === undefined) {
              this.navRouter = this.navRouter.filter((x) => x.value != 'component-data');
              if (this.router.url === '/component-data') {
                this.router.navigateByUrl('/error')
              }
            }
          }
        }
        let systemDataAccess = this.userAccess.actions.roles.find(x => x.id == 2 || x.id == 3 || x.id == 8);
        //No System Data Access
        if (systemDataAccess === undefined && isGlobalRead === undefined) {
          this.navRouter = this.navRouter.filter((x) => x.value != 'system-data');
          if (this.router.url === '/system-data') {
            this.router.navigateByUrl('/error')
          }
        }

        let componentDataAccess = this.userAccess.actions.roles.find(x => x.id == 4 || x.id == 5 || x.id == 7);
        //No Component Data Access
        if (componentDataAccess === undefined && isGlobalRead === undefined) {
          this.navRouter = this.navRouter.filter((x) => x.value != 'component-data');
          if (this.router.url === '/component-data') {
            this.router.navigateByUrl('/error')
          }
        }

        if (componentDataAccess === undefined) {
          this.navRouter = this.navRouter.filter((x) => x.value != 'version-changes');
          if (this.router.url === '/version-changes') {
            this.router.navigateByUrl('/error')
          }
        }

        let componentOwner = this.userAccess.actions.roles.find(x => x.id == 4);
        let systemOwner = this.userAccess.actions.roles.find(x => x.id == 2);
        if(componentOwner == undefined && systemOwner == undefined) {
          this.navRouter = this.navRouter.filter((x) => x.value != 'audit-log');
          if (this.router.url === '/audit-log') {
            this.router.navigateByUrl('/error')
          }
        }
        

        if(systemOwner == undefined) {
          this.navRouter = this.navRouter.filter((x) => x.value != 'common-system-data');
          if (this.router.url === '/common-system-data') {
            this.router.navigateByUrl('/error')
          }
        }
      }
    }
  }

  public checkboxChange(value: boolean) {
    this.isChecked = value;
  }

  public isSideMenuOponed() {
    return this.isChecked && this.loginDisplay;
  }

  public trigger(title) {
    this.navRouter.forEach((item) => {
      item.title === title ? item.active = true : item.active = false
    })
    if (title === SideNavMenuItems.ComponentData 
      || title === SideNavMenuItems.SystemData 
      || title === SideNavMenuItems.PhoneAdmin) {
      this.commonService.sendMessage(title);
    }
  }
 
  logout() {
    this.authService.logoutRedirect();
    this.cookie.delete('username');
    this.cookie.delete('componentListValue');
    this.cookie.delete('versionListValue');
    localStorage.clear()
    this.isChecked = false;
    setTimeout(() => {
        window.location.replace('/login')
    }, 500)
  }

  closeEventAction(status: boolean) {
    this.showViewAccess = false
    if(status === true) {
      this.ToastMessage("Request Sent Successfully. Please wait for 2 to 3 days to get the approval.", 'bg-success')
    }
  };

  showViewAccessAction() {
    this.showViewAccess = true
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 5000,
      autohide: true,
      headertext: 'Success'
    });
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    let account = this.authService.instance.getActiveAccount()
    if(account && account.username) {
      this.username = account.username;
      this.isChecked = true;
      // this.hideme = true;
    }
  }
  
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.reverseAction.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

export interface Menu {
  title: string;
  icon: string;
}



