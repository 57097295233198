import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UserAccess } from 'src/app/global/user-access';
import { ActivatedRoute } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeatureService } from 'src/app/services/system/feature.service';
import { CommonFeature } from 'src/app/models/system/feature/feature.module';
import { CommonMenusFeaturesService } from 'src/app/services/common-menus-features/common-menus-features.service';
import { MenuTitle } from 'src/app/models/component/component.data.model';
import { Subscription } from 'rxjs';
import { ListViewComponent } from '@progress/kendo-angular-listview';

@Component({
  selector: "app-common-features",
  templateUrl: "./common-features.component.html",
  styleUrls: ["./common-features.component.scss"],
})
export class CommonFeaturesComponent implements OnDestroy {
  componentAccess = false;
  componentId: string;
  selectedFeatureItem: any;
  componentVersionId: string;
  features: CommonFeature[] = [];
  faTimes = faTimes;
  haveCommonMenuTitles = false
  filteredValue = ''
  titleChangeSub: Subscription;
  commonFeatureSub: Subscription;
  @ViewChild ('listView') listView: ListViewComponent;
  constructor(
    private useraccess: UserAccess,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private featureService: FeatureService,
    private commonFeatureService: CommonMenusFeaturesService
  ) {}
  ngOnDestroy(): void {
    this.titleChangeSub.unsubscribe()
    this.commonFeatureSub.unsubscribe()
  }

  ngOnInit(): void {
    this.titleChangeSub = this.commonFeatureService.currentCommonTitle.subscribe((title: MenuTitle) => {
      if(title) {
        this.haveCommonMenuTitles = true
      } else{
        this.haveCommonMenuTitles = false
      }
      this.componentAccess = this.commonFeatureService.componentAccess
    })
    this.commonFeatureSub = this.commonFeatureService.commonFeatures$.subscribe((features) => {
      if (features && features.length > 0) {
        this.features = features
        let featureId = ''
        if(this.commonFeatureService.featureIdAdded.length > 0 || this.commonFeatureService.featureIdSelected) {
          if(this.commonFeatureService.featureIdSelected) {
            featureId = this.commonFeatureService.featureIdSelected.toString()
            setTimeout(() => {
              this.scrollToItem(+featureId)
            }, 1000);
          }else {
            let selected = features.find((feature) => feature.id == this.commonFeatureService.featureIdAdded)
            featureId = selected ? this.commonFeatureService.featureIdAdded : this.features[0].id.toString()
          }
         
        } else {
          featureId = this.features[0].id.toString()
        }
        this.fetchCommonFeatureData(featureId);
      } else {
        this.features = []
        this.commonFeatureService.featureIdAdded = ''
        this.selectedFeatureItem = undefined
      }
    })
  }

  //Scroll item to visible
  scrollToItem(id: number) {
    //this.listView.element.nativeElement.querySelector("[data-id = '596']")
    let element = this.listView.element.nativeElement.querySelector(`[data-id = '${id}']`)
    if(element) {
      element.scrollIntoView({behaviour: 'smooth', block: 'center'})
    }
  }

  //Filter items by search
  scrollToItemBySearch(id: number) {
      let index = this.features.findIndex((feature) => feature.id == id)
      this.filteredValue = this.features[index].name;
      this.handleFilterChange(this.filteredValue)
  }

  fetchCommonFeatureData(featureId: string) {
   let componentId = this.commonFeatureService.componentId
   let titleId = this.commonFeatureService.commonTitle.id
   this.spinner.show()
   this.featureService
      .getCommonFeatureData(featureId, componentId, titleId)
      .subscribe((data) => {
        this.spinner.hide()
        this.commonFeatureService.featureIdSelected = undefined
        this.commonFeatureService.featureIdAdded = ''
        if (data) {
          this.selectedFeatureItem = data;
        }
      }, error => this.spinner.hide()
    );
  }

  featureClicked(dataItem: any) {
    this.fetchCommonFeatureData(dataItem.id)
  }

  handleFilterChange(value: string) {
    const normalizedQuery = value.toLowerCase();
    const filterExpession = (item: CommonFeature) => item.name.toLowerCase().indexOf(normalizedQuery) !== -1;

    this.features = this.commonFeatureService.commonFeatures.filter(filterExpession);
  }

  searchClear() {
    this.filteredValue = ''
    this.features = this.commonFeatureService.commonFeatures
  }
}

