import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { MenuTitle } from "src/app/models/component/component.data.model";
import { MenuService } from "../system/menu.service";
import { map, switchMap } from "rxjs/Operators";
import { ToastService } from "../toast/toast.service";
import { CommonFeature, featureListing } from "src/app/models/system/feature/feature.module";
import { FeatureService } from "../system/feature.service";
import { ComponentDataActions, renameKey } from "src/app/global/app-enums.enum";
import { UserAccess } from "src/app/global/user-access";

@Injectable({
  providedIn: "root",
})
export class CommonMenusFeaturesService {
  private _menuTitles: MenuTitle[] = [];

  get commonMenuTitles() {
    return this._menuTitles;
  }

  set commonMenuTitles(titles: MenuTitle[]) {
    this._menuTitles = titles;
  }

  private _componentId: string;
  get componentId() {
    return this._componentId;
  }

  set componentId(componentId: string) {
    this._componentId = componentId;
  }

  private _featureIdAdded: string = "";
  get featureIdAdded() {
    return this._featureIdAdded;
  }
  set featureIdAdded(featureId: string) {
    this._featureIdAdded = featureId;
  }

  private _componentAccess: boolean;
  get componentAccess() {
    return this._componentAccess;
  }
  set componentAccess(access: boolean) {
    this._componentAccess = access;
  }

  private _commonFeatures: CommonFeature[] = [];
  get commonFeatures() {
    return this._commonFeatures;
  }
  set commonFeatures(features: CommonFeature[]) {
    this._commonFeatures = features;
    this._commonFeaturesSubject.next(features);
  }

  private commonTitleSource = new BehaviorSubject(undefined);
  private _commonTitle: MenuTitle;
  private set commonTitle(commonTitle: MenuTitle) {
    this._commonTitle = commonTitle;
  }

  //Jumping from feature to Menu
  private _menuIdSelected: number;
  get menuIdSelected() {
    return this._menuIdSelected;
  }
  set menuIdSelected(menuId: number) {
    this._menuIdSelected = menuId;
  }

  //Jumping from menu to Feature
  private _featureIdSelected: number;
  get featureIdSelected() {
    return this._featureIdSelected;
  }
  set featureIdSelected(menuId: number) {
    this._featureIdSelected = menuId;
  }

  private _menufeatureSubject = new Subject<string>();
  get menufeatureSubject() {
    return this._menufeatureSubject;
  }
  menufeatureSelection$ = this._menufeatureSubject.asObservable();

  get commonTitle() {
    return this._commonTitle;
  }
  currentCommonTitle = this.commonTitleSource.asObservable();

  changeCommonTitle(title: MenuTitle) {
    this._commonTitle = title;
    this.commonTitleSource.next(title);
  }

  private _commonFeaturesSubject = new BehaviorSubject(undefined);
  commonFeatures$ = this._commonFeaturesSubject.asObservable();

  private _isReOrdered: boolean = false;
  get isReOrdered() {
    return this._isReOrdered;
  }

  set isReOrdered(isReOrdered: boolean) {
    this._isReOrdered = isReOrdered;
  }

  constructor(
    private menuService: MenuService,
    private toastService: ToastService,
    private featureService: FeatureService,
    private userAccess: UserAccess
  ) {}

  /**
   * Menu Titles Section
   * Fetch common titles
   */
  fetchCommonMenuTitles(componentId: string): Observable<boolean> {
    try {
      return this.menuService.getMenuTitles(componentId).pipe(
        map((res) => {
          if (res != null) {
            Object(res).forEach((obj) => {
              renameKey(obj, "title", "name");
            });
            this._menuTitles = res;
            this.checkAccess(ComponentDataActions.Test, +componentId);
            return true;
          }
          return false;
        })
      );
    } catch (e) {
      return of(false);
    }
  }

  /**
   * Display alert
   * @param message
   * @param type
   */
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: type === "bg-success" ? "Success" : "Error",
    });
  }

  fetchCommonFeatures(
    compId: string,
    titleId: string
  ): Observable<CommonFeature[]> {
    //https://medium.com/@redin.gaetan/angular-for-everyone-chapter-4-services-61beaa998a86
    return this.featureService.getCommonFeaturelist(compId, titleId).pipe(
      map((features: CommonFeature[]) => {
        if (features && features.length > 0) {
          Object(features).forEach((obj: CommonFeature) => {
            renameKey(obj, "id", "dbid");
            renameKey(obj, "featureId", "id");
          });
          return features;
        } else {
          return [];
        }
      })
    );
  }

  fetchCommonListings(
    feature: CommonFeature,
    recordsCount: number,
    pageIndex: number
  ): Observable<featureListing[]> {
      let featureId = feature.featureId;
      let componentId = this.componentId;
      let titleId = this.commonTitle.id;
      return this.featureService
        .getCommonFeatureListing(
          featureId,
          componentId,
          titleId,
          recordsCount,
          pageIndex
        )
        .pipe(
          map((data) => {
            if (data) {
              return data.featureListings;
            } else {
              return [];
            }
          })
        );
  }

  checkAccess(action: string, dataItem: number = -1) {
    switch (action) {
      case ComponentDataActions.Test:
        this.componentAccess = this.userAccess.getUserAccess(
          "components",
          action,
          dataItem
        );
        break;
    }
  }
}
