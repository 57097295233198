import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/Operators';
import { UserAccess } from '../global/user-access';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginDisplay = false;
  multiLineTile = `Elevator \nData \nDictionary`

  constructor(private route: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private userAccess: UserAccess,
    private spinner: NgxSpinnerService,
    private msalBroadcastService: MsalBroadcastService) {
      let account = this.authService.instance.getActiveAccount()
      if(account && account.username) {
      this.route.navigate(['component-list']);
    }
   }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        this.spinner.show();
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

      
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay) {
      if(this.userAccess.actions === undefined) {
        let account = this.authService.instance.getActiveAccount()
        if(account && account.username) {    
        const headerData = { userEmailId: account.username};
        this.userAccess.getUserClaims(headerData).subscribe((status) => {
          this.spinner.hide()
          if (status == true) {
            this.route.navigate(['component-list']);
          }
        }, error => {
          this.spinner.hide()
        });
      }
      }
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
}
