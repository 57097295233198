import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentInfo } from 'src/app/models/component/component.data.model';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { SystemListService } from 'src/app/services/system/system-list.service';
import { UserService } from 'src/app/services/user/user.service';
import { Multi } from 'src/app/user/add-user/add-user.component';

@Component({
  selector: "app-view-access",
  templateUrl: "./view-access.component.html",
  styleUrls: ["./view-access.component.scss"],
})
export class ViewAccessComponent implements OnInit {
  @Input() opened: boolean = false;
  requestAccessForm: UntypedFormGroup;
  public compOwnervalue: Multi[];
  public compUservalue: Multi[];
  public compReadOnlyValue: Multi[];
  public sysOwnervalue: Multi[];
  public sysUservalue: Multi[];
  public sysReadOnlyValue: Multi[];
  public components: ComponentInfo[] = [];
  public systems: any = [];
  public buttontitle = "Request Access";
  headertitle = "Request Access";
  faTimes = faTimes;
  public phoneadminvalue: boolean;
  public globalreadvalue: boolean;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() data = [];
  public gridView = [];
  private viewAcceData: ViewAccess;
  public accessRoles: Role[] = []
  public isAdmin = false
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private componentService: ComponentListService,
    private systemService: SystemListService,
    private userService: UserService,
    private authService: MsalService,
  ) {}

  ngOnInit() {
    this.requestAccessForm = this.formBuilder.group({
      componentowners: [[]],
      componentusers: [[]],
      componentreadonly: [[]],
      systemowners: [[]],
      systemusers: [[]],
      systemreadonly: [[]],
      phoneadmin: [false],
      globalread: [false],
    });
    setTimeout(() => {
      this.getUserRoles();
      this.getComponentList();
      this.getSystemsList();
    });
  }

  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  public onTabSelect(e: SelectEvent): void {
    console.log(e);
  }

  getComponentList() {
    this.spinner.show();
    this.componentService.getComponentList(true).subscribe(
      (data) => {
        this.spinner.hide();
        this.components = data;
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getSystemsList() {
    this.spinner.show();
    this.systemService.getAllSystemList(true).subscribe(
      (data) => {
        this.spinner.hide();
        if(data) {
          this.systems = data;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getUserRoles() {
    this.spinner.show()
    let account = this.authService.instance.getActiveAccount()
    this.userService.getPendingAccess(account.username).subscribe((response) => {
      this.spinner.hide()
      if (response) {
        let accessRoles: Role[] = []
        response.userRoles.map((item) => {
          let assigned = []
          let unassigned = []
          let role: Role = {roleId: item.roleId, roleName: item.roleName, mappedEntities: item.mappedEntities}
            item.mappedEntities.map((role) => {
              if(role.status === true) {
                (item.roleId === 6 || item.roleId === 9 || item.roleId === 1) ? assigned.push('Yes') : assigned.push(role.entityName)
              } else {
                (item.roleId === 6 || item.roleId === 9) ? unassigned.push('Yes') : unassigned.push(role.entityName)
              }
            })
            role.rolesAPending = unassigned.toString()
            role.rolesAssigned = assigned.toString()
            accessRoles.push(role)
        })
        this.gridView = accessRoles
      }
    }, () => {
      this.spinner.hide();
    });
  }

  closeEnumEdit() {}

  onConfirmAction() {
    this.viewAcceData = {
      userId: 0,
      emailId: this.authService.instance.getActiveAccount().username,
      userRoles: [],
    };
    let role: Role;
    if (this.compOwnervalue && this.compOwnervalue.length > 0) {
      role = { roleId: 4, roleName: "ComponentOwner", mappedEntities: [] };
      this.addRoles(this.compOwnervalue, role);
    }
    if (this.compUservalue && this.compUservalue.length > 0) {
      role = { roleId: 5, roleName: "ComponentUser", mappedEntities: [] };
      this.addRoles(this.compUservalue, role);
    }
    if (this.compReadOnlyValue && this.compReadOnlyValue.length > 0) {
      role = { roleId: 7, roleName: "ComponentReadOnly", mappedEntities: [] };
      this.addRoles(this.compReadOnlyValue, role);
    }
    if (this.sysOwnervalue && this.sysOwnervalue.length > 0) {
      role = { roleId: 2, roleName: "SystemOwner", mappedEntities: [] };
      this.addRoles(this.sysOwnervalue, role);
    }
    if (this.sysUservalue && this.sysUservalue.length > 0) {
      role = { roleId: 3, roleName: "SystemUser", mappedEntities: [] };
      this.addRoles(this.sysUservalue, role);
    }
    if (this.sysReadOnlyValue && this.sysReadOnlyValue.length > 0) {
      role = { roleId: 8, roleName: "SystemReadOnly", mappedEntities: [] };
      this.addRoles(this.sysReadOnlyValue, role);
    }
    if (this.phoneadminvalue === true) {
      role = { roleId: 6, roleName: "PhoneAdministrator", mappedEntities: [] };
      this.addRoles([], role);
    }
    if (this.globalreadvalue === true) {
      role = { roleId: 9, roleName: "GlobalReadOnly", mappedEntities: [] };
      this.addRoles([], role);
    }
    if(this.viewAcceData.userRoles.length > 0) {
      this.postAccessRequest();
    }
  }

  addRoles(roles: Multi[], role: Role) {
    for (let i = 0; i < roles.length; i++) {
      let entity: MappedEntity = {
        entityId: roles[i]["id"],
        entityName: roles[i]["name"],
      };
      role.mappedEntities.push(entity);
    }
    this.viewAcceData.userRoles.push(role);
  }

  postAccessRequest() {
    this.spinner.show();
    let username = ''
    let account = this.authService.instance.getActiveAccount()
    if(account) {
      let fullname = account.name ? account.name : ''
      let splitted = fullname.split(',')
      if(splitted.length > 1) {
        let name = splitted[1].replace(' ', '');
        username = name.charAt(0).toUpperCase() + name.substring(1).toLowerCase()
      } else {
        username = account.name
      }
    }
    this.userService.requestAccess(this.viewAcceData,
      username ).
      subscribe(
      (res) => {
        this.spinner.hide();
        this.viewAcceData = undefined;
        this.closedEvent.emit(true)
      },
      () => {
        this.spinner.hide();
      }
    );
  }
}
export interface ViewAccess {
  userId: Number,
  emailId: String,
  userRoles: Role[]
}

export interface Role {
  roleId: Number,
  roleName: String,
  mappedEntities: MappedEntity[],
  rolesAssigned?: String,
  rolesAPending?: String
}

export interface MappedEntity {
  entityId: Number,
  entityName: String,
  status?: Boolean
}
